import React from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";


const MobileMaskAdapter = ({ input, meta, children, ...rest }) => {
  const mask = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  return (
    <div className={classNames("form-group", { row: !children })}>
      <label
        htmlFor="reg-mobile"
        className={classNames({ "col-sm-4 col-form-label": !children })}
      >
        Mobile No.
      </label>
      <div className={classNames({ "col-sm-8": !children })}>
        <MaskedInput
          mask={mask}
          {...input}
          {...rest}
          className={classNames("form-control", {
            "is-invalid": meta.touched && meta.error
          })}
          placeholder="Mobile No."
          onChange={event => input.onChange(event.target.value)}
        />
        {children}
      </div>
    </div>
  );
};

export default MobileMaskAdapter;
