import React from "react";
import {
  scansStorageUri,
  receiptStorageUri,
  presentationDateFormatTable,
  dateFormatShort,
  PAYMENT_CONSTANTS,
  VOUCHER_STATUSES,
} from "../constants/global.constants";
import { NAVIGATION_CONSTANTS } from '../constants/navigation.constants';
import Moment from "moment";
import { Row, Col } from "reactstrap";
import { isEmpty, chain, get, upperFirst, toLower } from "lodash";
import { formatSpeciality } from "../modules/profile/helpers";
import { isUserDentist } from "./permission.service";
import { toast } from "react-toastify";
import { toastr } from "react-redux-toastr";
// https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/601
// issue with same keys just ignore it and update version of table in future
// for now just remapped data to ignore errors in console.

import axios from "../axios-client";
import { fetchRadiologyReports } from "../modules/radiologyReports/actions";
import { store } from "../index";
// import { generateViewUrl } from "../plugins/postdicom/util";
import { fetchVisits } from "../modules/visits/actions";

const CUT_AFTER = 16;

// Utils
export const filterCloudScan = (scans) => scans.filter((i) => i.scanUrl);
export const filterScan = (scans) => scans.filter((i) => !i.scanUrl);
const getAuthor = (i) => {
  return get(i, "radiographer.name", "");
};

// Formatters
const dateFormatter = (cell) => {
  if (!cell) {
    return "";
  }
  return `${Moment(cell).format(presentationDateFormatTable)}`;
};

const dateFormatterShort = (cell) => {
  if (!cell) {
    return "";
  }
  return `${Moment(cell).format(dateFormatShort)}`;
};

const servicesFormatter = (cell) => {
  if (!cell) {
    return "";
  }

  return cell.map((item, index) => {
    return (
      <span key={index} className="mr-1">
        {item} {cell.length - 1 > index ? "," : ""}
      </span>
    );
  });
};

const urlBuilder = (item) =>
  item.scanUrl ? item.scanUrl : scansStorageUri + item.scanName;

  const ellipseText = (str = '', cutAfterChars  = CUT_AFTER) => {
    if(!str) {
      return ''
    }
    return str.length > cutAfterChars ?  `${str.substr(0, cutAfterChars)}...`: str
}
  
const scanNameBuilder = ({scanName, scanUrl}) => {
  const cutAfterChars  = CUT_AFTER;
  if(scanName) {
    return scanName?.length > cutAfterChars ? ellipseText(scanName) : scanName
  }
  if (scanUrl) {
    return scanUrl?.length > cutAfterChars ? ellipseText(scanUrl) : scanUrl
  }
  return '';
}

const scanTitleNameBuilder = ({scanName, scanUrl }) =>
  scanName || scanUrl || "no name";

const renderScanDateCell = (item) => {
  if (item.creationAt) {
    return (
      <Col>
        <span>
          {Moment(item.creationAt).format(presentationDateFormatTable)}
        </span>
      </Col>
    );
  }

  return (
    <Col>
      <small>N/A</small>
    </Col>
  );
};

// Renderers
export const renderPendingSection = () => (
  <span>
    <i className="icon-clock text-warning mr-2" />
    Pending...
  </span>
);

export const renderSameDayProcessingCell = (isSameDayProcessing, row) => {
  return (
    <span>
      {Moment(row.reportDate).format(presentationDateFormatTable)}
      {row.sameDayProcessing && (
        <i
          className="icon-exclamation text-danger ml-2"
          title="Express processing"
        />
      )}
    </span>
  );
};

export const renderRadiologistPatientNameCell = (row) => {
  return (
    <span>
      {row.isVisit
        ? row.patientName
        : row.booking
        ? `${row.booking.patient.firstName} ${row.booking.patient.lastName}`
        : `${row.patientName} ${row.patientSurname}`}
    </span>
  );
};

export const renderRadiologistDentistCell = (row) => {
  return <span>{row.dentistName}</span>;
};

export const renderRadiologyReportCell = (row) => {
  const { fullRadiologyReportUrl, fullRadiologyReportName } = row.report;

  if (fullRadiologyReportUrl) {
    return (
      <a
        title={fullRadiologyReportName}
        className="btn btn-light btn-sm mr-1 mb-1"
        href={fullRadiologyReportUrl}
        download
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <span>{ellipseText(fullRadiologyReportName)}</span>
      </a>
    );
  }

  const userRole = store.getState().profile.profileReducer.profile.role;

  if (isUserDentist(userRole)) {
    return renderPendingSection();
  }

  if (!fullRadiologyReportUrl) {
    return (
      <label
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        className="link-span"
      >
        + Add file
        <input
          type="file"
          className="d-none"
          onChange={(event) => handleFileUpload(event, row.mainId)}
        />
      </label>
    );
  }
};

export const renderAttachedScans = (scans) => {
  if (isEmpty(scans)) {
    return renderPendingSection();
  }
  return scans.map((item, index) => {
    return (
      <a
        title={scanTitleNameBuilder(item)}
        key={index + item.scanUrl}
        className="btn btn-light btn-sm mr-1 mb-1"
        href={urlBuilder(item)}
        {...(item.scanUrl ? {} : { download: true })}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <span>{scanNameBuilder(item)}</span>
      </a>
    );
  });
};

export const renderOrderReportAttachedScan = ({ scan }) => {
  if (!scan.scanUrl) {
    return (
      <span>
        <i className="icon-exclamation text-info mr-2" />
        Not uploaded
      </span>
    );
  }
  return (
    <a
      title={scan.scanName}
      className="btn btn-light btn-sm mr-1 mb-1"
      href={scan.scanUrl}
      download
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => e.stopPropagation()}
    >
      <span>{scan.scanName}</span>
    </a>
  );
};

export const renderAttachedScansForRadiographer = (scans, fn, visitId) => {
  if (isEmpty(scans)) {
    return <span className="link-span">Upload scans</span>;
  }
  return scans.map((item, index) => {
    return (
      <a
        title={scanTitleNameBuilder(item)}
        key={index}
        className="btn btn-light btn-sm mr-1 mb-1"
        {...(item.scanUrl ? {} : { download: true })}
        href={urlBuilder(item)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <span>{scanNameBuilder(item)}</span>
        <span
          style={{ margin: "5px", paddingLeft: "5px" }}
          onClick={(e) => {
            e.preventDefault();
            fn(item, visitId);
          }}
        >
          X
        </span>
      </a>
    );
  });
};

export const renderGroupedScansForRadiographer = (scans, fn, visitId) => {
  if (isEmpty(scans)) {
    return <small>N/A</small>;
  }
  const remappedScans = chain(scans)
    .map((i) => ({
      ...i,
      uploader: getAuthor(i),
    }))
    .groupBy((i) => i.uploader)
    .value();

  return Object.keys(remappedScans).map((uploader, index) => {
    return (
      <Row key={index}>
        <Col sm="4">
          <b>{uploader}</b>
        </Col>
        <Col>
          {remappedScans[uploader].map((item, innerIndex) => (
            <Row key={innerIndex}>
              <Col>
                <a
                  title={scanTitleNameBuilder({ scanName: item.file })}
                  className="btn btn-light btn-sm mr-1 mb-1"
                  {...(item.scanUrl ? {} : { download: true })}
                  href={urlBuilder(item)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>
                    {scanNameBuilder(item)}
                  </span>
                  <span
                    style={{ margin: "5px", paddingLeft: "5px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      fn(item, visitId);
                    }}
                  >
                    X
                  </span>
                </a>
              </Col>
              {renderScanDateCell(item)}
            </Row>
          ))}
        </Col>
      </Row>
    );
  });
};

export const renderGroupedScans = (scans, fn, visitId) => {
  if (isEmpty(scans)) {
    return <small>N/A</small>;
  }
  const hardCoddedScans = chain(scans)
    .map((i, index) => ({
      ...i,
      uploader: getAuthor(i),
    }))
    .groupBy((i) => i.uploader)
    .value();

  return Object.keys(hardCoddedScans).map((uploader, index) => {
    return (
      <Row key={index}>
        <Col sm="4">
          <b>{uploader}</b>
        </Col>
        <Col>
          {hardCoddedScans[uploader].map((item, index) => (
            <Row key={index}>
              <Col>
                <a
                  title={scanTitleNameBuilder(item)}
                  className="btn btn-light btn-sm mr-1 mb-1"
                  {...(item.scanUrl ? {} : { download: true })}
                  href={urlBuilder(item)}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <span>
                    {scanNameBuilder(item)}
                  </span>
                </a>
              </Col>
              {renderScanDateCell(item)}
            </Row>
          ))}
        </Col>
      </Row>
    );
  });
};

const renderReport = (report) => {
  if (!report) {
    return <span>No report was requested</span>;
  } else if (report && report.requestedReportDate && !report.reportContent) {
    return (
      <span>
        <i className="icon-clock text-warning mr-2" />
        Pending...
      </span>
    );
  } else if (report && report.reportContent) {
    return (
      <span title={report.reportContent}>
        <i className="icon-check text-success mr-2" /> Done
      </span>
    );
  }
};

const renderReportForRadiologist = (report) => {
  if (report && report.requestedReportDate && report.reportContentList == 0) {
    return <span className="link-span">+ Add file(s)</span>;
  } else if (report && report.reportContent) {
    return report.reportContentList.map((item, i) => (
      <a
        key={i}
        title={scanTitleNameBuilder({ scanName: item.file })}
        className="btn btn-light btn-sm mr-1 mb-1"
        href={urlBuilder({ scanName: item.file })}
        {...{ download: true }}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="d-flex align-items-center">
          <span>
            {scanNameBuilder({ scanName: item.file })}
          </span>
          <span
            className="font-xl d-inline px-1 ml-1"
            onClick={(e) => {
              e.preventDefault();
              deleteReport(report.id, item.file);
            }}
          >
            &times;
          </span>
        </div>
      </a>
    ));
  }
};

const deleteReport = async (reportId, fileName) => {
  if (!window.confirm("Are you sure?")) return false;
  await axios.delete(`/reports/deleteReportFile/${reportId}/${fileName}`);
  store.dispatch(fetchVisits());
  toastr.success("Success", "Report was deleted");
};

export const renderReceiptInfo = (_, { paymentStatus, receiptFile }) => {
  switch (paymentStatus) {
    case PAYMENT_CONSTANTS.PAID:
      return (
        <div>
          <i className="icon-doc text-primary mr-2" />{" "}
          {receiptFile ? (
            <a
              className="text-primary"
              href={receiptStorageUri + receiptFile}
              download="key"
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {receiptFile}
            </a>
          ) : (
            <span>No receipt</span>
          )}
        </div>
      );
    case PAYMENT_CONSTANTS.PAID_BY_PATIENT:
      return (
        <div>
          <i className="icon-check text-success mr-2" /> Paid by patient
        </div>
      );
    case PAYMENT_CONSTANTS.PAID_BY_DENTIST:
      return (
        <div>
          <i className="icon-check text-success mr-2" /> Paid by dentist
        </div>
      );
    case PAYMENT_CONSTANTS.PAYMENT_FAILED:
      return (
        <div className="text-danger">
          <i className="icon-close mr-2" /> Payment failed
        </div>
      );
    default:
      break;
  }
};

const nameFormatter = (cell) => {
  const { lastName, firstName } = cell;
  return `${lastName} ${firstName}`;
};

export const emailFormatter = (cell) => {
  return ellipseText(cell);
};

const hasCardFormatter = (hasCard) => {
  if (hasCard) {
    return (
      <div>
        <i className="icon-check text-success mr-2" />
      </div>
    );
  } else {
    return (
      <div>
        <i className="icon-check  text-warning mr-2" />
      </div>
    );
  }
};

const specialityFormatter = (speciality) => {
  return <div>{formatSpeciality(speciality)}</div>;
};

const receiptFormatter = (receiptFile, cell) => {
  if (!receiptFile) {
    return "";
  }

  return receiptFile && !cell.paidByPatient ? (
    <div>
      <i className="icon-doc text-primary mr-2" />{" "}
      <a
        className="text-primary"
        href={receiptStorageUri + receiptFile}
        download="key"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        {receiptFile}
      </a>
    </div>
  ) : (
    <span>n/a</span>
  );
};

const invoiceFormatter = (cell, row) => {
  return (
    <div>
      <a
        className="text-primary"
        href={row.fileUrl}
        download="key"
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        Preview
      </a>
    </div>
  );
};

const voucherStatusFormatter = (cell, row) => {
  let statusStyle = {
    display: "inline-block",
    borderRadius: "4px",
    padding: "6px 12px 6px 12px",
  };

  switch (row.status) {
    case VOUCHER_STATUSES[0].value:
      statusStyle.backgroundColor = "#FFF5E0";
      statusStyle.color = "#DCB768";
      break;
    case VOUCHER_STATUSES[1].value:
      statusStyle.backgroundColor = "#D8F0DB";
      statusStyle.color = "#7DD187";
      break;
    case VOUCHER_STATUSES[2].value:
      statusStyle.backgroundColor = "#F7DAD8";
      statusStyle.color = "#DC6F6A";
      break;
    default:
      break;
  }

  return (
    <div style={statusStyle}>
      <span>{VOUCHER_STATUSES[row.status].label}</span>
    </div>
  );
};

const voucherAmountFormatter = (cell, row) => {
  return (
    <span>{`${row.point && row.point !== null ? `${row.point}£` : ""}`}</span>
  );
};

// Configs
export const commonTableConfig = (isRadiologist) => [
  {
    dataField: isRadiologist ? "mainId" : "visitId",
    text: "ID",
    sort: true,
    headerStyle: () => {
      return { width: "5%" };
    },
  },
  isRadiologist
    ? sameDayProcessingCell
    : {
        dataField: "visitDate",
        text: "Visit",
        sort: true,
        formatter: dateFormatter,
      },
  {
    dataField: "patientName",
    text: "Patient",
    sort: true,
  }
].filter(Boolean);

// Most likely confirmation modal
const configs = ({ hasLinkedAccounts, deleteScan, history }) => ({
  dentist: [
    hasLinkedAccounts ? dentistCell : null,
    scanCell,
    // postDicomUrlCell,
    reportCell(),
    paymentTypeCell,
    // paymentCell,
  ],
  receptionist: [
    dentistCell,
    scanCell,
    // postDicomUrlCell,
    paymentTypeCell,
    // paymentCell,
    receptDetailsCell(history),
  ],
  radiologist: [
    radiologistDentistCell,
    radiologistScanCell,
    reportCellForRadiologist(),
    deletePatient,
    // deleteReportPdf,
  ],
  radiographer: [
    dentistCell,
    // postDicomUrlCell,
    scanRadiographerCell(deleteScan),
  ],
});

// Cells
const scanCommonCell = {
  dataField: "scans",
  text: "Attached Scans",
  sort: false,
  headerStyle: () => {
    return { width: "20%" };
  },
};

const sameDayProcessingCell = {
  dataField: "reportDate",
  text: "Request Report",
  sort: true,
  headerStyle: () => {
    return { width: "15%" };
  },
  formatter: renderSameDayProcessingCell,
};

const radiologistPatientNameCell = {
  dataField: "patientName",
  text: "Patient",
  sort: true,
  formatter: (cell, row) => renderRadiologistPatientNameCell(row),
};

const scanCell = {
  ...scanCommonCell,
  formatter: (cell) => renderAttachedScans(cell),
};

const radiologistScanCell = {
  dataField: "scans",
  text: "Attached Scans",
  sort: false,
  headerStyle: () => {
    return { width: "20%" };
  },
  formatter: (cell, row) => {
    if (!row.isVisit && row.metaData.booking) {
      return renderAttachedScans(cell);
    }

    return row.isVisit
      ? renderAttachedScans(cell)
      : renderOrderReportAttachedScan(row);
  },
};

const deletePatient = {
  dataField: "id",
  text: "Delete patient",
  sort: false,
  formatter: (cell, row) => {
    return (
      <span
        className="link-span"
        onClick={(e) => {
          e.stopPropagation();
          if (window.confirm("Are you sure you want to delete?")) {
            deleteReportFromRadiology(row);
          }
        }}
      >
        Delete
      </span>
    );
  },
};

const deleteReportFromRadiology = async (row) => {
  try {
    const res = await axios.delete(`/reports/deleteReport/${row.report.id}`);
    store.dispatch(fetchVisits());
    toast.success(res.data, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (err) {
    toastr.error("Error", err);
  }
};

const deleteReportPdf = {
  dataField: "id",
  text: "Delete report",
  sort: false,
  formatter: (cell, row) => {
    return (
      <span
        className="link-span"
        onClick={(e) => {
          e.stopPropagation();
          deleteReportPdfFromRadiology(row);
        }}
      >
        Delete
      </span>
    );
  },
};

const deleteReportPdfFromRadiology = async (row) => {
  try {
    const res = await axios.delete(`/reports/deleteReportPdf/${row.report.id}`);
    store.dispatch(fetchVisits());
    toast.success(res.data, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (err) {
    toastr.error("Error", err);
  }
};

// const cloudCell = {
//   ...scanCommonCell,
//   text: "Web-based PACS",
//   dataField: "scanClouds",
//   formatter: (cell) => renderAttachedScans(filterCloudScan(cell)),
// };

const scanRadiographerCell = (handler) => ({
  ...scanCommonCell,
  formatter: (cell, row) =>
    renderAttachedScansForRadiographer(cell, handler, row.visitId),
});

const scanCloudRadiographerCell = (handler) => ({
  ...scanCommonCell,
  text: "Web-based PACS",
  dataField: "scanClouds",
  formatter: (cell, row) =>
    renderAttachedScansForRadiographer(
      filterCloudScan(cell),
      handler,
      row.visitId
    ),
});

const paymentCell = {
  dataField: "paymentStatus",
  text: "Payment",
  formatter: renderReceiptInfo,
  sort: true,
};

const paymentTypeCell = {
  dataField: "paymentType",
  text: "Payment type",
  sort: true,
};

const dentistCell = {
  dataField: "dentistName",
  text: "Referrer",
  sort: true,
};

const radiologistDentistCell = {
  dataField: "dentistName",
  text: "Referrer",
  sort: true,
  formatter: (cell, row) => renderRadiologistDentistCell(row),
};

const receptDetailsCell = (history) => {
  return {
    dataField: "options",
    text: "Receipt details",
    sort: false,
    formatter: (cell, row) => {
      return <div onClick={(e) => {
        e.stopPropagation();
        history.push(`${NAVIGATION_CONSTANTS.RECEIPT}/${row.visitId}`);
      }}><button
      className="btn btn-link">View</button></div>
    }
  }
}

// export const postDicomUrlCell = {
//   dataField: "postdicomPatientOrderId",
//   text: "PostDICOM URL",
//   sort: false,
//   formatter: (cell, row) => {
//     if (!cell) {
//       return <span className="text-muted">Not available</span>;
//     }
//     return (
//       <span onClick={async (event) => {
//         const toastId = toast("Generating url, please wait...", {
//           position: toast.POSITION.TOP_CENTER,
//           autoClose: false,
//           hideProgressBar: false,
//           progress: 0.1,
//           style: {
//             color: "#555",
//           },
//         });
//         event.stopPropagation();
//         const viewUrl = await generateViewUrl(row.postdicomPatientOrderId)
//         window.open(viewUrl, '_blank')
//         toast.done(toastId)
//       }}
//       className="link-span"
//       >
//         View
//       </span>
//     );
//   },
// };

// const radiationDoseCell = {
//     dataField: "dosageGiven",
//     text: "Dosage Given",
//     sort: true
// }

const reportCell = () => ({
  dataField: "report",
  text: "Radiology report",
  sort: false,
  sortValue: (cell) => {
    if (!cell) return false;
    return cell.reportContent && cell.reportContent.length;
  },
  formatter: (cell) => renderReport(cell),
});

const reportCellForRadiologist = () => ({
  dataField: "report",
  text: "Radiology report",
  sort: false,
  headerStyle: () => {
    return { width: "20%" };
  },
  sortValue: (cell) => {
    if (!cell) return false;
    return cell.reportContent && cell.reportContent.length;
  },
  formatter: (cell, row) =>
    row.isVisit
      ? renderReportForRadiologist(cell)
      : renderRadiologyReportCell(row),
});

// Builders
const getExtraConfig = (type, handlers) =>
  configs(handlers)[type].filter((i) => i);

export const buildTable = (type, handlers, isRadiologist = false) => [
  ...commonTableConfig(isRadiologist),
  ...getExtraConfig(type, handlers),
];

export const buildPastVisitsTable = (fn) => {
  return [
    {
      dataField: "visitDate",
      text: "Visit Date",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "scans",
      text: "Attached Scans",
      formatter: renderAttachedScans,
      headerStyle: () => {
        return { width: "30%" };
      },
    },
    // {
    //   dataField: "report",
    //   text: "Report",
    //   formatter: renderMiniReport
    // }
  ];
};

export const buildInvoicesTableForReceptionist = (fn) => {
  return [
    {
      dataField: "dentistFullName",
      text: "Dentist",
      sort: true,
    },
    {
      dataField: "month",
      text: "Month",
      sort: true,
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
    },
    {
      dataField: "",
      text: "",
      sort: false,
      formatter: (cell, row) => invoiceFormatter(cell, row),
    },
  ];
};

export const buildInvoicesTableForDentist = (fn) => {
  return [
    {
      dataField: "month",
      text: "Month",
      sort: true,
    },
    {
      dataField: "year",
      text: "Year",
      sort: true,
    },
    {
      dataField: "",
      text: "",
      sort: false,
      formatter: (cell, row) => invoiceFormatter(cell, row),
    },
  ];
};

export const buildVouchersTable = (fn) => {
  return [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
    },
    {
      dataField: "point",
      text: "Amount",
      sort: true,
      formatter: (cell, row) => voucherAmountFormatter(cell, row),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => voucherStatusFormatter(cell, row),
    },
  ];
};

function handleFileUpload(event, orderId) {
  console.log("loading....");
  const toastId = toast("Uploading file, please, wait...", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: false,
    hideProgressBar: false,
    progress: 0.1,
    style: {
      color: "#555",
    },
  });

  const data = new FormData();

  data.append("fullRadiologyReportFile", event.target.files[0]);
  data.append("orderId", orderId);

  axios
    .request({
      method: "POST",
      url: "/order-report/upload-fullradioloy-report-file",
      data,
      onUploadProgress: (p) => {
        const progress = p.loaded / p.total;
        toast.update(toastId, {
          progress,
        });
      },
    })
    .then(() => {
      toast.done(toastId);
      toastr.success("Success", "Uploaded successfully");
      store.dispatch(fetchRadiologyReports());
    })
    .catch((e) => {
      toastr.error("Error", e);
    });
}

export const buildRadiologyReportsTable = (fn) => {
  return [
    {
      dataField: "id",
      text: "ID",
      sort: true,
    },
    {
      dataField: "orderReportDate",
      text: "Order Date",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "patientName",
      text: "Patient name",
      sort: true,
      formatter(cell, { booking, patientName, patientSurname }) {
        if (booking) {
          const { firstName, lastName } = booking.patient;
          return (
            <span>
              {firstName} {lastName}
            </span>
          );
        }

        return (
          <span>
            {patientName} {patientSurname}
          </span>
        );
      },
    },
    {
      dataField: "scanUrl",
      text: "Scan file",
      formatter(cell, { scanUrl, scanName }) {
        if (!scanUrl) {
          return (
            <span>
              <i className="icon-exclamation text-info mr-2" />
              Not uploaded
            </span>
          );
        }
        return (
          <a
            title={scanName}
            className="btn btn-light btn-sm mr-1 mb-1"
            href={scanUrl}
            download
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <span>{scanName}</span>
          </a>
        );
      },
    },
    {
      dataField: "fullRadiologyReportUrl",
      text: "Radiologist report",
      formatter(cell, { id, fullRadiologyReportUrl, fullRadiologyReportName }) {
        if (fullRadiologyReportUrl) {
          return (
            <a
              title={fullRadiologyReportName}
              className="btn btn-light btn-sm mr-1 mb-1"
              href={fullRadiologyReportUrl}
              download
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <span>
                {fullRadiologyReportName}
              </span>
            </a>
          );
        }

        const userRole = store.getState().profile.profileReducer.profile.role;

        if (isUserDentist(userRole)) {
          return renderPendingSection();
        }

        if (!fullRadiologyReportUrl) {
          return (
            <label
              tabIndex={0}
              onClick={(e) => e.stopPropagation()}
              className="link-span"
            >
              + Add file
              <input
                type="file"
                className="d-none"
                onChange={(event) => handleFileUpload(event, id)}
              />
            </label>
          );
        }
      },
    },
  ];
};

export const buildRegisterAuditReportTable = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    headerStyle: () => {
      return { width: "5%" };
    },
  },
  {
    dataField: "name",
    text: "Name",
    sort: true,
  },
  {
    dataField: "gdc",
    text: "GDC",
    sort: true,
  },
  {
    dataField: "hasCard",
    text: "Credit Card",
    sort: true,
    formatter: hasCardFormatter,
  },
  {
    dataField: "speciality",
    text: "Speciality",
    sort: true,
    formatter: specialityFormatter,
  },
  {
    dataField: "nameOfPractice",
    text: "Name of Practice",
    sort: true,
  },
  {
    dataField: "telephoneNumber",
    text: "Phone",
    sort: true,
    headerStyle: () => {
      return { width: "150px" };
    },
  },
  {
    dataField: "mobile",
    text: "Mobile",
    sort: true,
    headerStyle: () => {
      return { width: "150px" };
    },
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
    formatter: emailFormatter
  },
  {
    dataField: "city",
    text: "City",
    sort: true,
  },
  {
    dataField: "postCode",
    text: "Postal Code",
    sort: true,
  },
  {
    dataField: "address",
    text: "Address",
    sort: true,
  },
  {
    dataField: "linkedCode",
    text: "Linked Code",
    sort: true,
  },
  {
    dataField: "createdDate",
    text: "Registration Date",
    sort: true,
    formatter: dateFormatterShort,
  },
];

export const buildAuditReportTable = [
  {
    dataField: "visitId",
    text: "ID",
    sort: true,
    headerStyle: () => {
      return { width: "5%" };
    },
  },
  {
    dataField: "date",
    text: "Visit Date",
    sort: true,
    formatter: dateFormatterShort,
  },
  {
    dataField: "dentistName",
    text: "Referrer",
    sort: true,
  },
  {
    dataField: "patientName",
    text: "Patient",
    sort: true,
  },
  {
    dataField: "servicesProvided",
    text: "Services provided",
    sort: false,
    formatter: servicesFormatter,
  },
  {
    dataField: "totalAmount",
    text: "Total, £",
    sort: true,
  },
  {
    dataField: "receiptName",
    text: "Receipt #",
    formatter: (cell, row) => receiptFormatter(cell, row),
    sort: true,
  },
];

export const buildRadiologistReportTable = (cb, isExpanded) => [
  {
    dataField: "radiologistName",
    text: "Radiologist",
    sort: true,
    headerStyle: () => {
      return { width: "38%" };
    },
  },
  {
    dataField: "reportsInTimeRange",
    text: "Report during selected time",
    sort: true,
    headerStyle: () => {
      return { width: "33%" };
    },
  },
  {
    dataField: "options",
    text: "Expand/Collapse all",
    sort: true,
    headerStyle: () => {
      return {
        width: "33%",
        textAlign: "right",
      };
    },
    headerFormatter: (column) => {
      return <span className="link-span">Expand/Collapse all</span>;
    },
    headerEvents: {
      onClick: cb,
    },
    formatter: (cell, row) => (
      <div className="text-right">
        <span className="link-span">Expand/Collapse</span>
      </div>
    ),
  },
];

export const buildPatientsSatisfactionReportTable = (cb) => [
  {
    dataField: "bookingId",
    text: "BookingId",
    sort: true,
  },
  {
    dataField: "patientName",
    text: "Patient name",
    sort: true,
  },
  {
    dataField: "submittedDate",
    text: "Submitted Date",
    sort: true,
    formatter: dateFormatter,
  },
  {
    dataField: "visitedDate",
    text: "Visited date",
    sort: true,
    formatter: dateFormatter,
  },
  {
    dataField: "overallExperience",
    text: "Overall Experience",
    sort: true,
    formatter: (cell) => (
      <div>
        <span>{upperFirst(toLower(cell.split("_").join(" ")))}</span>
      </div>
    ),
  },
  {
    dataField: "options",
    text: "Options",
    headerStyle: () => {
      return {
        width: "33%",
        textAlign: "right",
      };
    },
    formatter: (cell, row) => (
      <div className="text-right">
        <span className="link-span" onClick={() => cb(row)}>
          View
        </span>
      </div>
    ),
  },
];

export const buildRadiologistReportTableMini = [
  {
    dataField: "visitId",
    text: "Visit ID",
    sort: true,
    headerStyle: () => {
      return { width: "5%" };
    },
  },
  {
    dataField: "submittedDate",
    text: "Submitted Date",
    sort: true,
    formatter: dateFormatter,
  },
  {
    dataField: "patient",
    text: "Patient",
    sort: true,
    sortValue: (cell) => cell.lastName,
    formatter: nameFormatter,
  },
  {
    dataField: "dentist",
    text: "Referrer",
    sort: true,
    sortValue: (cell) => cell.lastName,
    formatter: nameFormatter,
  },
];

export const buildJoinedTable = (fn) => {
  return [
    {
      dataField: "firstName",
      text: "Dentist",
      sort: true,
      formatter: (cell, row) => nameFormatter(row),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: emailFormatter
    },
    {
      dataField: "mobile",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "options",
      text: "",
      formatter: (cell, row) => {
        return (
          <button
            className="btn btn-light"
            onClick={(e) => {
              e.preventDefault();
              fn(row);
            }}
          >
            Unlink
          </button>
        );
      },
    },
  ];
};

const orderByDTO = field => {
  if(field.endsWith('-visit') || field.endsWith('-order')) {
    return 'id';
  }
  const map = {
    mainId: 'id',
    visitId: 'id',
    patientName: 'patient',
    dentistName: 'referrer',
    visitDate: 'visit',
    reportDate: 'visit'
  }

  return map[field] || field;
}

  export const addSort =  (filters, fetchVisits, node) => c =>  ({
    ...c,
    onSort: sortFunctor(filters, fetchVisits, node)
  })

export const sortFunctor =  (filters, fetchVisits, node) => (field, order) => {
  console.log(field, order, field, order, filters, fetchVisits);
  const desc = order.toLowerCase() === "desc"
  const orderBy = orderByDTO(field)
  if(node?.paginationContext) {
    node.paginationContext.currPage= 1; // reset pagination
  }
  
  fetchVisits({
     ...filters,
    criteria: filters.searchString, 
    page: 1, 
    size: filters.size,
    orderBy, 
    desc
  });
}

export const buildOptions =	(fetchFn, filters, visitCount, setFilters) => ({
  totalSize: visitCount,
  onPageChange:  (page, size) => fetchFn({ ...filters, criteria: filters.searchString, page, size }),
  onSizePerPageChange: (size, page) =>  {
    setFilters({ ...filters, size });
    fetchFn({ ...filters, criteria: filters.searchString, page, size })
  },
  paginationSize: 4,
  pageStartIndex: 1,
  firstPageText: 'First',
  prePageText: 'Back',
  nextPageText: 'Next',
  lastPageText: 'Last',
  nextPageTitle: 'First page',
  prePageTitle: 'Pre page',
  firstPageTitle: 'Next page',
  lastPageTitle: 'Last page',
  showTotal: true,
  disablePageTitle: true,
});

export const rowEvents = onRowSelected => ({
  onClick: (e, row, rowIndex, type) => {
    onRowSelected(e, row, rowIndex, type);
  }
});