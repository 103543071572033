import React, { Fragment } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {
	buildRadiologyReportsTable,
  rowEvents,
  addSort,
  buildOptions,
} from "../../../../services/table.service";

export default function RadiologyReportsTable({
	radiologyReports,
	onRowSelected,
	fetchRadiologyReports,
	reportCount,
	filters
}) {

	const columns = buildRadiologyReportsTable().map(addSort(filters, fetchRadiologyReports));

	const options = buildOptions(fetchRadiologyReports, filters, reportCount);

	const onTableChange = () => { }

	return (
		<Fragment>
			<BootstrapTable
				bootstrap4
				bordered={false}
				keyField="id"
				data={radiologyReports}
				columns={columns}
				rowEvents={rowEvents(onRowSelected)}
				remote
				onTableChange={onTableChange}
				pagination={paginationFactory(options)}
			/>
		</Fragment>
	);
}
