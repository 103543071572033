import React, { useState } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
  Button,
} from "reactstrap";

export default function ScansManualUpload({ handleAdd, label, btnLabel }) {
  const [url, setUrl] = useState("");
  const handleClick = () => {
    handleAdd(url);
  };
  return (
    <div className="form-group">
      <Row className="mt-1">
        <Col>
          <label>{label}</label>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <InputGroup>
            <input
              type="text"
              className="form-control"
              placeholder="Url"
              value={url}
              onChange={e => setUrl(e.target.value)}
            />
            <InputGroupAddon addonType="append">
              <Button
                className="btn btn-primary"
                color="primary"
                onClick={handleClick}
                disabled={url.trim() === ""}
              >
                {btnLabel}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}
