import { createSelector } from "reselect";
import { isUserRadiologist } from "../../services/permission.service";

const visitsSelector = ({ visits }) => visits;
const userProfileSelector = ({ profile }) => profile;
const radiologyReportsSelector = ({ radiologyReports }) => radiologyReports;

export const getVisitCount = createSelector(
  visitsSelector,
  ({ visitsReducer }) => {
    return visitsReducer.visitCount;
  }
);

export const getVisitFilter = createSelector(
  visitsSelector,
  ({ visitsReducer }) => {
    return visitsReducer.filters;
  }
);

export const getVisitsReceipt = createSelector(
  visitsSelector,
  ({ visitsReceiptReducer }) => {
    return visitsReceiptReducer.data;
  }
);

export const getVisits = createSelector(
  [visitsSelector, userProfileSelector, radiologyReportsSelector],
  (
    { visitsReducer },
    { profileReducer: { profile } },
    { radiologyReportsListReducer }
  ) => {

    let result = [];
    if (isUserRadiologist(profile.role)) {
      const query = visitsReducer.filters.searchString
        ? visitsReducer.filters.searchString.toLowerCase()
        : "";
      const { startDate, endDate } = visitsReducer.filters;

      result = getDataForRadiologist(
        visitsReducer,
        query,
        radiologyReportsListReducer
      );
  
      return result
    } else {
      return visitsReducer.data;
    }
  }
);

export const getPastVisits = createSelector(
  visitsSelector,
  ({ visitsPastReducer }) => {
    return visitsPastReducer.data;
  }
);

function getDataForRadiologist(
  visitsReducer
) {

  const data = []
  for(const item of visitsReducer.data) {
    if(item.type === 'visit') {
      console.log(item.id, item.type)
      data.push({
        mainId: item.visitId,
        reportDate: item.visitDate,
        patientName: item.patientData.name,
        dentistName: item.dentistName,
        scans: item.scans,
        report: item.report,
        sameDayProcessing: item.sameDayProcessing,
        isVisit: true,
        metaData: item,
      })
    }
    //order hide orders from visit
    if(item.type === 'report') {
        console.log(item.id, item.type)
        const patientName = item?.patientData?.name || item?.report?.patientName || item?.booking?.patient?.name;
        const dentistName = item.dentistName ? `${item.dentistName}` : `${item.dentist.firstName} ${item.dentist.lastName}`;
        
        data.push({
          mainId: item.id,
          reportDate: item.orderReportDate,
          patientName,
          dentistName,
          scan: {
            scanName: item.scanName,
            scanUrl: item.scanUrl,
          },
          scans: item.scans ? item.scans : [],
          report: {
            fullRadiologyReportName: item.fullRadiologyReportName,
            fullRadiologyReportUrl: item.fullRadiologyReportUrl,
            fullRadiologyReport: item.fullRadiologyReport,
            dentistName,
          },
          sameDayProcessing: false,
          isVisit: false,
          metaData: item,
        })
    }
  }

  return data
}

export const getVisitsUpdateLoading = createSelector(
  visitsSelector,
  ({ visitsUpdateReducer }) => {
    return visitsUpdateReducer.isUpdating;
  }
);