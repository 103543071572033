import * as t from "../action-types";

const initialUserProfileState = {
  profile: {},
  editMode: false
};

export const profileReducer = (state = initialUserProfileState, action) => {
  switch (action.type) {
    case t.SET_USER_PROFILE:
      return {
        ...state,
        profile: action.profile
      };
    case t.TOGGLE_EDIT_MODE:
      return {
        ...state,
        editMode: !state.editMode
      };
    default:
      return state;
  }
};
