import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { dateFormatShort } from "../../../../constants/global.constants";
import moment from "moment";

export default function SelectedPatientDetails({
  patient: { address, city, dob, email, gender, mobile }
}) {
  const formAddress = () => `${city} ${address}`;
  return (
    <Card body>
      <CardBody className="p-0">
        <Row>
          <Col xs="2">Date of birth:</Col>
          <Col xs="4">{moment(dob).format(dateFormatShort)}</Col>
          <Col xs="2">Phone:</Col>
          <Col xs="4">{mobile}</Col>
        </Row>
        <hr />
        <Row>
          <Col xs="2">Email:</Col>
          <Col xs="4">{email}</Col>
          <Col xs="2">Address:</Col>
          <Col xs="4">{formAddress()}</Col>
        </Row>
      </CardBody>
    </Card>
  );
}
