import axios from "../axios-client";
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localStorage.constants";
import { toastr } from "react-redux-toastr";
import { NAVIGATION_CONSTANTS } from "../constants/navigation.constants";
import {
  auditReportStorageUri,
  backendDateFormat,
  dateFormatReports,
} from "../constants/global.constants";
import { API_CONSTANTS } from "../modules/radiologists/constants";
import Moment from "moment";

export const checkVoucherCode = (code) => {
  return axios
    .get("/voucher/check-voucher", { params: { code } })
    .then((res) => res.data);
};

export const applyVoucher = (bookingId, voucherCode) => {
  return axios
    .post("/bookings/apply-voucher", {}, { params: { bookingId, voucherCode } })
    .then((res) => res.data);
};

export const impersonateDentist = async (id, history) => {
  try {
    const result = await axios.get(`/receptionist/impersonate/${id}`);
    localStorage.setItem(
      LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN,
      result.headers["authorization"]
    );
    history.push(NAVIGATION_CONSTANTS.DASHBOARD);
    window.location.reload(true);
  } catch (error) {
    toastr.error("Error", "Please enter valid data");
  }
};

export const handleReportDownload = (e, query) => {
  e.preventDefault();
  axios
    .get(
      auditReportStorageUri +
        `?startDate=${query.startDate.format(
          backendDateFormat
        )}&endDate=${query.endDate.format(backendDateFormat)}`,
      {
        responseType: "blob",
      }
    )
    .then((response) => downloadDocumentHelper(response, query, "audit-report"))
    .catch((e) => toastr.error("Error", e));
};

export const handleRadiologistReportDownload = (e, query) => {
  e.preventDefault();
  axios
    .post(API_CONSTANTS.DOWNLOAD_RADIOLOGIST_REPORT, query, {
      responseType: "blob",
    })
    .then((response) =>
      downloadDocumentHelper(response, query, "radiologist-report")
    )
    .catch((e) => toastr.error("Error", e));
};

export const handlePatientSatisfactionReportDownload = (e, query) => {
  e.preventDefault();
  axios
    .get(
      `${
        API_CONSTANTS.DOWNLOAD_PATIENTS_SATISFACTION_REPORT
      }?startDate=${query.startDate.format(
        backendDateFormat
      )}&endDate=${query.endDate.format(backendDateFormat)}`,
      {
        responseType: "blob",
      }
    )
    .then((response) =>
      downloadDocumentHelper(response, query, "patients-satisfaction-report")
    )
    .catch((e) => toastr.error("Error", e));
};

export const handleAuditRegistrationReportDownload = (e, query) => {
  e.preventDefault();
  axios
    .get(
      `${
        API_CONSTANTS.DOWNLOAD_AUDIT_REGISTRATION_REPORT
      }?startDate=${query.startDate.format(
        backendDateFormat
      )}&endDate=${query.endDate.format(backendDateFormat)}`,
      {
        responseType: "blob",
      }
    )
    .then((response) =>
      downloadDocumentHelper(response, query, "registration-audit-report")
    )
    .catch((e) => toastr.error("Error", e));
};

const downloadDocumentHelper = (response, query, name) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `${Moment(query.startDate).format(dateFormatReports)}-${Moment(
      query.endDate
    ).format(dateFormatReports)}-${name}.xlsx`
  ); //or any other extension
  document.body.appendChild(link);
  link.click();
};

export const uploadReceiptPdf = (file,bookingId, total) => {
  const formData = new FormData();
  formData.append("pdf", file);
  return axios.post(`${API_CONSTANTS.UPLOAD_RECEIPT}/${bookingId}/${total}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  })
    .then(() =>       toastr.success("Success", "Email was Sent"))
    .catch((e) => toastr.error("Error", e));
};