import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { buildPastVisitsTable } from "../../../../services/table.service";

export default class PastVisits extends Component {
  componentDidMount() {
    let bookingDetails = this.props.bookingInfo;
    if (!!bookingDetails) {
      const patientId = bookingDetails.patient.id;
      this.props.retrievePreviousVisits(patientId);
    }
  }

  componentDidUpdate(prevProps){
    const prevPatientId = prevProps.bookingInfo.patient.id;
    const currentPatientId = this.props.bookingInfo.patient.id;

    if(currentPatientId !== prevPatientId) {
      this.props.retrievePreviousVisits(currentPatientId)
    }
  }

  buildPreviousVisitsTable() {
    const columns = buildPastVisitsTable();

    return (
      <BootstrapTable
        bootstrap4
        bordered={false}
        keyField="visitId"
        data={this.props.visitsByPatient}
        columns={columns}
      />
    );
  }

  render() {
    return <div>{this.buildPreviousVisitsTable()}</div>;
  }
}
