import React, { useEffect, useCallback } from "react";
import {
  ListGroup,
  ListGroupItem,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody
} from "reactstrap";
import Moment from "moment";
import {uniqBy} from "lodash";
import { presentationDateFormatTable } from "../../../../constants/global.constants";

const popoverBodyStyle = {
  padding: "0px"
};

const renderTimeSlot = (appointmentEndTime, appointmentStartTime) => {
  return `${Moment(appointmentStartTime).format(
    presentationDateFormatTable
  )} -  ${Moment(appointmentEndTime).format(presentationDateFormatTable)}`;
};

export default function OccupiedSlots({
  occupiedSlots,
  endDate,
  notifyHasConflict
}) {
  const isSameDate = (appointmentStartTime, endDate) =>
    Moment(appointmentStartTime).isSame(endDate);

  const hasConflict = useCallback(
    () => occupiedSlots.some(i => isSameDate(i.appointmentStartTime, endDate)),
    [endDate, occupiedSlots]
  );

  useEffect(() => {
    notifyHasConflict(hasConflict());
  }, [hasConflict, notifyHasConflict, occupiedSlots]);

  return (
    <span>
      <i
        className="icon-question ml-2"
        id="DatesDownPopover"
        style={{
          color: hasConflict() ? "red" : null
        }}
      />
      <UncontrolledPopover
        className="popover-override"
        placement="auto"
        target="DatesDownPopover"
        trigger="hover"
      >
        <PopoverHeader>Occupied slots</PopoverHeader>
        <PopoverBody style={popoverBodyStyle}>
          <ListGroup>
            {uniqBy(occupiedSlots, 'appointmentStartTime').map(
              ({ appointmentEndTime, appointmentStartTime }, index) => (
                <ListGroupItem
                  key={index}
                  style={{
                    textDecoration:
                      isSameDate(appointmentStartTime, endDate) && "underline",
                    color: isSameDate(appointmentStartTime, endDate) && "red"
                  }}
                >
                  {renderTimeSlot(appointmentEndTime, appointmentStartTime)}
                </ListGroupItem>
              )
            )}
          </ListGroup>
        </PopoverBody>
      </UncontrolledPopover>
    </span>
  );
}
