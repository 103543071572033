import { FetchReceiptRoutine, CLEAR_RECEIPT } from "../action-types";

const initialVisitsState = {
  isFetching: false,
  error: null,
  data: {},
};

export const visitsReceiptReducer = (state = initialVisitsState, action) => {
  switch (action.type) {
    case FetchReceiptRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FetchReceiptRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FetchReceiptRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case CLEAR_RECEIPT: {
      return {
        ...initialVisitsState,
      };
    }

    default:
      return state;
  }
};
