import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import {
	FetchDataRoutine,
	SET_FILTERS,
	UPDATE_REPORT,
	FetchPastVisitsRoutine,
	UPDATE_SCAN,
	UPDATE_SCAN_UPLOAD,
} from "./action-types";
import { API_CONSTANTS } from "./constants";
import { getBookingInfo } from "../dashboard/actions";
import { FetchReceiptRoutine, CLEAR_RECEIPT } from '../visits/action-types';

export const fetchVisits = ({ page = 1, size = 10, criteria, startDate, endDate, orderBy = 'visits', desc = true } = {}) => async (dispatch) => {
	dispatch(FetchDataRoutine.request());
	try {
		const { data } = await axios.get(`${API_CONSTANTS.GET_VISITS}`, { params: { page, size, criteria, startDate, endDate, orderBy, desc } });
		dispatch(FetchDataRoutine.success(data));
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const setFilters = (filters) => ({
	type: SET_FILTERS,
	filters,
});

export const updateReport = (payload) => ({
	type: UPDATE_REPORT,
	payload,
});

export const updateScan = (payload) => ({
	type: UPDATE_SCAN,
	payload,
});

export const updateScanUpload = (scans) => ({
	type: UPDATE_SCAN_UPLOAD,
	scans,
});

export const requestReport = ({
	visitId,
	radiologistId,
	sameDayProcessing,
}) => async (dispatch) => {
	dispatch(FetchDataRoutine.request());
	try {
		const { data } = await axios.post(`/visits/report/`, {
			visitId,
			radiologistId,
			sameDayProcessing,
		});
		dispatch(
			updateReport({
				visitId,
				report: data,
			})
		);
		toastr.success("Success", "Report was requested");
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const uploadScans = ({
	files,
	bookingId,
	isReport,
	onUploadProgress,
}) => async (dispatch) => {
	dispatch(FetchDataRoutine.request());
	var formData = new FormData();
	formData.append("bookingId", bookingId);
	for (let file of files) {
		formData.append("files", file);
	}

	try {
		await axios.post(isReport === true ? `/reports` : `/scans`, formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
			onUploadProgress,
		});
		toastr.success(
			"Success",
			isReport === true ? "Reports were uploaded" : "Scans were uploaded"
		);
		dispatch(fetchVisits());
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const addScanLink = ({ scanUrl, visitId }) => async (dispatch) => {
	dispatch(FetchDataRoutine.request());
	try {
		await axios.post(`/scans/url`, { scanUrl, visitId });
		toastr.success("Success", "Scans were uploaded");
		dispatch(fetchVisits());
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const addLinkDosageForScan = ({ dosage, visitId }) => async (
	dispatch
) => {
	dispatch(FetchDataRoutine.request());
	try {
		//   await axios.post(`/scans/url`, { dosage, visitId });
		toastr.success("Success", "Need correct link from backend");
		dispatch(fetchVisits());
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const deleteScan = ({ visitId, scanId, isReport }) => async (
	dispatch
) => {
	dispatch(FetchDataRoutine.request());
	try {
		await axios.delete(isReport ? `/reports/${visitId}` : `/scans/${scanId}`);
		dispatch(
			updateScan({
				visitId,
				scanId,
			})
		);
		toastr.success("Success", "Report was deleted");
		dispatch(fetchVisits());
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchDataRoutine.failure(e));
	}
};

export const uploadReport = (data) => async (dispatch) => {
	try {
		await axios.patch(`/visit/${data.visitId}/report`, {
			reportContent: data.reportContent,
		});
		dispatch(fetchVisits());
		dispatch(getBookingInfo(data.visitId));
		toastr.success("Success", "Report was updated");
	} catch (e) {
		toastr.error("Error", e);
	}
};

export const retrievePreviousVisits = (patientId) => async (dispatch) => {
	dispatch(FetchPastVisitsRoutine.request());
	try {
		const { data } = await axios.get(
			`/visits/history/patient/${patientId}`,
			patientId
		);
		dispatch(FetchPastVisitsRoutine.success(data));
	} catch (e) {
		toastr.error("Error", e);
		dispatch(FetchPastVisitsRoutine.failure(e));
	}
};

export const fetchReceiptDetails =  (visitId) => async (dispatch) => {
  dispatch(FetchReceiptRoutine.request(visitId));
  
  try {
    const { data }  = await axios.get(`/receipts/${visitId}/patient`)
    dispatch(FetchReceiptRoutine.success(data));
  } catch (error) {
    dispatch(FetchReceiptRoutine.error({visitId, error}));
  }
}

export const cleanReceiptDetails = () => dispatch => dispatch(({ type: CLEAR_RECEIPT }))