//Project dependencies
import React, { Fragment, useEffect, useState } from "react";
//Module dependencies
import visitsAPI from "../../modules/visits";
import radiologyReportsAPI from "../../modules/radiologyReports";
import visitDetailsAPI from "../../modules/visit-details";
import { Container, Row, Col } from "reactstrap";
import useModal from "../../hooks/useModal";
import Modal from "../../common/modals/Modal";
import { isEmpty } from "lodash";
import NoData from "../../common/NoData";
import { useInterval } from "../../hooks/useInterval";
import { recheckTimer } from "../../constants/global.constants";
import CreateEditPatient from "../../modules/patient/components/CreateEditPatient";
import { companyInfo } from "../../services/legal.service";
import './index.css'

import {
	isUserReceptionist,
	isUserDentist,
	isUserRadiologist,
	isUserRadiographer,
	isUserLeadRadiographer
} from "../../services/permission.service";
import DefaultLayout from "../../layouts/DefaultLayout";
import ShareScans from "../../modules/visits/components/ShareScans";
import ReceptionistShareScans from "../../modules/visits/components/ReceptionistShareScans";
import axios from '../../axios-client';
import moment from 'moment';

const {
	VisitsFilter,
	DentistTable,
	ReceptionistTable,
	RadiologistTable,
	RadiographerTable,
} = visitsAPI.components;

const {
	VisitDetailsPopUp,
	VisitDetailsRadiologistPopUp,
} = visitDetailsAPI.components;

const { ReportDetailsPopup } = radiologyReportsAPI.components;

const Visits = ({
	userRole,
	userInfo,
	hasLinkedAccounts,
	dentistActions: { fetchDentists },
	visitsActions: {
		fetchVisits,
		uploadScans,
		addScanLink,
		setFilters,
		requestReport,
		uploadReport,
		deleteScan,
		addLinkDosageForScan,
        fetchReceiptDetails,
	},
	radiologyReportsActions: { fetchRadiologyReports },
	logoutAction,
	visitDetailsActions: { retrievePreviousVisits, addComment },
	dashboardActions: { getBookingInfo },
	profileActions: { getUserProfile },
	radiologistsActions: { fetchRadiologists },
	patientActions: { editPatient },
	dentists,
	radiologists,
	history,
	bookingInfo,
	visitsByPatient,
	visits,
	visitCount,
	filters
}) => {
	const [selectedVisit, setSelectedVisit] = useState(null);
	const [editPatientPopUp, setEditPatientPopUp] = useState(false);
	const [shareScans, setShareScans] = useState(false);
	const [receptionistShareScans, setReceptionistShareScans] = useState(false);
	const { handleModalVisibilityToggle, showModal } = useModal();
	const [patientToEdit, setPatientToEdit] = useState({});
	const [attachedScans, setAttachedScans] = useState([]);
    const [reports, setReports] = useState([]);

	useEffect(() => {
		fetchVisits()
		getUserProfile();
		if (isUserRadiologist(userRole)) {
			fetchRadiologyReports()
		}
		return () => setFilters({});
	}, [
		fetchVisits,
		fetchRadiologyReports,
		getUserProfile,
		setFilters,
		userRole,
	]);

	useEffect(() => {
		showModal && fetchRadiologists();
	}, [fetchRadiologists, showModal]);

	useInterval(() => {
		fetchVisits();
	}, recheckTimer);

	const handleConfirmRequest = (data) => {
		handleModalVisibilityToggle();
		requestReport({
			visitId: selectedVisit.visitId,
			radiologistId: companyInfo.defaultRadiologistId,
			sameDayProcessing: data.sameDayProcessing,
		});
		setSelectedVisit(null);
	};

	const handlePatientEdit = (editedPatient) => {
		setEditPatientPopUp(false);
		editPatient(
			{ id: patientToEdit.id, ...editedPatient },
			true,
			selectedVisit.visitId
		);
	};

	const handleReportDelete = ({ id: scanId }, visitId, isReport) => {
		deleteScan({
			visitId,
			scanId,
			isReport,
		});

		setSelectedVisit(null);
	};

	const renderCorrectTable = () => {
		if (isEmpty(visits))
			return <NoData>No results which match the criteria</NoData>;
		if (isUserDentist(userRole)) {
			return (
				<DentistTable
					visits={visits}
					fetchVisits={fetchVisits}
					visitCount={visitCount}
					filters={filters}
                    setFilters={setFilters}
					hasLinkedAccounts={hasLinkedAccounts}
					onRowSelected={(e, row) => {
						e.stopPropagation();
						setSelectedVisit(row);
					}}
				/>
			);
		}
		if (isUserRadiologist(userRole)) {
			return (
				<RadiologistTable
					visits={visits}
					fetchVisits={fetchVisits}
					visitCount={visitCount}
					filters={filters}
                    setFilters={setFilters}
					onRowSelected={(e, row) => {
						e.stopPropagation();
						setSelectedVisit(row);
					}}
				/>
			);
		}
		if (isUserRadiographer(userRole)) {
			return (
				<RadiographerTable
					visits={visits}
					fetchVisits={fetchVisits}
					visitCount={visitCount}
					filters={filters}
                    setFilters={setFilters}
					onRowSelected={(e, row) => {
						e.stopPropagation();
						setSelectedVisit(row);
					}}
					deleteScan={handleReportDelete}
				/>
			);
		}
		if (isUserReceptionist(userRole)) {
			return (
				<ReceptionistTable
					visits={visits}
					fetchVisits={fetchVisits}
                    fetchReceiptDetails={fetchReceiptDetails}
					visitCount={visitCount}
					filters={filters}
                    setFilters={setFilters}
					onRowSelected={(e, row) => {
						e.stopPropagation();
						setSelectedVisit(row);
					}}
				/>
			);
		}

		return null;
	};

	const getAttachedScans = (patientId) => {
		axios.get(`/visits/patient/attached-scans/${patientId}`).then(response => {
			let attachedScans = [];
			response.data.map(s => attachedScans.push({
				value: s.id,
				label: s.scanName + ' / ' + moment.utc(s.creationAt).format('DD.MM.YYYY')
			}))
			setAttachedScans(attachedScans);
		})
	}

    const getReports = (patientId) => {
        axios.get(`/visits/patient/reports/${patientId}`).then(response => {
            let reports = [];
            response.data.map(r => reports.push({
                value: r.id,
                label: r.reportContent + ' / ' + moment.utc(r.submittedReportDate).format('DD.MM.YYYY')
            }))
            setReports(reports);
        })
    }

	const renderCorrectPopup = () => {
		if (!selectedVisit || showModal) return null;
		if (isUserRadiologist(userRole) && selectedVisit.isVisit) {
			return (
				<VisitDetailsRadiologistPopUp
					requestReport={handleModalVisibilityToggle}
					visitDetails={selectedVisit.metaData}
					onUpdatedReport={() => setSelectedVisit(null)}
					getBookingInfo={getBookingInfo}
					retrievePreviousVisits={retrievePreviousVisits}
					bookingInfo={bookingInfo}
					visitsByPatient={visitsByPatient}
					type="visit"
					uploadReport={uploadReport}
					onReportUpload={(files, onUploadProgress) => {
						uploadScans({
							files,
							bookingId: selectedVisit.mainId,
							isReport: true,
							onUploadProgress,
						});
					}}
					isUserRadiologist={isUserRadiologist(userRole)}
					isUserRadiographer={isUserRadiographer(userRole)}
					onClose={() => setSelectedVisit(null)}
					deleteScan={handleReportDelete}
					addComment={addComment}
				/>
			);
		}

		if (isUserRadiologist(userRole) && !selectedVisit.isVisit) {
			return (
				<ReportDetailsPopup
					userInfo={userInfo}
					close={() => setSelectedVisit(null)}
					report={selectedVisit.metaData}
				/>
			);
		}

		return (
			<VisitDetailsPopUp
				requestReport={handleModalVisibilityToggle}
				visitDetails={selectedVisit}
				getBookingInfo={getBookingInfo}
				retrievePreviousVisits={retrievePreviousVisits}
				bookingInfo={bookingInfo}
				visitsByPatient={visitsByPatient}
				type="visit"
				isUserRadiologist={isUserRadiologist(userRole)}
				isUserDentist={isUserDentist(userRole)}
				isUserRadiographer={isUserRadiographer(userRole)}
				isUserReceptionist={isUserReceptionist(userRole)}
				uploadReport={uploadReport}
				onClose={() => setSelectedVisit(null)}
				fetchRadiologists={fetchRadiologists}
				onPatientEdit={(patientToEdit) => {
					setEditPatientPopUp(true);
					setPatientToEdit(patientToEdit);
				}}
				onFileUpload={(files, onUploadProgress) => {
					uploadScans({
						files,
						bookingId: selectedVisit.visitId,
						isReport: false,
						onUploadProgress,
					});
				}}
				onReportUpload={(files, onUploadProgress) => {
					uploadScans({
						files,
						bookingId: selectedVisit.visitId,
						isReport: true,
						onUploadProgress,
					});
				}}
				onAddLinkForScan={(scanUrl) => {
					addScanLink({ scanUrl, visitId: selectedVisit.visitId });
					setSelectedVisit(visits.find((i) => i.id === selectedVisit.visitId));
				}}
				onAddLinkDosageForScan={(dosage) => {
					addLinkDosageForScan({ dosage, visitId: selectedVisit.visitId });
					setSelectedVisit(visits.find((i) => i.id === selectedVisit.visitId));
				}}
				deleteScan={handleReportDelete}
				addComment={addComment}
			/>
		);
	};

	return (
		<Fragment>
			<DefaultLayout
				history={history}
				fetchDentists={fetchDentists}
				dentists={dentists}
				isUserReceptionist={isUserReceptionist(userRole)}
				isUserDentist={isUserDentist(userRole)}
				isUserRadiologist={isUserRadiologist(userRole)}
				isUserLeadRadiographer={isUserLeadRadiographer(userRole)}
				logoutAction={logoutAction}
			>
				<Container fluid className="pt-4">
					<Row className="ap-headline">
						<Col>
							<h3>
								{isUserRadiologist(userRole) ? "Report requests" : "Visits"}
							</h3>
						</Col>
					</Row>
					<Row className="pt-4">
						<Col md="11">
							<VisitsFilter onFilter={setFilters} fetchVisits={fetchVisits} isUserRadiologist={isUserRadiologist} userRole={userRole} />
						</Col>
						{isUserDentist(userRole) && <Col md="1">
							<button onClick={() => {
								setShareScans(true);
							}} className="share-button">
								<svg className="share-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11 2.49995C10.9999 1.91338 11.2061 1.34546 11.5825 0.895559C11.9588 0.445654 12.4814 0.142411 13.0588 0.0388849C13.6361 -0.0646415 14.2315 0.0381412 14.7408 0.32925C15.25 0.620359 15.6406 1.08126 15.8444 1.63131C16.0481 2.18135 16.0519 2.78553 15.8552 3.33812C15.6585 3.89071 15.2737 4.35654 14.7682 4.65409C14.2627 4.95165 13.6687 5.06198 13.0901 4.9658C12.5115 4.86962 11.9851 4.57304 11.603 4.12795L4.88502 7.24795C5.03946 7.73739 5.03946 8.26251 4.88502 8.75195L11.603 11.8719C12.0069 11.4023 12.5707 11.0993 13.1852 11.0216C13.7997 10.9439 14.4212 11.0972 14.9292 11.4516C15.4372 11.8061 15.7955 12.3365 15.9347 12.9401C16.0739 13.5436 15.9841 14.1774 15.6826 14.7185C15.3812 15.2596 14.8896 15.6696 14.3031 15.8689C13.7166 16.0682 13.077 16.0427 12.5083 15.7974C11.9396 15.552 11.4822 15.1042 11.2247 14.5408C10.9673 13.9774 10.9282 13.3385 11.115 12.7479L4.39702 9.62795C4.06446 10.0155 3.62117 10.2919 3.1268 10.42C2.63243 10.5481 2.11069 10.5217 1.63176 10.3444C1.15283 10.1671 0.739697 9.84738 0.447928 9.42824C0.15616 9.00909 -0.000244141 8.51064 -0.000244141 7.99995C-0.000244141 7.48925 0.15616 6.99081 0.447928 6.57166C0.739697 6.15252 1.15283 5.83278 1.63176 5.65547C2.11069 5.47816 2.63243 5.45177 3.1268 5.57986C3.62117 5.70795 4.06446 5.98438 4.39702 6.37195L11.115 3.25195C11.0386 3.00862 10.9998 2.75501 11 2.49995Z" />
								</svg>
								<span className="text-margin">Share</span>
							</button>
						</Col>}

						{isUserReceptionist(userRole) && <Col md="1">
							<button onClick={() => {
								setReceptionistShareScans(true);
							}} className="share-button">
								<svg className="share-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M11 2.49995C10.9999 1.91338 11.2061 1.34546 11.5825 0.895559C11.9588 0.445654 12.4814 0.142411 13.0588 0.0388849C13.6361 -0.0646415 14.2315 0.0381412 14.7408 0.32925C15.25 0.620359 15.6406 1.08126 15.8444 1.63131C16.0481 2.18135 16.0519 2.78553 15.8552 3.33812C15.6585 3.89071 15.2737 4.35654 14.7682 4.65409C14.2627 4.95165 13.6687 5.06198 13.0901 4.9658C12.5115 4.86962 11.9851 4.57304 11.603 4.12795L4.88502 7.24795C5.03946 7.73739 5.03946 8.26251 4.88502 8.75195L11.603 11.8719C12.0069 11.4023 12.5707 11.0993 13.1852 11.0216C13.7997 10.9439 14.4212 11.0972 14.9292 11.4516C15.4372 11.8061 15.7955 12.3365 15.9347 12.9401C16.0739 13.5436 15.9841 14.1774 15.6826 14.7185C15.3812 15.2596 14.8896 15.6696 14.3031 15.8689C13.7166 16.0682 13.077 16.0427 12.5083 15.7974C11.9396 15.552 11.4822 15.1042 11.2247 14.5408C10.9673 13.9774 10.9282 13.3385 11.115 12.7479L4.39702 9.62795C4.06446 10.0155 3.62117 10.2919 3.1268 10.42C2.63243 10.5481 2.11069 10.5217 1.63176 10.3444C1.15283 10.1671 0.739697 9.84738 0.447928 9.42824C0.15616 9.00909 -0.000244141 8.51064 -0.000244141 7.99995C-0.000244141 7.48925 0.15616 6.99081 0.447928 6.57166C0.739697 6.15252 1.15283 5.83278 1.63176 5.65547C2.11069 5.47816 2.63243 5.45177 3.1268 5.57986C3.62117 5.70795 4.06446 5.98438 4.39702 6.37195L11.115 3.25195C11.0386 3.00862 10.9998 2.75501 11 2.49995Z" />
								</svg>
								<span className="text-margin">Share</span>
							</button>
						</Col>}
					</Row>
					<Row className="pt-4">
						<Col>{renderCorrectTable()}</Col>
					</Row>
					{renderCorrectPopup()}
					{editPatientPopUp && (
						<CreateEditPatient
							closeCreatePatient={() => {
								setEditPatientPopUp(false);
							}}
							mode="edit"
							patient={patientToEdit}
							editPatient={handlePatientEdit}
						/>
					)}
					{showModal && (
						<Modal
							onClose={() => {
								handleModalVisibilityToggle();
								setSelectedVisit(null);
							}}
							radiologists={radiologists}
							mode="request"
							visitDetails={selectedVisit}
							onConfirm={handleConfirmRequest}
						/>
					)}
					{shareScans && (
						<ShareScans
							attachedScans={attachedScans}
							setAttachedScans={setAttachedScans}
							getAttachedScans={getAttachedScans}
                            reports={reports}
                            setReports={setReports}
                            getReports={getReports}
							onClose={() => {
								setShareScans(false);
								setAttachedScans([]);
                                setReports([]);
							}}
						/>
					)}

					{receptionistShareScans && (
						<ReceptionistShareScans
							attachedScans={attachedScans}
							setAttachedScans={setAttachedScans}
							getAttachedScans={getAttachedScans}
                            reports={reports}
                            setReports={setReports}
                            getReports={getReports}
							onClose={() => {
								setReceptionistShareScans(false);
								setAttachedScans([]);
                                setReports([]);
							}}
						/>
					)}
				</Container>
			</DefaultLayout>
		</Fragment>
	);
};

Visits.propTypes = {};

export default Visits;
