import React from "react";
import {
  ListGroup,
  ListGroupItem,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { chain, sumBy } from "lodash";

const wrapperStyle = {
  display: "flex",
  alignItems: "center",
};

const popoverBodyStyle = {
  padding: "0px",
};

const overlineStyle = {
  textDecoration: "line-through",
  marginRight: "7px",
};

function renderPrice(calculatedPrice, voucherInfo, visitDetails) {
  if (voucherInfo && voucherInfo.discountAmount > 0) {
    const finalPrice =
      calculatedPrice > voucherInfo.discountAmount
        ? calculatedPrice - voucherInfo.discountAmount
        : 0;

    return (
      <div>
        <strong className="d-block mb-1">
          Estimated price:{" "}
          <span style={overlineStyle}>&pound; {calculatedPrice}</span>
          &pound; {finalPrice}
        </strong>
        <small className="d-block text-muted">
          Discounted price: &pound; {voucherInfo.discountAmount}
        </small>
      </div>
    );
  }

  return (
    <div>
      <strong>Estimated price: &pound; {calculatedPrice}</strong>
      {visitDetails && (
        <span className="text-muted d-block">
          ({visitDetails.selectedPayment})
        </span>
      )}
    </div>
  );
}

export default function PriceBreakDown({
  calculatedPrice,
  pricesBrakeDown,
  voucherInfo,
  visitDetails,
}) {
  const groupedPricesBrakeDown = chain(pricesBrakeDown)
    .groupBy("label")
    .map((items, key) => ({
      label: key,
      price: sumBy(items, "price"),
    }))
    .value();

  return (
    <div style={wrapperStyle}>
      {renderPrice(calculatedPrice, voucherInfo, visitDetails)}
      <i className="icon-question ml-2" id="priceBrakeDownPopover" />
      <UncontrolledPopover
        className="popover-override"
        placement="auto"
        target="priceBrakeDownPopover"
        trigger="hover"
      >
        <PopoverHeader>Price breakdown</PopoverHeader>
        <PopoverBody style={popoverBodyStyle}>
          <ListGroup>
            {groupedPricesBrakeDown.map(({ label, price }, index) => (
              <ListGroupItem key={index}>
                <b>{label}</b>: {price}(£)
              </ListGroupItem>
            ))}
          </ListGroup>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
}
