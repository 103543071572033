import moment from "moment";
import { isEmpty } from "lodash";

export const required = (value) => {
  if (Array.isArray(value)) {
    return !isEmpty(value) ? undefined : "Required";
  }
  return value ? undefined : "Required";
};
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const isEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return !re.test(email);
};

export const isSecondaryEmail = (email) => {
  if (!email) return false;

  const re = /\S+@\S+\.\S+/;
  return !re.test(email);
};

export const isValidPassword = (pass) => {
  const re = /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z]{6,}$/;
  return !re.test(pass);
};

export const hasRequiredLength = (value, l, separator = "") => {
  return value.replace(separator, "").length < l;
};

export const prepareExpData = (card) => {
  return `${card.expMonth < 10 ? `0${card.expMonth}` : card.expMonth}/${String(
    card.expYear
  ).substr(2)}`;
};

export const isValidDate = (dateStr) => {
  const [day, month, year] = dateStr.split("/");
  const dateString = `${year}-${month}-${day}`;
  const date = moment(new Date(dateString));

  return !date.isValid();
};

export const isValidDateAmPm = (meridiam, value = "", date) => {
  if (value && value.replace(/:_/, "").length < 4) return true;
  //check if 15mins internal
  const [, min] = value.split(":");
  const minsNumber = Number(min);
  const allowedTimeSpans = [0, 15, 30, 45];
  
  if (value && !allowedTimeSpans.some((i) => i === minsNumber)) {
    return true;
  }
  if (!date || date.isoWeekday() === 7) return false;
  let afterTimeAmValue, afterTimePmValue, beforeTimeAmValue, beforeTimePmValue;

  if (date.isoWeekday() === 6) {
    afterTimeAmValue = "09:00";
    afterTimePmValue = "00:00";
    beforeTimeAmValue = "11:59";
    beforeTimePmValue = "03:45";
  } else {
    afterTimeAmValue = "10:00";
    afterTimePmValue = "00:00";
    beforeTimeAmValue = "11:59";
    beforeTimePmValue = "04:50";
  }

  if (value && meridiam) {
    const parsedM = meridiam.substr(0, 1);
    const format = `hh:mm ${parsedM.toUpperCase()}`;
    const time = moment(value, format);
    const isAm = parsedM === "a";
    if (!isAm && value.startsWith("12") && value.replace(/:_/, "").length === 5)
      return false;
    const afterTime = isAm
      ? moment(afterTimeAmValue, `hh:mm A`)
      : moment(afterTimePmValue, `hh:mm P`);
    const beforeTime = isAm
      ? moment(beforeTimeAmValue, `hh:mm A`)
      : moment(beforeTimePmValue, `hh:mm P`);
    const isBetweenFlag =
      time.isBetween(afterTime, beforeTime) ||
      time.isSame(beforeTime) ||
      time.isSame(afterTime);

    return !isBetweenFlag;
  }
  return false;
};

export const formatSpeciality = (spec) => {
  const specMap = {
    OTHER: "Other",
    ORAL_SURGERY: "Oral Surgery",
    ENDODONTICS: "Endodontics",
    IMPLANTOLOGY: "Implantology",
    ORTHODONTICS: "Orthodontics",
    GENERAL: "General",
    PERIODONTICS: "Periodontics",
    MAXILLOFACIAL: "Maxillofacial",
    ENT: "ENT",
  };
  return specMap[spec];
};
