export const scansStorageUri = process.env.REACT_APP_SCANS_STORAGE_URI;
export const receiptStorageUri = process.env.REACT_APP_RECEIPT_STORAGE_URI;

export const auditReportStorageUri = "/audit/download";

export const dateFormat = "DD/MM/YYYY HH:mm";
export const dateFormatShort = "DD/MM/YYYY";
export const backendDateFormat = "YYYY-MM-DD";
export const dateFormatReports = "DD.MM.YYYY";
export const presentationDateFormat = "dddd, MMM D ⋅ h:mm";
export const presentationDateFormatAm = "dddd, MMM D ⋅ h:mm a";
export const presentationDateFormatTable = "DD/MM/YYYY h:mm a";
export const bookingCreationFormat = "YYYY-MM-DDTHH:mm:ss";

export const PAYMENT_CONSTANTS = Object.freeze({
  PAID: "PAID",
  PAID_BY_PATIENT: "PAID_BY_PATIENT",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  PAID_BY_DENTIST: "PAID_BY_DENTIST",
});

const SUPPORTED_PAYMENTS = [
  {
    name: "Mastercard",
    src: require(`../assets/img/mastercardDark.png`),
  },
  {
    name: "Visa",
    src: require(`../assets/img/visaDark.png`),
  },
  {
    name: "Visa Electron",
    src: require(`../assets/img/visaElectronDark.png`),
  },
  {
    name: "American Express",
    src: require(`../assets/img/americanExpressDark.png`),
  },
  {
    name: "Diners Club",
    src: require(`../assets/img/dinersClub.png`),
  },
  {
    name: "JCB",
    src: require(`../assets/img/jcb.png`),
  },
];

SUPPORTED_PAYMENTS.forEach(Object.freeze);
export { SUPPORTED_PAYMENTS };

export const recheckTimer = 60000 * 5; // 5 mins
export const slotDuration = "15"; // mins
export const fullRadiologyReportNoOption = { value: 1907, label: "No" };

export const PAYMENT_OPTIONS = [
  { label: "To be paid by patient", value: 0 },
  { label: "To be paid by credit card", value: 1 },
  { label: "Invoice me monthly", value: 2 },
];

export const getMonths = () => {
  let months = [
    { label: "All months", value: -1 },
    { label: "January", value: 0 },
    { label: "February", value: 1 },
    { label: "March", value: 2 },
    { label: "April", value: 3 },
    { label: "May", value: 4 },
    { label: "June", value: 5 },
    { label: "July", value: 6 },
    { label: "August", value: 7 },
    { label: "September", value: 8 },
    { label: "October", value: 9 },
    { label: "November", value: 10 },
    { label: "December", value: 11 },
  ];
  const currentMonth = new Date().getMonth();

  months = months.map((month) => {
    return month.value >= currentMonth ? { ...month, isDisabled: true } : month;
  });

  return months;
};

export const getYears = (invoicesYears) => {
  const years = [{ label: "All year", value: -1 }];

  invoicesYears.forEach((year) => {
    years.push({ label: year, value: year });
  });

  return years;
};

export const VOUCHER_QUANTITIES = [
  { label: "1", value: 1 },
  { label: "10", value: 10 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
];

export const VOUCHER_AMOUNTS = [
  { label: "50£", value: 50 },
  { label: "100£", value: 100 },
];

export const VOUCHER_STATUSES = [
  { label: "Not Applied", value: 0 },
  { label: "Applied", value: 1 },
  { label: "Expired", value: 2 },
];

export const COVID19_SURVEY_RESULT = [
  { label: "Not filled out", value: 0 },
  { label: "Submitted successfully", value: 1 },
  {
    label:
      "You might have COVID-19, please, contact with your dentist and change your appointment time",
    value: 2,
  },
];

export const LOAD_STATUS = {
  REQUEST: "REQUEST",
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
}