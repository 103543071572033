import React, { Fragment, useState } from "react";
import { Col } from "reactstrap";
import { DateRangePicker } from "react-dates";
import Select from "react-select";
import Moment from "moment";
import { handleRadiologistReportDownload } from "../../../../services/api";
import {
  backendDateFormat,
  dateFormatShort
} from "../../../../constants/global.constants";

export default function DentistFilter({
  radiologistReportFilters,
  updateFilters,
  dentists
}) {
  const [focused, setFocused] = useState(null);
  const [dates, setDates] = useState({
    startDate: Moment(radiologistReportFilters.startDate),
    endDate: Moment(radiologistReportFilters.endDate)
  });

  const handleFocusChange = focused => {
    setFocused(focused);
  };

  const onDatesChange = ({ startDate, endDate }) => {
    if (startDate && endDate && endDate.isAfter(startDate)) {
      updateFilters({
        startDate: Moment(startDate)
          .startOf("day")
          .format(backendDateFormat),
        endDate: Moment(endDate)
          .endOf("day")
          .format(backendDateFormat)
      });
    }
    setDates({ startDate, endDate });
  };

  return (
    <Fragment>
      <Col sm="2">
        <Select
          options={dentists}
          onChange={r =>
            updateFilters({
              radiologistIds: r ? r.reduce((acc, i) => [...acc, i.id], []) : []
            })
          }
        />
      </Col>
      <Col sm={{ size: 3 }}>
        <DateRangePicker
          onFocusChange={handleFocusChange}
          onDatesChange={onDatesChange}
          startDateId={"startDate"}
          endDateId={"endDate"}
          keepOpenOnDateSelect={true}
          firstDayOfWeek={1}
          reopenPickerOnClearDates={false}
          displayFormat={() => dateFormatShort}
          focusedInput={focused}
          small={true}
          hideKeyboardShortcutsPanel
          isOutsideRange={date => date.isAfter(Moment())}
          startDate={dates.startDate}
          endDate={dates.endDate}
        />
      </Col>
      <Col className="text-right">
        <a
          className="btn btn-primary"
          href=""
          target="_blank"
          rel="noopener noreferrer"
          onClick={e =>
            handleRadiologistReportDownload(e, radiologistReportFilters)
          }
        >
          <span>Download</span>
        </a>
      </Col>
    </Fragment>
  );
}
