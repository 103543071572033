import React, { Fragment } from "react";
import { Col } from "reactstrap";
import Select from "react-select";

export default function DentistFilterWithName({
  updateFilters,
  dentistId,
  dentists,
  onClick = () => { }
}) {
  return (
    <Fragment>
      <Col sm="3" style={{ zIndex: 10 }}>
        <Select
          value={dentists.filter(dentist => dentist.id === dentistId)}
          options={dentists}
          onChange={(r) => {
            updateFilters({
              dentistId: r.id
            })
          }
          }
        />
      </Col>
      <Col className="text-right" sm="9">
        <button
          className="btn btn-primary mr-5"
          style={{ width: 100 }}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <span>Save</span>
        </button>
      </Col>
    </Fragment>
  );
}
