import { combineReducers } from "redux";
import { auditReportListReducer } from "./reducers/audit-reports-list";
import { radiologistReportListReducer } from "./reducers/radiologist-reports-list";
import { patientsSatisfactionReportsListReducer } from './reducers/patients-satisfaction-reports-list'
import { auditRegistrationListReducer } from './reducers/audit-registration-report-list'

export default combineReducers({
    auditReportListReducer,
    radiologistReportListReducer,
    patientsSatisfactionReportsListReducer,
    auditRegistrationListReducer
});
