import React, { Component } from "react";
import {
	INITIAL_FORM_STATE,
	GENDERS,
	TITLES,
} from "../../../../constants/patient.constants";
import { Form, Field } from "react-final-form";
import classNames from "classnames";
import moment from "moment";
import axios from "axios";

import {
	required,
	composeValidators,
	isEmail,
	hasRequiredLength,
	isValidDate,
} from "../../../profile/helpers";
import DobMaskAdapter from "../../../../common/masks/DobMaskAdapter";
import MobileMaskAdapter from "../../../../common/masks/MobileMaskAdapter";
import { dateFormatShort } from "../../../../constants/global.constants";
import { prepareAddressesInfo } from "../../constants";
import "./index.css";

const client = axios.create({
	baseURL: process.env.REACT_APP_POSTCODE_API_URL,
});

// Also edits patient rename after done to CreateEditPatient
class CreateEditPatient extends Component {
	constructor(props) {
		super(props);
		this.handlePatientCreation = this.handlePatientCreation.bind(this);
		this.state = {
			searchedAddress: null,
			addresses: [],
			...props.patient,
			dob:
				props.patient && props.patient.dob
					? moment(props.patient.dob).format(dateFormatShort)
					: null,
		};
	}

	async handlePatientCreation(values) {
		const [day, month, year] = values.dob.split("/");
		const dateString = `${year}-${month}-${day}`;
		const patientObj = {
			...values,
			dob: dateString,
		};
        try {
            if (this.props.mode === "edit") {
                await this.props.editPatient(patientObj);
            } else {
                const newPatientId = await this.props.createPatient(patientObj);
                this.props.onPatientCreated(newPatientId);
            }
            this.props.closeCreatePatient();
        } catch (e) {
            console.error(e);
        }
	}

	searchAddressByPostCode = (searchedAddress, values) => {
		client
			.get(
				`v1/autocomplete/addresses?api_key=${process.env.REACT_APP_POSTCODE_API_KEY}&query=${searchedAddress}`
			)
			.then((response) => {
				const addresses = response.data.result.hits;
				if (Array.isArray(addresses) && addresses.length >= 0) {
					this.setState({
						...values,
						addresses,
					});
				}
			});
	};

	getAddressByUdprn = (url, values) => {
		client
			.get(
				`${url}?api_key=${process.env.REACT_APP_POSTCODE_API_KEY}`
			)
			.then((response) => {
				if (response.data && response.data.result) {
					const { fullAddress, postcode, city } = prepareAddressesInfo(response.data.result);
					this.setState({
						...values,
						addresses: [],
						city: city,
						postCode: postcode,
						address: fullAddress,
					});
				}
			});
	};

	renderSuggestionList = (values) => {
		return (
			<ul className="suggestions">
				{this.state.addresses.length > 0 &&
					this.state.addresses.map((address) => {
						return (
							<li
								key={address.udprn}
								onClick={() => this.getAddressByUdprn(address.urls.udprn, values)}
							>
								{address.suggestion}
							</li>
						);
					})}
			</ul>
		);
	};

	render() {
		const capitalize = (val) =>
			val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();

		return (
			<div
				className="ap-side-modal mod-high-level"
				id="createNewPatient"
				style={{ display: "block" }}
			>
				<div className="ap-side-modal-backdrop" />
				<div className="ap-side-modal-content mod-xs">
					<h3>{this.props.mode === "edit" ? "Edit Patient" : "New patient"}</h3>
					<Form
						onSubmit={this.handlePatientCreation}
						initialValues={
							{
								...this.state
							} || INITIAL_FORM_STATE
						}
						render={({ handleSubmit, invalid, values }) => (
							<form className="pt-4" onSubmit={handleSubmit}>
								<Field
									name="gender"
									validate={required}
									render={({ input }) => (
										<div className="form-group">
											<label htmlFor="new-patient-gender">Gender</label>
											<select
												{...input}
												className="form-control"
												id="new-patient-gender"
											>
												<option disabled hidden value="">
													Please Choose...
												</option>
												{GENDERS.map((gender) => (
													<option key={gender} value={gender}>
														{capitalize(gender)}
													</option>
												))}
												;
											</select>
										</div>
									)}
								/>
								<Field
									name="title"
									validate={required}
									render={({ input }) => (
										<div className="form-group">
											<label htmlFor="new-patient-title">Title</label>
											<select
												{...input}
												className="form-control"
												id="new-patient-title"
											>
												<option disabled hidden value="">
													Please Choose...
												</option>
												{TITLES.map((title) => (
													<option key={title} value={title}>
														{capitalize(title)}
													</option>
												))}
												;
											</select>
										</div>
									)}
								/>

								<Field
									name="firstName"
									validate={required}
									render={({ input, meta }) => (
										<div className="form-group">
											<label htmlFor="new-patient-forename">Forename</label>
											<input
												type="text"
												{...input}
												className={classNames("form-control", {
													"is-invalid": meta.touched && meta.error,
												})}
												id="new-patient-forename"
												placeholder="Forename"
											/>
										</div>
									)}
								/>

								<Field
									name="lastName"
									validate={required}
									render={({ input, meta }) => (
										<div className="form-group">
											<label htmlFor="new-patient-surname">Surname</label>
											<input
												type="text"
												{...input}
												className={classNames("form-control", {
													"is-invalid": meta.touched && meta.error,
												})}
												id="new-patient-surname"
												placeholder="Surname"
											/>
										</div>
									)}
								/>

								<Field
									type="text"
									className="form-control"
									placeholder="12/01/1990"
									validate={composeValidators(required, isValidDate)}
									name="dob"
									component={DobMaskAdapter}
								/>

								{/* <div className="row mx-0">
                  <label htmlFor="searched-by-address"><b>Search address</b></label>
                  <input
                    type="text"
                    id="searched-by-address"
                    name="searchedByAddress"
                    className="form-control"
                    placeholder="Search address"
                    aria-label="Address"
                    aria-describedby="basic-addon2"
                    autoComplete="on"
                    value={this.state.searhcedAddress}
                    onChange={(e) =>
                      this.searchAddressByPostCode(e.target.value, values)
                    }
                  />
                </div>
                <div className="suggestions-row row mx-0">{this.renderSuggestionList(values)}</div>

                <Field
                  name="city"
                  validate={required}
                  render={({ input, meta }) => (
                    <div className="form-group mt-3">
                      <label htmlFor="new-patient-city">City</label>
                      <input
                        type="text"
                        {...input}
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error,
                        })}
                        id="new-patient-city"
                        placeholder="City"
                      />
                    </div>
                  )}
                />

                <Field
                  name="postCode"
                  validate={required}
                  render={({ input, meta }) => (
                    <div className="form-group">
                      <label htmlFor="new-patient-postcode">Postcode</label>
                      <input
                        type="text"
                        {...input}
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error,
                        })}
                        id="new-patient-postcode"
                        placeholder="Postcode"
                      />
                    </div>
                  )}
                />

                <Field
                  name="address"
                  validate={required}
                  render={({ input, meta }) => (
                    <div className="form-group">
                      <label htmlFor="new-patient-address">Address line</label>
                      <input
                        type="text"
                        {...input}
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error,
                        })}
                        id="new-patient-address"
                        placeholder="Address line"
                      />
                    </div>
                  )}
                /> */}

								<Field
									name="mobile"
									component={MobileMaskAdapter}
									validate={composeValidators(required, (val) =>
										hasRequiredLength(val.split("_").join("").trim(), 12, "_")
									)}
								>
									{" "}
									<small className="text-muted">
										SMS reminders will be sent to this number.
									</small>
								</Field>

								<Field
									name="email"
									validate={composeValidators(required, isEmail)}
									render={({ input, meta }) => (
										<div className="form-group">
											<label htmlFor="new-patient-forename">
												Email address
											</label>
											<input
												type="email"
												{...input}
												className={classNames("form-control", {
													"is-invalid": meta.touched && meta.error,
												})}
												id="new-patient-email"
												placeholder="Email address"
											/>
										</div>
									)}
								/>

								<div className="row">
									<div className="col-6">
										<button
											className="btn btn-secondary btn-block"
											onClick={() => {
												this.props.closeCreatePatient();
											}}
										>
											Discard
										</button>
									</div>
									<div className="col-6">
										<button
											className="btn btn-primary btn-block"
											disabled={invalid}
										>
											{this.props.mode === "edit" ? "Edit" : "Create"}
										</button>
									</div>
								</div>
							</form>
						)}
					/>
				</div>
			</div>
		);
	}
}

export default CreateEditPatient;
