import axios from "axios";
import { get } from "lodash";
import { ImpersonatingService } from "./services/impersonating.service";
const client = axios.create({
  baseURL:  '/api',
});

client.interceptors.request.use(config => {
  let { token, originalToken } = ImpersonatingService();
  token = token || originalToken;
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

client.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    const message = get(error, "response.data.apierror.message", null);
    return Promise.reject(message || "Something went wrong");
  }
);

export default client;
