import React from "react";
import { Redirect, Route } from "react-router-dom";
import { NAVIGATION_CONSTANTS } from "../constants/navigation.constants";
import { ImpersonatingService } from "../services/impersonating.service";
import Dashboard from "../screens/Dashboard";
import VisitsContainer from "../screens/Visits";
import {
	isUserDentist,
	isUserRadiologist,
} from "../services/permission.service";
export const PrivateRoute = ({ component: Component, ...rest }) => {
	const { token, originalToken, isImpersonating } = ImpersonatingService();

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isImpersonating) {
					// ToDo: 'fix it there is infinity loading if we use redirect instead of component'
					const currentUser = JSON.parse(isImpersonating);
					if (isUserRadiologist(currentUser.role)) {
						return <VisitsContainer {...props} />;
					}

					return isUserDentist(currentUser.role) &&
						(props.location.pathname ===
							NAVIGATION_CONSTANTS.RADIOLOGY_REPORT ||
							props.location.pathname === NAVIGATION_CONSTANTS.PROFILE) ? (
						<Component {...props} />
					) : (
						<Dashboard {...props} />
					);
				}
				return token || originalToken ? (
					<Component {...props} />
				) : (
					<Redirect to={NAVIGATION_CONSTANTS.LOGIN} />
				);
			}}
		/>
	);
};
