import { createSelector } from "reselect";
import { orderBy } from "lodash";

const bookingsSelector = ({ bookings }) => bookings;

export const getBookings = createSelector(
	bookingsSelector,
	({ bookingsReducer }) => {
		return bookingsReducer.bookings;
	}
);

export const getBookingInfo = createSelector(
	bookingsSelector,
	({ bookingsReducer }) => {
		return bookingsReducer.bookingInfo;
	}
);

export const getBookingsWithoutDate = createSelector(
	bookingsSelector,
	({ bookingsReducer }) => {
		return orderBy(bookingsReducer.bookingsWithoutDate, ["id"], ["desc"]);
	}
);

export const getSelectedPaymentType = createSelector(
	bookingsSelector,
	({ bookingsReducer }) => {
		return bookingsReducer.selectedPaymentType;
	}
);

export const getScanPrices = createSelector(
	bookingsSelector,
	({ pricesReducer }) => {
		return pricesReducer.scanPrices;
	}
);

export const getScanPricesStatic = createSelector(
	bookingsSelector,
	({ pricesReducer }) => {
		return pricesReducer.scanPricesStatic;
	}
);

export const getScanPricesForPayment = createSelector(
	bookingsSelector,
	({ pricesReducer }) => {
		return pricesReducer.scanPricesForPayment;
	}
);

export const getOccupiedSlotsAll = createSelector(
	bookingsSelector,
	({ occupiedSlotsReducer }) => {
		return occupiedSlotsReducer.occupiedSlots;
	}
);

export const getOccupiedSlotsTimeRange = createSelector(
	bookingsSelector,
	({ occupiedSlotsReducer }) => {
		return occupiedSlotsReducer.occupiedSlotsDateRange;
	}
);

export const getTemporallyOccupiedSlots = createSelector(
	bookingsSelector,
	({ occupiedSlotsReducer }) => {
		return occupiedSlotsReducer.temporallyOccupiedSlots;
	}
);

//NEW
export const getScanPricesStaticSaving = createSelector(
	bookingsSelector,
	({ pricesReducer }) => {
		return pricesReducer.scanPricesStaticSaving;
	}
);

//NEW END

export const getBlockedDatesList = createSelector(
	bookingsSelector,
	({ occupiedSlotsReducer }) => {
		return occupiedSlotsReducer.blockedSlotsDateRange;
	}
);