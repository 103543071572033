import React from "react";
import { Alert } from "reactstrap";

export default function RequestReport({ requestReport }) {
  return (
    <Alert color="info" className="text-center mt-4">
      Currently there is no report, click{" "}
      <span
        className="link-span"
        onClick={() => {
          requestReport();
        }}
      >
        <b> here</b>
      </span>{" "}
      to request it
    </Alert>
  );
}
