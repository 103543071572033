import React from "react";
import { Input, Button, UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";

import "./styles.scss";

export default function ApplyVoucherForm({
  showVoucherForm,
  loading,
  voucherInfo,
  voucherCode,
  toggleShowVoucherForm,
  handleApplyVoucher,
  handleInputChange,
}) {
  return showVoucherForm ? (
    <div role="form" className="d-flex voucher-form">
      {voucherInfo && !loading && (
        <React.Fragment>
          <UncontrolledTooltip
            placement="top"
            target="voucherStatus"
            className={classNames("voucher-tooltip", {
              "voucher-tooltip--error": !voucherInfo.isValid,
              "voucher-tooltip--success": voucherInfo.isValid,
            })}
          >
            {voucherInfo.isValid
              ? "Voucher code is valid."
              : "The voucher code is not valid."}
          </UncontrolledTooltip>

          <i
            id="voucherStatus"
            className={classNames("voucher-status-icon", {
              "icon-check": voucherInfo.isValid,
              "text-success": voucherInfo.isValid,
              "icon-close": !voucherInfo.isValid,
              "text-danger": !voucherInfo.isValid,
            })}
          ></i>
        </React.Fragment>
      )}

      <Input
        type="number"
        className="mr-2"
        placeholder="Add voucher code"
        value={voucherCode}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            handleApplyVoucher();
          }
        }}
        onChange={handleInputChange}
      />
      <Button
        color="primary"
        onClick={handleApplyVoucher}
        disabled={!voucherCode || loading}
      >
        {loading ? "Checking..." : "Apply"}
      </Button>
    </div>
  ) : (
    <Button color="link" onClick={toggleShowVoucherForm}>
      +Apply voucher code
    </Button>
  );
}
