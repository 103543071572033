import React from "react";

export const Login = React.lazy(() => import("./screens/Login"));
export const Register = React.lazy(() => import("./screens/Register"));
export const Dashboard = React.lazy(() => import("./screens/Dashboard"));
export const VisitsContainer = React.lazy(() => import("./screens/Visits"));
export const InvoicesContainer = React.lazy(() => import("./screens/Invoices"));
export const VoucherContainer = React.lazy(() => import("./screens/Voucher"));
export const Radiographers = React.lazy(() => import("./screens/Radiographers"));
export const Radiologists = React.lazy(() => import("./screens/Radiologists"));
export const Dentists = React.lazy(() => import("./screens/Dentists"));
export const Schedules = React.lazy(() => import("./screens/Schedules"));
export const PriceList = React.lazy(() => import("./screens/PriceList"));
export const ReceiptDetails = React.lazy(() => import("./screens/ReceiptDetails"));

export const VisitsMobileContainer = React.lazy(() =>
  import("./screens/VisitsMobile")
);

export const Profile = React.lazy(() => import("./screens/Profile"));
export const CreditCardDetails = React.lazy(() =>
  import("./screens/CreditCardDetails")
);
export const ChangePasswordContainer = React.lazy(() =>
  import("./screens/ChangePassword")
);
export const JoinedAccountsContainer = React.lazy(() =>
  import("./screens/JoinedAccounts")
);

export const ResetPasswordContainer = React.lazy(() =>
  import("./screens/ResetPassword")
);

export const ForgotPasswordContainer = React.lazy(() =>
  import("./screens/ForgotPassword")
);

export const ForgotUsernameContainer = React.lazy(() =>
  import("./screens/ForgotUsername")
);

export const ActivateUserContainer = React.lazy(() =>
  import("./screens/ActivateUser")
);

export const ServiceLevelAgreementDentistContainer = React.lazy(() =>
  import("./screens/ServiceLevelAgreement/Dentist")
);

export const ServiceLevelAgreementReceptionistContainer = React.lazy(() =>
  import("./screens/ServiceLevelAgreement/Receptionist")
);

export const ServiceLevelAgreementDentistDetailsContainer = React.lazy(() =>
  import("./screens/ServiceLevelAgreement/DentistDetails")
);

export const TermsAndConditionsContainer = React.lazy(() =>
  import("./screens/TermsAndConditions")
);

export const Wallet = React.lazy(() =>
  import("./screens/Wallet")
);

export const DataLevelAgreementContainer = React.lazy(() =>
  import("./screens/DataLevelAgreement")
);

export const AuditReportContainer = React.lazy(() =>
  import("./screens/AuditReport")
);

export const RadiologistReportContainer = React.lazy(() =>
  import("./screens/RadiologistReport")
);

export const PricingContainer = React.lazy(() => import("./screens/Pricing"));

export const PatientSatisfactionReportsContainer = React.lazy(() =>
  import("./screens/PatientSatisfactionReport")
);

export const PatientSatisfactionFormContainer = React.lazy(() =>
  import("./screens/PatientSatisfactionForm")
);

export const RegistrationAuditReportContainer = React.lazy(() =>
  import("./screens/RegistrationAuditReport")
);

export const Covid19SurveyContainer = React.lazy(() =>
  import("./screens/Covid19Survey")
);

export const RadiologyReportContainer = React.lazy(() =>
  import("./screens/RadiologyReport")
);

export const ReferralGuidelines = React.lazy(() =>
  import("./screens/ReferralGuidelines")
);

export const RegistrationSurvey = React.lazy(() =>
  import("./screens/RegistrationSurvey")
);