import { SET_SCAN_PRICES, SET_SCAN_PRICES_STATIC, UPDATE_SCAN_PRICES_STATIC, SET_SCAN_PRICES_STATIC_SAVING } from "../action-types";
import { getScanOptions } from "../../../services/bookScan.service";

const initialState = {
  scanPrices: [],
  scanPricesStatic: [],
  scanPricesStaticSaving: false
};

export const pricesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCAN_PRICES: {
      return {
        ...state,
        scanPrices: getScanOptions(action.payload)
      };
    }
    case SET_SCAN_PRICES_STATIC: {
      return {
        ...state,
        scanPricesStatic: getScanOptions(action.payload)
      };
    }

    case UPDATE_SCAN_PRICES_STATIC:

      switch (action.payload.type) {
        case "cbctFormats":
          const { selectedCBCTFormatOptions } = state.scanPricesStatic;
          for (let index = 0; index < selectedCBCTFormatOptions.length; index++) {
            const element = selectedCBCTFormatOptions[index];
            if (element.id === action.payload.id) {
              element.price = action.payload.newValue;
              break;
            }

          }
          return {
            ...state,
            scanPricesStatic: {
              ...state.scanPricesStatic,
              ...selectedCBCTFormatOptions
            }

          }


        case "twoDImage":

          const { selected2DImagingOptions } = state.scanPricesStatic;
          for (let index = 0; index < selected2DImagingOptions.length; index++) {
            const element = selected2DImagingOptions[index];
            if (element.id === action.payload.id) {
              element.price = action.payload.newValue;
              break;
            }

          }
          return {
            ...state,
            scanPricesStatic: {
              ...state.scanPricesStatic,
              ...selected2DImagingOptions
            }

          }


        case "skull":

          const { selectedSkullOptions } = state.scanPricesStatic;
          for (let index = 0; index < selectedSkullOptions.length; index++) {
            const element = selectedSkullOptions[index];
            if (element.id === action.payload.id) {
              element.price = action.payload.newValue;
              break;
            }

          }
          return {
            ...state,
            scanPricesStatic: {
              ...state.scanPricesStatic,
              ...selectedSkullOptions
            }

          }


        case "extras":

          const { selectedExtraOptions } = state.scanPricesStatic;
          for (let index = 0; index < selectedExtraOptions.length; index++) {
            const element = selectedExtraOptions[index];
            if (element.id === action.payload.id) {
              element.price = action.payload.newValue;
              break;
            }

          }
          return {
            ...state,
            scanPricesStatic: {
              ...state.scanPricesStatic,
              ...selectedExtraOptions
            }

          }

        default: return state;
      }

    
    case SET_SCAN_PRICES_STATIC_SAVING:
        return {
          ...state,
          scanPricesStaticSaving: action.payload
        };
    

    default:
      return state;
  }
};
