import React from "react";
import { Button } from "reactstrap";
import { dateFormat } from "../../constants/global.constants";
import Moment from "moment";

export default function ReportModal({
  onClose,
  report: { reportContent, requestedReportDate }
}) {
  return (
    <div className="modal-dialog mobile-modal text-center" role="document">
      <div className="modal-content border-0">
        <div className="card border-primary mb-0">
          <div className="card-header bg-primary">
            Report was requested on{" "}
            {Moment(requestedReportDate).format(dateFormat)}
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body report-content">{reportContent}</div>
          <div className="card-footer">
            <Button color="secondary" size="lg" block onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
