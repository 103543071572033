import React from "react";
import Select from "react-select";

const ReactSelectAdapter = ({ input, ...rest }) => {
  return (
    <Select
      {...input}
      {...rest}
      onChange={(...args) => {
        args[0] = args[0] ? args[0] : [];
        input.onChange(...args);
      }}
    />
  );
};

export default ReactSelectAdapter;
