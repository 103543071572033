export const loadLocalStoreData = (
  name,
  defaultValue = {},
  Serialization = true
) => {
  try {
    const serializedData = localStorage.getItem(name);

    if (serializedData === null) {
      return defaultValue;
    }
    return Serialization ? JSON.parse(serializedData) : serializedData;
  } catch (error) {
    return defaultValue;
  }
};

export const saveLocalStoreData = (key, data, Serialization = true) => {
  try {
    const serializedData = Serialization ? JSON.stringify(data) : data;

    localStorage.setItem(key, serializedData);
  } catch (error) {
    console.log(error, "Privacy browser issue");
  }
};

