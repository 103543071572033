import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import promise from "redux-promise";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(
  initialState = {
    auth: {
      auth_token: localStorage.getItem("auth_token"),
    },
    // bookings: {
    //     bookingsWithoutDate: [],
    //     bookings: [],
    //     bookingInfo: null,
    //     bookingInfoDragged: null
    // },
    patients: {},
  }
) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancer(applyMiddleware(promise, thunk))
  );
}
