import { createSelector } from "reselect";

const radiologistsSelector = ({ radiologists }) => radiologists;

export const getRadiologists = createSelector(
  radiologistsSelector,
  ({ radiologistsListReducer }) =>
    radiologistsListReducer.data.reduce(
      (acc, cur) => [
        ...acc,
        { value: cur.id, label: `${cur.lastName} ${cur.firstName}`, id: cur.id }
      ],
      []
    )
);
