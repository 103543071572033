import { get } from "lodash";
import {
  selectedCBCTFormatOptionsV2,
  selectedXRayJustificationOptionsV2,
  selectedExtraOptionsV2,
  selectedTeethOptions,
  selected2DImagingOptionsV2,
  selectedSkullPartsOptionsV2
} from "../constants/priceItems.constants";

export const preparePrices = ({
  selected2DImagingOptions,
  selectedCBCTFormatOptions,
  selectedExtraOptions,
  selectedSkullOptions
}) => {
  return {
    cbctFormats: {
      dicomFiles: buildPrices(selectedCBCTFormatOptions, "cbct_f_dc_"),
    //   icanVision: buildPrices(selectedCBCTFormatOptions, "cbct_f_cv_"),
      romexisViewer: buildPrices(selectedCBCTFormatOptions, "cbct_f_rv_"),
      simplant: buildPrices(selectedCBCTFormatOptions, "cbct_f_sp_"),
      carestreamViewer: buildPrices(selectedCBCTFormatOptions, "cbct_f_cs_"),
    },
    twoDImage: {
      opg: buildPrices(selected2DImagingOptions, "2d_i_po_"),
      ceph: buildPrices(selected2DImagingOptions, "2d_i_dpc_"),
      digitalCeph: buildPrices(selected2DImagingOptions, "2d_i_dlc_"),
      cephReport: buildPrices(selected2DImagingOptions, "2d_i_ctr_")
    },    
    skull: {
      any: buildSkull(selectedSkullOptions),
      boneSpine: buildBoneSpine(selectedSkullOptions)
    },
    extras: {
      fullRadiologyReport: timeMapper(buildPrices(selectedExtraOptions, "e_frr_"), "72 hours", "Full radiology report"),
      sameDayProcessing: timeMapper(buildPrices(selectedExtraOptions, "e_epr_"), '24 hours', "Express processing for radiology report"),
      scans: buildPrices(selectedExtraOptions, "e_pacs"),
      extraCd: buildCd(selectedExtraOptions)
    }
  };
};

const timeMapper = (arr, time, matcher) => arr.map(i => {
    return i.humanReadablePrice === matcher ? ({
        ...i, 
        humanReadablePrice: `${i.humanReadablePrice} (${time})`
    }) : i
})

const buildCd = (prices = []) => {
  const cdObject = prices.find(price => price.name.startsWith("e_cd_copy"));
  return [cdObject, cdObject, cdObject];
};

const buildSkull = (prices = []) => {
    const cdObject = prices.find(price => price.name.startsWith("maxillofacial"));
    return [cdObject, cdObject, cdObject];
  };

  const buildBoneSpine = (prices = []) => {
    const cdObject = prices.find(price => price.name === "bone_spine");
    return [cdObject, cdObject, cdObject];
  }

const buildPrices = (prices = [], matcher) => {
  let holder = [];
  for (let price of prices) {
    if (price.name.startsWith(matcher)) {
      holder.push(price);
    }
    if (holder.length === 3) {
      break;
    }
  }
  return holder;
};

const optionBuilder = (acc, item, visitDetails) => {
  const foundDto = visitDetails.bookingDetailsDto.bookingItemDtos.find(dto =>
    dto.itemPriceDto.name.startsWith(item)
  );

  if (foundDto) {
    acc.push({
      label: foundDto.itemPriceDto.humanReadablePrice,
      value: foundDto.itemPriceDto.name,
      price: foundDto.itemPriceDto.price,
      priceSnapshot: foundDto.priceSnapshot
    });
  }

  return acc;
};

export const preparePricingData = (details, radiologists, totalAmount) => {
  const selectedCBCTFormat = selectedCBCTFormatOptionsV2.reduce(
    (acc, item) => optionBuilder(acc, item, details),
    []
  );
  const selectedXRayJustification = selectedXRayJustificationOptionsV2.reduce(
    (acc, item) => optionBuilder(acc, item, details),
    []
  );
  const selectedExtra = selectedExtraOptionsV2.reduce(
    (acc, item) => optionBuilder(acc, item, details),
    []
  );
  const selected2DImaging = selected2DImagingOptionsV2.reduce(
    (acc, item) => optionBuilder(acc, item, details),
    []
  );

  const selectedTeeth = details.bookingDetailsDto.bookingItemDtos.reduce(
    (acc, el) => {
      const mappedOption = selectedTeethOptions.find(option => {
        return option.htmlFor === el.itemPriceDto.name;
      });

      if (mappedOption) acc.push(mappedOption);
      return acc;
    },
    []
  );

  const selectedSkullParts = selectedSkullPartsOptionsV2.reduce(
    (acc, item) => optionBuilder(acc, item, details),
    []
  );

  const radiologist = get(
    details.bookingDetailsDto,
    "radiologist.radiologistName",
    ""
  );


const pricesBrakeDown = [
  ...selectedCBCTFormat,
  ...selectedExtra,
  ...selected2DImaging,
  ...selectedSkullParts
]

  const calculatedPrice =  totalAmount || Object.values(pricesBrakeDown).reduce((acc, cur) => acc+= cur.price, 0)
  const preparePriceBrakeDown = {
    calculatedPrice,
    pricesBrakeDown
  };

  return {
    selectedCBCTFormat,
    selectedXRayJustification,
    selectedExtra,
    selected2DImaging,
    selectedTeeth,
    radiologist,
    preparePriceBrakeDown,
    selectedSkullParts
  };
};
