import React, { Fragment, useRef, } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { noop } from 'lodash';
import { buildTable, rowEvents, buildOptions, addSort } from "../../../../../src/services/table.service";

import { withRouter } from "react-router";

const ReceptionistTable = ({ visits, onRowSelected, fetchVisits, visitCount, filters, setFilters, fetchReceiptDetails, history }) => {

  let table = useRef(null)
	const columns = buildTable("receptionist", { fetchReceiptDetails, history }).map(addSort(filters, fetchVisits, table?.current));

  const options =  buildOptions(fetchVisits, filters, visitCount, setFilters);

	return (
    <Fragment>
    <BootstrapTable
      bootstrap4
      bordered={false}
      keyField="visitId"
      data={visits}
      columns={columns}
      rowEvents={rowEvents(onRowSelected)}
      remote
      onTableChange={noop}
      pagination={paginationFactory(options)}
      ref={table}
    />
  </Fragment>)
}


export default withRouter(ReceptionistTable);