export const calculateEstimatedBookScanPrice = (
  formValues,
  selectedTeeth,
  selectOptions,
  isTwoD,
  isSkull,
  selectedSkullAreas
) => {
  return calculateActualPrice(
    selectOptions,
    formValues,
    decidePricingStrategy(selectedTeeth, isTwoD, isSkull, selectedSkullAreas)
  );
};

export const getScanOptions = (scans) => {
  const teeth = {
    ur: [],
    ul: [],
    ll: [],
    lr: [],
  };

  const selectedCBCTFormatOptions = [];
  const selected2DImagingOptions = [];
  const selectedExtraOptions = [];
  const selectedXRayJustificationOptions = [];
  const selectedSkullOptions = [];

  for (let scan of scans) {
    if (scan.name.startsWith("ur")) {
      teeth.ur.push(scan);
      continue;
    }
    if (scan.name.startsWith("ul")) {
      teeth.ul.push(scan);
      continue;
    }
    if (scan.name.startsWith("lr")) {
      teeth.lr.push(scan);
      continue;
    }
    if (scan.name.startsWith("ll")) {
      teeth.ll.push(scan);
      continue;
    }
    if (
      scan.name.startsWith("cbct_f_dc") ||
      scan.name.startsWith("cbct_f_rv") ||
      scan.name.startsWith("cbct_f_sp") ||
      scan.name.startsWith("cbct_f_cs")
    ) {
      selectedCBCTFormatOptions.push(scan);
      continue;
    }
    if (scan.name.startsWith("2d_i")) {
      selected2DImagingOptions.push(scan);
      continue;
    }
    if (scan.name.startsWith("e_")) {
      selectedExtraOptions.push(scan);
      continue;
    }
    if (scan.name.startsWith("x_ray")) {
      selectedXRayJustificationOptions.push(scan);
      continue;
    }
    if (scan.name === "maxillofacial" || scan.name.startsWith("bone")) {
      selectedSkullOptions.push(scan);
    }
  }

  teeth.ul = teeth.ul.reverse();
  teeth.ll = teeth.ll.reverse();

  return {
    teeth,
    selectedCBCTFormatOptions,
    selected2DImagingOptions,
    selectedExtraOptions,
    selectedXRayJustificationOptions,
    selectedSkullOptions,
  };
};

const decidePricingStrategy = (
  selectedTeeth = [],
  isTwoD,
  isSkull,
  selectedSkullAreas
) => {
  let upperLeftQuadrantSelected = isTwoD;
  let upperRightQuadrantSelected = isTwoD;
  let lowerLeftQuadrantSelected = isTwoD;
  let lowerRightQuadrantSelected = isTwoD;
  let topJawSelected = isTwoD;
  let lowerJawSelected = isTwoD;
  for (let tooth of Array.from(selectedTeeth)) {
    if (tooth.id.startsWith("ll")) {
      lowerLeftQuadrantSelected = tooth.id.startsWith("ll");
      continue;
    } else if (tooth.id.startsWith("lr")) {
      lowerRightQuadrantSelected = true;
      continue;
    } else if (tooth.id.startsWith("ul")) {
      upperLeftQuadrantSelected = true;
      continue;
    } else if (tooth.id.startsWith("ur")) {
      upperRightQuadrantSelected = true;
      continue;
    } else {
      break;
    }
  }

  topJawSelected = upperLeftQuadrantSelected && upperRightQuadrantSelected;
  lowerJawSelected = lowerLeftQuadrantSelected && lowerRightQuadrantSelected;
  return {
    topJawSelected,
    lowerJawSelected,
    upperLeftQuadrantSelected,
    upperRightQuadrantSelected,
    lowerLeftQuadrantSelected,
    lowerRightQuadrantSelected,
    isSkull,
    selectedSkullAreas,
  };
};

const calculateActualPrice = (
  {
    selectedCBCTFormatOptions,
    selected2DImagingOptions,
    selectedExtraOptions,
    selectedXRayJustificationOptions,
    selectedSkullOptions,
  },
  {
    selectedCBCTFormat,
    selected2DImaging,
    selectedFullRadiologyReport,
    selectedXRayJustification,
  },
  {
    topJawSelected,
    lowerJawSelected,
    upperLeftQuadrantSelected,
    upperRightQuadrantSelected,
    lowerLeftQuadrantSelected,
    lowerRightQuadrantSelected,
    isSkull,
    selectedSkullAreas,
  }
) => {
  //   No grouping done whatsoever hence such obscure parser
  //   Pease check the price table to see details of calculation
  //   Few options are possible
  //   2 jaws
  //   1 jaw 1 quadrant
  //   1 jaw
  //   2 quadrants which don't form jaw
  //   1 quadrant
  //   Depending on those prices are different for extra options and scan options
  // Now we also added skull option it always cost the same no matter how many section selected
  // use isSkull flag for details

  //   console.log(topJawSelected, "topJawSelected");
  //   console.log(lowerJawSelected, "lowerJawSelected");
  const quadrants = [
    upperLeftQuadrantSelected,
    upperRightQuadrantSelected,
    lowerLeftQuadrantSelected,
    lowerRightQuadrantSelected,
  ];

  const selectedQuadrants = quadrants.filter((i) => i);

  if (isSkull) {
    return allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selectedSkullOptions,
        selectedSkullAreas: Array.from(selectedSkullAreas),
        selectedExtraOptions,
        selectedCBCTFormat,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_q",
      true
    );
  }

  // console.log("calculateActualPrice => ", topJawSelected, lowerJawSelected, allPriceCollector(
  //   {
  //     selectedCBCTFormatOptions,
  //     selected2DImagingOptions,
  //     selectedExtraOptions,
  //     selectedCBCTFormat,
  //     selected2DImaging,
  //     selectedExtra,
  //     selectedXRayJustificationOptions,
  //     selectedXRayJustification
  //   },
  //   "_2j"
  // ))
  if (topJawSelected && lowerJawSelected) {
    // console.log("2 jaws selected");
    return allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedExtraOptions,
        selectedCBCTFormat,
        selected2DImaging,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_2j"
    );
  }
  if (selectedQuadrants.length === 3 && (topJawSelected || lowerJawSelected)) {
    // console.log("1 jaws selected and one quadrant");
    const jaw = allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedExtraOptions,
        selectedCBCTFormat,
        selected2DImaging,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_1j"
    );
    const oneQuadrant = allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedCBCTFormat,
        selected2DImaging,
      },
      "_q"
    );
    return [...jaw, ...oneQuadrant];
  }
  if (topJawSelected || lowerJawSelected) {
    // console.log("1 jaws selected");
    return allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedExtraOptions,
        selectedCBCTFormat,
        selected2DImaging,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_1j"
    );
  }
  if (selectedQuadrants.length === 2) {
    // console.log("2 quadrants selected by they don't form jaw");
    const data = allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedExtraOptions,
        selectedCBCTFormat,
        selected2DImaging,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_q"
    );
    const dataTwo = allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedCBCTFormat,
        selected2DImaging,
      },
      "_q"
    );
    return [...data, ...dataTwo];
  }
  if (selectedQuadrants.length === 1) {
    // console.log("only one quadrant selected");
    return allPriceCollector(
      {
        selectedCBCTFormatOptions,
        selected2DImagingOptions,
        selectedExtraOptions,
        selectedCBCTFormat,
        selected2DImaging,
        selectedFullRadiologyReport,
        selectedXRayJustificationOptions,
        selectedXRayJustification,
      },
      "_q"
    );
  }
  //   console.log("cant calculate");
};

const allPriceCollector = (
  {
    selectedCBCTFormatOptions = [],
    selected2DImagingOptions = [],
    selectedExtraOptions = [],
    selectedXRayJustificationOptions = [],
    selectedXRayJustification = [],
    selectedCBCTFormat = [],
    selected2DImaging = [],
    selectedFullRadiologyReport = {},
    selectedSkullOptions = [],
    selectedSkullAreas = [],
  },
  matcher,
  isSkull
) => {
  let collectedCBCTFormatOptions = [];
  let collected2DImagingOptions = [];
  let collectedFullRadiologyReportOptions = [];
  let collectedSelectedXRayJustification = [];
  let collectedSkullOptions = [];

  if (isSkull) {
    const names = selectedSkullAreas.map((i) => i.name);
    if (names && names.length) {
      if(names.length <= 1 && names.includes('bone_spine')) {
        collectedSkullOptions = selectedSkullOptions.filter(
          (i) => i.name === "bone_spine"
        );
      }
      else {
      collectedSkullOptions = selectedSkullOptions.filter(
        (i) => i.name === "maxillofacial"
      );
      collectedSkullOptions = collectSkullPrices(
        collectedSkullOptions,
        selectedSkullOptions,
        names
      );
    }
    }
  }

  collectedCBCTFormatOptions = collectPrices(
    collectedCBCTFormatOptions,
    selectedCBCTFormatOptions,
    selectedCBCTFormat,
    matcher
  );

  for (let format of selected2DImaging) {
    collected2DImagingOptions = collectPrices(
      collected2DImagingOptions,
      selected2DImagingOptions,
      format,
      matcher
    );
  }

  if (
    selectedFullRadiologyReport &&
    Object.keys(selectedFullRadiologyReport).length > 0 &&
    selectedFullRadiologyReport.label !== "No"
  ) {
    // collectedFullRadiologyReportOptions = [selectedFullRadiologyReport];
    collectedFullRadiologyReportOptions = collectFullRadiologyReportPrices(
      collectedFullRadiologyReportOptions,
      selectedExtraOptions,
      selectedFullRadiologyReport
    );
  }

  for (let format of selectedXRayJustification) {
    collectedSelectedXRayJustification = collectXrayPrices(
      collectedSelectedXRayJustification,
      selectedXRayJustificationOptions,
      format
    );
  }

  return [
    ...collectedFullRadiologyReportOptions,
    ...collectedSkullOptions,
    ...collected2DImagingOptions,
    ...collectedCBCTFormatOptions,
    ...collectedSelectedXRayJustification,
  ];
};

const collectFullRadiologyReportPrices = (arr, options, format) => [
  ...arr,
  options.filter((i) => i.id === format.value),
];

const collectPrices = (arr, options, format, matcher) => [
  ...arr,
  options.filter(
    (i) => i.humanReadablePrice === format.label && i.name.endsWith(matcher)
  ),
];

const collectXrayPrices = (arr, options, format) => [
  ...arr,
  options.filter(
    (i) => i.humanReadablePrice === format.label && i.name.startsWith("x_ray")
  ),
];

const collectSkullPrices = (arr, options, names) => [
  ...arr,
  ...options.filter((i) => names.includes(i.name)),
];

export const SCAN_TYPES = Object.freeze({
  CBCT: "CBCT",
  TWO_D: "TWO_D",
  SKULL: "SKULL",
});
