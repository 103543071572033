export const selectedCBCTFormatOptions = [
  { value: "cbct_f_rv", label: "Romexis Viewer (MAC OS and Windows)" },
  { value: "cbct_f_dc", label: "DICOM Files" },
  { value: "cbct_f_sp", label: "Morita" },
  { value: "cbct_f_cs", label: "Carestream Viewer" },
];

export const selectedCBCTFormatOptionsV2 = [
  "cbct_f_dc_",
  "cbct_f_cv_",
  "cbct_f_rv_",
  "cbct_f_sp_",
  "cbct_f_cs_"
];

export const selectedXRayJustificationOptionsV2 = [
  "x_ray_it",
  "x_ray_se",
  "x_ray_en",
  "x_ray_tm",
  "x_ray_im",
  "x_ray_dt",
  "x_ray_agea",
  "x_ray_aira",
  "x_ray_sav",
  "x_ray_pfa",
  "x_ray_mi",
  "x_ray_pg",
  "x_ray_rbc",
  "x_ray_dp",
  "x_ray_ds",
  "x_ray_co",
  "x_ray_df",
  "x_ray_pci",
  "x_ray_ft",
  "x_ray_os",
  "x_ray_tmja",
  "x_ray_pt",
  "x_ray_pth",
  "x_ray_ort",
  "x_ray_mal",
  "x_ray_def",
  "x_ray_dam",
];

export const selectedExtraOptionsV2 = [
  "e_epr_",
  "e_cd_copy",
  "e_frr_",
  "e_pacs_",
];

export const selected2DImagingOptionsV2 = [
  "2d_i_dlc",
  "2d_i_po",
  "2d_i_dpc",
  "2d_i_ctr",
];

export const selectedSkullPartsOptionsV2 = [
  "bone_sphenoid",
  "bone_temporal",
  "bone_nasal",
  "bone_maxilla",
  "bone_frontal",
  "bone_orbit",
  "bone_zygomatic",
  "bone_mandible",
  "bone_paranasal",
  "bone_airway",
  "maxillofacial",
  "bone_spine"
];

export const selected2DImagingOptions = [
  { value: "two_d_dl", label: "Digital Lateral CEPH" },
  { value: "two_d_dpa", label: "Digital PA CEPH" },
];

export const selectedXRayJustificationOptions = [
  { value: "cbct_x_ray_impl", label: "Implants" },
  { value: "cbct_x_ray_imt", label: "Impacted Teeth" },
  { value: "cbct_x_ray_end", label: "Endodontics" },
  { value: "cbct_x_ray_sin_ex", label: "Sinus Exam" },
  { value: "TMJ", label: "TMJ" },
];

export const selectedXRayJustificationOptionsTWO = [
  { value: "two_d_xray_imp", label: "Implants" },
  { value: "two_d_xray_imp_t", label: "Impacted Teeth" },
  { value: "two_d_xray_end", label: "Endodontics" },
  { value: "two_d_xray_sin_ex", label: "Sinus Exam" },
  { value: "two_d_xray_tmj", label: "TMJ" },
];

export const selectedExtraOptions = [
  { value: "cbct_extra_brr", label: "Radiology Report" },
  { value: "cbct_extra_srt", label: "Scan Radiographic Template" },
  { value: "cbct_extra_ep", label: "Same day processing (+£3,99)" },
  { value: "cbct_extra_cd_copy", label: "CD copy of the scan (+£4,99)" },
];

export const selectedExtraOptionsTWO = [
  { value: "two_d_extra_r_report", label: "Radiology Report" },
  { value: "two_d_extra_scant", label: "Scan Radiographic Template" },
  { value: "two_d_extra_same_day", label: "Same day processing (+£3,99)" },
  { value: "two_d_extra_cd_copy", label: "CD copy of the scan (+£4,99)" },
];

export const selectedTeethOptions = [
  { value: "cbct_ul_1", label: "1", htmlFor: "ul_1" },
  { value: "cbct_ul_2", label: "2", htmlFor: "ul_2" },
  { value: "cbct_ul_3", label: "3", htmlFor: "ul_3" },
  { value: "cbct_ul_4", label: "4", htmlFor: "ul_4" },
  { value: "cbct_ul_5", label: "5", htmlFor: "ul_5" },
  { value: "cbct_ul_6", label: "6", htmlFor: "ul_6" },
  { value: "cbct_ul_7", label: "7", htmlFor: "ul_7" },
  { value: "cbct_ul_8", label: "8", htmlFor: "ul_8" },

  { value: "cbct_ur_1", label: "1", htmlFor: "ur_1" },
  { value: "cbct_ur_2", label: "2", htmlFor: "ur_2" },
  { value: "cbct_ur_3", label: "3", htmlFor: "ur_3" },
  { value: "cbct_ur_4", label: "4", htmlFor: "ur_4" },
  { value: "cbct_ur_5", label: "5", htmlFor: "ur_5" },
  { value: "cbct_ur_6", label: "6", htmlFor: "ur_6" },
  { value: "cbct_ur_7", label: "7", htmlFor: "ur_7" },
  { value: "cbct_ur_8", label: "8", htmlFor: "ur_8" },

  { value: "cbct_ll_1", label: "1", htmlFor: "ll_1" },
  { value: "cbct_ll_2", label: "2", htmlFor: "ll_2" },
  { value: "cbct_ll_3", label: "3", htmlFor: "ll_3" },
  { value: "cbct_ll_4", label: "4", htmlFor: "ll_4" },
  { value: "cbct_ll_5", label: "5", htmlFor: "ll_5" },
  { value: "cbct_ll_6", label: "6", htmlFor: "ll_6" },
  { value: "cbct_ll_7", label: "7", htmlFor: "ll_7" },
  { value: "cbct_ll_8", label: "8", htmlFor: "ll_8" },

  { value: "cbct_lr_1", label: "1", htmlFor: "lr_1" },
  { value: "cbct_lr_2", label: "2", htmlFor: "lr_2" },
  { value: "cbct_lr_3", label: "3", htmlFor: "lr_3" },
  { value: "cbct_lr_4", label: "4", htmlFor: "lr_4" },
  { value: "cbct_lr_5", label: "5", htmlFor: "lr_5" },
  { value: "cbct_lr_6", label: "6", htmlFor: "lr_6" },
  { value: "cbct_lr_7", label: "7", htmlFor: "lr_7" },
  { value: "cbct_lr_8", label: "8", htmlFor: "lr_8" },
];
