import React, { Component } from "react";
import NoData from "../../../../common/NoData";
import { capitalize } from "lodash";
import { presentationDateFormatAm } from "../../../../constants/global.constants";
import Moment from 'moment';
class BookingsWithoutTime extends Component {
  state = {
    searchString: null,
  };

  buildTitle(event) {
    const { patient } = event;

    return `${capitalize(patient.title)} ${patient.name}`;
  }

  buildDoctorName(event) {
    const { dentist } = event;
    return `Dr. ${dentist.firstName} ${dentist.lastName}`;
  }

  selectionChange(event, type) {
    this.setState({ [type]: event.target.value });
  }

  render() {
    const { bookingsWithoutDate } = this.props;
    const { searchString } = this.state;

    let resultedArray = [...bookingsWithoutDate];

    if (searchString && searchString !== "") {
      const query = searchString.toLowerCase();
      resultedArray = resultedArray.filter((item) => {
        return (
          item.patient.name.toLowerCase().includes(query) ||
          item.dentist.lastName.toLowerCase().includes(query) ||
          item.dentist.firstName.toLowerCase().includes(query) ||
          item.patient.dob.replace(/-/g, "/").includes(query)
        );
      });
    }

    return (
      <aside className="aside-menu">
        <div className="p-3">
          <h4>Bookings without time:</h4>
          <form className="ap-clients-search">
            <div className="form-group input-group m-0">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="icon-magnifier" />
                </div>
              </div>
              <input
                type="text"
                className="form-control ap-table-sortable-search"
                placeholder="Search"
                onChange={(event) =>
                  this.selectionChange(event, "searchString")
                }
              />
            </div>
          </form>
        </div>
        <div
          className="list-group list-group-accent ap-clients-list"
          id="external-events"
        >
          <div className="list-group-item list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small bl-0 border-left-0 border-right-0">
            Drag to calendar to assign time
          </div>
          {resultedArray.length === 0 && (
            <NoData>No results which match the criteria</NoData>
          )}
          {resultedArray.map((event) => (
            <div
              className="ap-client-item list-group-item list-group-item-divider border-0"
              title={this.buildTitle(event)}
              doctor={this.buildDoctorName(event)}
              tel={event.patient.mobile}
              data={event.id}
              key={event.id}
              onClick={() => this.props.onShowDetails(event)}
              id={event.id}
            >
              <div style={{ cursor: "pointer" }}>
                <span className="ap-client-item__name">
                  {this.buildTitle(event)}
                </span>
              </div>
              <small className="text-muted mr-3">
                <i className="icon-user" />
                &nbsp;{" "}
                <span className="ap-client-item__doc">
                  {this.buildDoctorName(event)}
                </span>
              </small>
              <br />
              <small className="text-muted mr-3">
                <i className="icon-calendar" />
                &nbsp;{" "}
                <span className="ap-event__date">
                  {Moment(event.createdAt).format(presentationDateFormatAm)}
                </span>
              </small>
              <br />
              {event.patient.mobile && (
                <>
                  <small className="text-muted mr-3">
                    <i className="icon-phone" />
                    &nbsp;{" "}
                    <span className="ap-client-item__tel">
                      {event.patient.mobile}
                    </span>
                  </small>
                  <br />
                </>
              )}
              {event.comment && (
                <small className="text-muted">
                  <i className="icon-speech" />
                  &nbsp;{" "}
                  <span className="ap-client-item__desc">{event.comment}</span>
                </small>
              )}
            </div>
          ))}
        </div>
      </aside>
    );
  }
}

export default BookingsWithoutTime;
