import React from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";

const DobMaskAdapter = ({ input, meta, ...rest }) => {
  const mask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

  return (
    <div className="form-group">
      <label htmlFor="new-patient-dob">Date of birth</label>
      <div>
        <MaskedInput
          mask={mask}
          {...input}
          {...rest}
          className={classNames("form-control", {
            "is-invalid": meta.touched && meta.error
          })}
          onChange={event => input.onChange(event.target.value)}
        />
      </div>
    </div>
  );
};

export default DobMaskAdapter;
