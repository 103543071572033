import React, { Component, Fragment } from "react";
import Modal from "../../../../common/modals/Modal";

class CancelBookingModal extends Component {
  onDiscard = () => {
    const { id } = this.props.patientInfo;
    this.handleModalVisibilityToggle();
    //this.setState({ showBookingDetailsShort: false });
    this.props.deleteBooking(id);
  };

  handleModalVisibilityToggle(e) {
    e && e.preventDefault();
    this.props.closeCancelBooking();
  }

  render() {
    const { name, date } = this.props.patientInfo;

    return (
      <Modal
        onDiscard={() => this.onDiscard()}
        onClose={e => this.handleModalVisibilityToggle(e)}
        title="Cancel Booking?"
        confirmBtn="Confirm"
      >
        Are you sure you want to cancel a scan booking for:
        <br />
        <strong>{name}</strong>
        {date && (
          <Fragment>
            {" "}
            on <strong>{date}</strong>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default CancelBookingModal;
