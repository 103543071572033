import React from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";

const CreditCardInputAdapter = ({
  input,
  meta,
  mask = [/\d/, /\d/, /\d/, /\d/],
  ...rest
}) => {
  return (
    <MaskedInput
      mask={mask}
      {...input}
      {...rest}
      className={classNames("form-control", {
        "is-invalid": meta.touched && meta.error
      })}
      onBlur={event => input.onBlur(event)}
      onChange={event => input.onChange(event.target.value)}
    />
  );
};

export default CreditCardInputAdapter;
