import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.scss";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ThemedStyleSheet from "react-with-styles/lib/ThemedStyleSheet";
import aphroditeInterface from "react-with-styles-interface-aphrodite";
import { PrivateRoute } from "./common/PrivateRoute";
import { NAVIGATION_CONSTANTS } from "./constants/navigation.constants";
import { theme } from "./configs";
import { isMobile } from "./utils";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

// Pages
import * as routes from "./routes";
// import "./plugins/postdicom/init";

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme(theme);

const loading = () => (
	<div className="animated fadeIn pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse" />
	</div>
);

class App extends Component {
	history = createBrowserHistory();

	render() {
		return (
			<>
				<ToastContainer />
				<HashRouter history={this.history}>
					<React.Suspense fallback={loading()}>
						<Switch>
							<Route
								exact
								path="/"
								render={() => {
									return isMobile(
										navigator.userAgent || navigator.vendor || window.opera
									) ? (
										<Redirect to={NAVIGATION_CONSTANTS.VISITS_MOBILE} />
									) : (
										<Redirect to={NAVIGATION_CONSTANTS.DASHBOARD} />
									);
								}}
							/>
							<Route
								exact
								path="/activation"
								component={routes.ActivateUserContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.FORGOT_PASSWORD}
								component={routes.ForgotPasswordContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.FORGOT_USERNAME}
								component={routes.ForgotUsernameContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.AGREEMENT}
								component={routes.ServiceLevelAgreementDentistContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.AGREEMENT_DENTISTS_LIST}
								component={routes.ServiceLevelAgreementReceptionistContainer}
							/>
							<PrivateRoute
								exact
								path={`${NAVIGATION_CONSTANTS.AGREEMENT_DENTIST_DETAILS}/:dentistId`}
								component={routes.ServiceLevelAgreementDentistDetailsContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.TERMS}
								component={routes.TermsAndConditionsContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.WALLET}
								component={routes.Wallet}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.REFERRAL_GUIDELINES}
								component={routes.ReferralGuidelines}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.REGISTRATION_SURVEY}
								component={routes.RegistrationSurvey}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.DATA_PROCESSING}
								component={routes.DataLevelAgreementContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.PRICING}
								component={routes.PricingContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.RESET_PASSWORD}
								component={routes.ResetPasswordContainer}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.LOGIN}
								component={routes.Login}
							/>
							<Route
								exact
								path={NAVIGATION_CONSTANTS.REGISTER}
								component={routes.Register}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.DASHBOARD}
								component={routes.Dashboard}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.VISITS}
								component={routes.VisitsContainer}
							/>
             <PrivateRoute
								exact
								path={`${NAVIGATION_CONSTANTS.RECEIPT}/:visitId`}
								component={routes.ReceiptDetails}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.INVOICES}
								component={routes.InvoicesContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.VOUCHERS}
								component={routes.VoucherContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.RADIOGRAPHERS}
								component={routes.Radiographers}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.RADIOLOGISTS}
								component={routes.Radiologists}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.DENTISTS}
								component={routes.Dentists}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.PRICE_LIST}
								component={routes.PriceList}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.PROFILE}
								component={routes.Profile}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.CREDIT_CARD_DETAILS}
								component={routes.CreditCardDetails}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.CHANGE_PASSWORD}
								component={routes.ChangePasswordContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.JOINED_ACCOUNTS}
								component={routes.JoinedAccountsContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.AUDIT_REPORTS}
								component={routes.AuditReportContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.RADIOLOGIST_REPORTS}
								component={routes.RadiologistReportContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.VISITS_MOBILE}
								component={routes.VisitsMobileContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.PATIENT_SATISFACTION_REPORTS}
								component={routes.PatientSatisfactionReportsContainer}
							/>
							<Route
								exact
								path={`${NAVIGATION_CONSTANTS.PATIENT_SATISFACTION_FORM}`}
								component={routes.PatientSatisfactionFormContainer}
							/>
							<PrivateRoute
								exact
								path={`${NAVIGATION_CONSTANTS.PATIENT_SATISFACTION_FORM}/:token`}
								component={routes.PatientSatisfactionFormContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.AUDIT_REGISTRATION_REPORT}
								component={routes.RegistrationAuditReportContainer}
							/>
							<Route
								exact
								path={`${NAVIGATION_CONSTANTS.COVID19SURVEY}/:code`}
								component={routes.Covid19SurveyContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.RADIOLOGY_REPORT}
								component={routes.RadiologyReportContainer}
							/>
							<PrivateRoute
								exact
								path={NAVIGATION_CONSTANTS.SCHEDULES}
								component={routes.Schedules}
							/>
						</Switch>
					</React.Suspense>
				</HashRouter>
			</>
		);
	}
}

export default App;
