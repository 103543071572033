import axios from "../axios-client";
import { LOGIN_ACTION, LOGOUT_ACTION } from "./authActionTypes";
import { toastr } from "react-redux-toastr";
import { setUserProfile } from "../modules/profile/actions";
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localStorage.constants";
import { resolveAfterLoginRedirect } from "../services/navigation.service";
import { get } from "lodash";

export const loginAction = (email, password, history) => dispatch => {
  axios
    .post("/user/login", {
      email,
      password
    })
    .then(result => {
      const token = get(
        result.headers,
        "authorization",
        result.data.authHeader
      );
      
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN, token);
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.ORIGINAL_AUTH_TOKEN, token);
      dispatch(setUserProfile(result.data));
      return result;
    })
    .then(result => {
      dispatch({
        type: LOGIN_ACTION,
        payload: result.headers["authorization"]
      });
      resolveAfterLoginRedirect(history, result.data.role);
    })
    .catch(e => toastr.error("Error", e));
};

export const registerAction = ({ user, history }) => dispatch => {
  const { email, password } = user;
  axios
    .post("/user/sign-up", {
      ...user
    })
    .then(_ => {
      toastr.success(
        "Success",
        "Your account is now ready to use!"
      );
      dispatch(loginAction(email, password, history));
    })
    .catch(e => {
      toastr.error("Error", e);
    });
};

export const logoutAction = () => dispatch => {
  localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.ORIGINAL_AUTH_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.ACTING_AS);
  return dispatch({
    type: LOGOUT_ACTION
  });
};
