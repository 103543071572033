import React, { Component, Fragment } from "react";
import moment from "moment";
import "./styles.scss";
import _ from "lodash";
import VisitedLabel from "../VisitedLabel";
import VisitPayment from "../VisitPayment";
import Modal from "../../../../common/modals/Modal";
import { Form, Field } from "react-final-form";
import { Row, Col } from "reactstrap";
import { OnChange } from "react-final-form-listeners";
import SingleDatePickerAdapter from "../../../../common/masks/SingleDatePickerAdapter";
import TimeAdapter from "../../../../common/masks/TimeAdapter";
import { isValidDateAmPm } from "../../../../modules/profile/helpers";
import Countdown from "react-countdown-now";
import OccupiedSlots from "../../../dashboard/components/OccupiedSlots";
import {
	bookingCreationFormat,
	backendDateFormat,
	presentationDateFormat,
	dateFormatShort,
	slotDuration,
} from "../../../../constants/global.constants";
import { AiFillSave } from "react-icons/ai";
// import { createPostDICOMOrder } from "../../../../plugins/postdicom/util";

export default class PatientInfo extends Component {
	countDownTimer = Date.now() + 60000 * 30; // 30 m

	constructor(props) {
		super(props);
		const { visitDetails } = this.props;

		if (visitDetails.appointmentStartTime) {
			const dateObj = moment(this.props.visitDetails.appointmentStartTime);
			this.state = {
				date: dateObj,
				hoursMinutes: dateObj.format("hh:mm"),
				meridiem: dateObj.format("a"),
				showModal: false,
			};
		} else {
			this.state = {
				date: null,
				showModal: false,
			};
		}
	}

	shouldComponentUpdate = (nextProp) => {
		return (
			nextProp.visitDetails.appointmentStartTime !==
			this.props.visitDetails.appointmentStartTime ||
            nextProp.visitDetails.patient !==this.props.visitDetails.patient
		);
	};

	onDiscard = () => {
		this.props.onClose();
	};

	isBooking(type) {
		return type === "booking";
	}

	isEdit(type) {
		return type === "edit";
	}

	buildDentistHeader() {
	const { visitDetails, isUserDentist, type, onPatientEdit } = this.props;
    const patientName = _.startCase(_.toLower(`${visitDetails?.patient?.title}. ${visitDetails?.patient?.name}`));
		return (
			<div>
				<div className="row">
					<div className="col-4">
						<h3>
							{patientName}
						</h3>
					</div>
					<div className="col-4">
						<button
							className="btn btn-secondary"
							onClick={() => onPatientEdit(visitDetails.patient)}
						>
							Edit Patient
						</button>
					</div>
					{isUserDentist && this.isBooking(type) && (
						<div className="col-4 text-right">
							<VisitedLabel isVisited={visitDetails.visited} />
						</div>
					)}
				</div>
				<div className="row">
					<div className="col-8">
						<small>
							{moment(visitDetails.patient.dob).format(dateFormatShort)}
						</small>
					</div>
				</div>
			</div>
		);
	}

	buildReceptionistHeader() {
		const { visitDetails, isUserReceptionist, type, updateVisit, updateVisitUnvisited} = this.props;

    const patientName = _.startCase(_.toLower(`${visitDetails?.patientData?.title}. ${visitDetails?.patientData?.name}`))

		return (
			<div>
				<div className="row">
					<div className="col flex-grow-4">
						<h3>
							{patientName}
						</h3>
					</div>
					{!visitDetails.visited && isUserReceptionist && this.isBooking(type) && (
						<div className="col text-right flex-grow-2">
							<button
								className="btn btn-primary btn-lg"
								title="Cannot mark as visited future bookings"
								disabled={moment(visitDetails.appointmentEndTime).isAfter(
									moment().endOf("day")
								)}
								onClick={() => {
									visitDetails.paidByPatient
										? this.setState({ showModal: true })
										: updateVisit(visitDetails.id);
								}}
							>
								Mark as Visited
							</button>
						</div>
					)}
					{isUserReceptionist && visitDetails.visited && this.isBooking(type) && (
						<div className="col text-right flex-grow-2 d-flex align-items-center justify-content-end">
							<VisitedLabel isVisited={visitDetails.visited} />
						</div>
					)}
                    {visitDetails.visited && isUserReceptionist && this.isBooking(type) && (
                        <div className="col text-right flex-grow-2">
                            <button
                                className="btn btn-danger btn-block"
                                data-toggle="modal"
                                title="Cannot mark as unvisited future bookings"
                                onClick={() => {
                                    updateVisitUnvisited(visitDetails.id);
                                }}
                            >Return to unvisited
                            </button>
                        </div>
                    )}
				</div>
				<div className="row">
					<div className="col-8">
						<small>
							{moment(visitDetails.patientData.dob).format(dateFormatShort)}
						</small>
					</div>
				</div>

				{this.state.showModal && (
					<Modal
						onDiscard={() => updateVisit(visitDetails.id)}
						onClose={() => this.setState({ showModal: false })}
						confirmBtn="Mark as visited"
						title="Patient Payment"
					>
						By clicking <b>Mark as visited</b> <br />I confirm that patient
						payment was successful
					</Modal>
				)}
			</div>
		);
	}

	handleLockUpdate(value, old, { hoursMinutes, meridiem, selectedDate }) {
		if (value === old) return;

		this.setState((prevState) => {
			prevState.date = selectedDate;
		});

		const endDate = this.calculateEndTime(hoursMinutes, meridiem, selectedDate);
		const appointmentStartTime = endDate
			? endDate.format(bookingCreationFormat)
			: endDate;
		const appointmentEndTime = endDate
			? endDate.add(slotDuration, "minutes").format(bookingCreationFormat)
			: endDate;

		if (
			appointmentStartTime &&
			appointmentEndTime &&
			!isValidDateAmPm(meridiem, hoursMinutes, selectedDate)
		) {
			this.props.updateTemporallyOccupiedSlots({
				appointmentStartTime,
				appointmentEndTime,
			}) &&
				this.props.fetchOccupiedSlotsByDateRange(
					moment(appointmentStartTime)
						.startOf("week")
						.format(backendDateFormat),
					moment(appointmentEndTime).endOf("week").format(backendDateFormat)
				);
		}
	}

	calculateEndTime(hoursMinutes, meridiem, selectedDate) {
		let selectedTime = {};
		let endDate = null;
		if (hoursMinutes && meridiem && selectedDate) {
			selectedTime = moment(hoursMinutes + " " + meridiem, ["h:mm A"]);
			endDate = selectedDate.set({
				hours: selectedTime.hours(),
				minutes: selectedTime.minutes(),
			});
			return endDate;
		}
		return null;
	}

	editBooking(values) {
		const { selectedDate, hoursMinutes, meridiem } = values;

		let endDate = this.calculateEndTime(hoursMinutes, meridiem, selectedDate);
		const { id } = this.props.visitDetails;
		const appointmentStartTime = endDate
			? endDate.format(bookingCreationFormat)
			: endDate;
		const appointmentEndTime = endDate
			? endDate
				.add(Number(slotDuration), "minutes")
				.format(bookingCreationFormat)
			: endDate;

		this.props.updateBooking({
			id,
			appointmentStartTime: moment(appointmentStartTime).format(
				bookingCreationFormat
			),
			appointmentEndTime: moment(appointmentEndTime).format(
				bookingCreationFormat
			),
		});
		this.props.onClose();
	}

	renderCountdown({ meridiem, selectedDate, hoursMinutes }, clearFn) {
		if (
			meridiem &&
			selectedDate &&
			hoursMinutes &&
			!selectedDate.isSame(this.props.visitDetails.appointmentStartTime)
		) {
			const {
				occupiedSlots = [],
				temporallyOccupiedSlots = [],
				occupiedSlotsTimeRange = [],
				userProfile = {},
				bookings = [],
			} = this.props;

      if(userProfile && !userProfile.id) {
        return null
      }

			const endDate = this.calculateEndTime(
				hoursMinutes,
				meridiem,
				selectedDate
			);

			const allSlots = [
				...occupiedSlots,
				...temporallyOccupiedSlots,
				...occupiedSlotsTimeRange,
				...bookings,
			].filter(
				(i) =>
					moment(i.appointmentStartTime).isSame(endDate, "day") &&
					i.dentistId !== userProfile.id
			);

			return (
				<Fragment>
					<Countdown
						date={this.countDownTimer}
						onComplete={clearFn}
						daysInHours
					/>{" "}
					<OccupiedSlots
						occupiedSlots={allSlots}
						userId={userProfile.id}
						endDate={endDate}
						notifyHasConflict={(flag) => {
							this.setState({ hasTimeConflict: flag });
						}}
					/>
				</Fragment>
			);
		}
		this.countDownTimer = Date.now() + 60000 * 30; // 30 mins
	}

	buildVisitDate() {
		const { meridiem, hoursMinutes, date } = this.state;
		return (
			<Form
				onSubmit={this.editBooking}
				initialValues={{
					selectedDate: date,
					hoursMinutes,
					meridiem: meridiem || "am",
				}}
				mutators={{
					clearDates: (args, state, utils) => {
						utils.changeValue(state, "selectedDate", () => null);
						utils.changeValue(state, "hoursMinutes", () => null);
					},
					clearHours: (args, state, utils) => {
						utils.changeValue(state, "hoursMinutes", () => null);
					},
				}}
				render={({ handleSubmit, values, form }) => (
					<form className="pt-1" onSubmit={(values) => handleSubmit(values)}>
						<Row className="col-sm-8 px-0 mx-0">
							<Col
								sm="3"
								className="override-date-picker-booking pr-1 px-0 mr-0"
							>
								<div className="input-group-prepend react-dates">
									<div className="input-group-text">
										<i className="icon-calendar"></i>
									</div>
								</div>
								<div className="single-calendar-container">
									<Field
										id="visit-date-input"
										name="selectedDate"
										component={SingleDatePickerAdapter}
									/>
									<OnChange name="selectedDate">
										{(value, previous) =>
											this.handleLockUpdate(value, previous, values)
										}
									</OnChange>
								</div>
							</Col>
							<Col sm="3" className="pr-1 px-0">
								<Field
									name="hoursMinutes"
									component={TimeAdapter}
									validate={(e, allValues) =>
										isValidDateAmPm(
											allValues.meridiem,
											e,
											allValues.selectedDate
										)
									}
								/>
								<OnChange name="hoursMinutes">
									{(value, previous) =>
										this.handleLockUpdate(value, previous, values)
									}
								</OnChange>
							</Col>
							<Col sm="2" className="pr-1 px-0">
								<Field
									name="meridiem"
									render={({ input }) => (
										<div className="form-group">
											<select
												{...input}
												className="form-control"
												id="reg-title"
											>
												{["am", "pm"].map((title) => (
													<option key={title} value={title}>
														{_.toUpper(title)}
													</option>
												))}
											</select>
										</div>
									)}
								/>
								<OnChange name="meridiem">
									{(value, previous) =>
										this.handleLockUpdate(value, previous, values)
									}
								</OnChange>
							</Col>
							<Col sm="1" className="pr-1 px-0">
								<button
									className="btn btn-link"
									style={{ padding: 0 }}
									onClick={() => this.editBooking(values)}
								>
									<AiFillSave size={30} color="black" />
								</button>
							</Col>
							<Col sm="3" className="mt-2 px-0">
								{this.renderCountdown(values, form.mutators.clearDates)}
							</Col>
						</Row>
					</form>
				)}
			/>
		);
	}

	render() {
		const {
			visitDetails,
			isUserRadiologist,
			isUserDentist,
			isUserReceptionist,
		} = this.props;
		return (
			<div>
				{isUserDentist && this.buildDentistHeader()}
				{!isUserDentist && this.buildReceptionistHeader()}
				<div className="ap-patient-details">
					<p>
						<i className="icon-phone" />{" "}
						<span>{visitDetails?.patient?.mobile}</span>
						<i className="icon-envelope ml-4" />{" "}
						<a href={`mailto:${visitDetails?.patient?.email}`}>
							{visitDetails?.patient?.email}
						</a>
					</p>
					{visitDetails?.patient?.address && (
						<p>
							<i className="icon-map" />{" "}
							<span>
								{visitDetails.patientData.city}, {visitDetails.patientData.address},{" "}
								{visitDetails.patientData.postCode}
							</span>
						</p>
					)}
					{visitDetails.appointmentStartTime &&
						!isUserDentist &&
						!isUserReceptionist && (
							<p>
								<i className="icon-calendar" />{" "}
								<span>
									{moment(visitDetails.appointmentStartTime).format(
										presentationDateFormat
									)}{" "}
									-{" "}
									{moment(visitDetails.appointmentStartTime)
										.add(Number(slotDuration), "minutes")
										.format("h:mm a")}
								</span>
							</p>
						)}
					{visitDetails.appointmentStartTime &&
						(isUserDentist || isUserReceptionist) &&
						this.buildVisitDate()}
					<p>
						<i className="icon-user" />{" "}
						<span>
							{"Dr. " +
								visitDetails.dentist.firstName +
								" " +
								visitDetails.dentist.lastName}
						</span>
					</p>
					{!!visitDetails.paidByPatient &&
						(isUserReceptionist || isUserDentist) && (
							<VisitPayment isVisited={visitDetails.visited} isDetailsView />
						)}
					{!!visitDetails.possibilityOfPregnancy && !isUserRadiologist && (
						<p className="ap-event__warning alert alert-warning visit-details">
							<i className="icon-close" />
							<span>Possibility of pregnancy.</span>
						</p>
					)}
					{!!visitDetails.bookingDetailsDto.subjectToSafeguarding &&
						!isUserRadiologist && (
							<p className="ap-event__warning alert alert-warning visit-details">
								<i className="icon-close" />
								This patient is subject to safeguarding.
							</p>
						)}
					{!!visitDetails.bookingDetailsDto.patientComeWithTemplate &&
						!isUserRadiologist && (
							<p className="ap-event__warning alert alert-warning visit-details">
								<i className="icon-close" />
								Patient comes with a radiographic template.
							</p>
						)}
					<p className="ap-event__success alert alert-success" />
				</div>
			</div>
		);
	}
}
