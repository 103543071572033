import { UpdateDataRoutine } from "../action-types";

const initialVisitsState = {
  isUpdating: false,
  error: null,
};

export const visitsUpdateReducer = (state = initialVisitsState, action) => {
  switch (action.type) {
    case UpdateDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case UpdateDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case UpdateDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
