import React from "react";
import styled from "styled-components";

const LabelItem = ({ tooth: { name }, number, className }) => {
  return (
    <label htmlFor={name} className={className}>
      {number}
    </label>
  );
};

export default styled(LabelItem)`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
`;
