import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
export default function ProfileSideMenuItem({
  label,
  icon,
  id,
  history,
  navigateTo,
  location: { pathname }
}) {
  return icon ? (
    <li className="nav-item">
      <Link
        className={classNames("nav-link border-0", {
          active: navigateTo === pathname
        })}
        id={id}
        to={navigateTo}
      >
        <i className={icon} style={{ marginRight: "5px" }} />
        {label}
      </Link>
    </li>
  ) : (
    <li className="pt-4">
      <div className="list-group-item font-weight-bold text-muted text-uppercase small border-0">
        {label}
      </div>
    </li>
  );
}
