import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {
	GET_ALL_BOOKINGS_FOR_CURRENT_WEEK,
	CREATE_BOOKING,
	GET_BOOKING_INFO,
	GET_BOOKINGS_BY_DATE_RANGE,
	GET_ALL_BOOKINGS_WITHOUT_DATE,
	SET_SCAN_PRICES,
	SET_SCAN_PRICES_STATIC,
	SET_OCCUPIED_SLOTS,
	SET_OCCUPIED_SLOTS_DATE_RANGE,
	SET_TEMPORALLY_OCCUPIED_SLOTS,
	UPDATE_TEMPORALLY_OCCUPIED_SLOTS,
	UPDATE_SCAN_PRICES_STATIC,
	SET_SCAN_PRICES_STATIC_SAVING,
	SET_LAST_SELECTED_PAYMENT_TYPE,
	SET_BLOCKED_SLOTS_DATE_RANGE,
  UPDATE_BOOKING_INFORMATION
} from "./action-types";
import { UpdateDataRoutine } from "../visits/action-types";

export const getAllBookingsForCurrentWeek = () => (dispatch) => {
	axios.get("/bookings").then((result) => {
		return dispatch({
			type: GET_ALL_BOOKINGS_FOR_CURRENT_WEEK,
			payload: result.data,
		});
	});
};

export const createBooking = (bookingDetails) => (dispatch) => {
	return axios
		.post("/bookings", {
			...bookingDetails,
		})
		.then((result) => {
			if (
				!bookingDetails.appointmentEndTime &&
				!bookingDetails.appointmentStartTime
			) {
				dispatch(getAllBookingsWithoutDate());
			} else {
				dispatch({
					type: CREATE_BOOKING,
					payload: {
						...bookingDetails,
						id: result.data,
					},
				});
			}

			toastr.success("Success", "Booking was created");
		})
		.catch((e) => toastr.error("Error", e));
};

export const updateBooking = ({
	id,
	appointmentStartTime,
	appointmentEndTime,
}) => async (dispatch) => {
	return await axios
		.patch(
			`/bookings/booking/${id}?appointmentStartTime=${appointmentStartTime}&appointmentEndTime=${appointmentEndTime}`
		)
		.then((result) => {
      dispatch({
        type: UPDATE_BOOKING_INFORMATION,
        payload: { id, appointmentStartTime,  appointmentEndTime },
      });
      dispatch(getBookingInfo(id));
			toastr.success("Success", "Booking was updated");
		})
		.catch((e) => {
			toastr.error("Error", e);
			dispatch(getAllBookingsForCurrentWeek()); // temp fix find a better way to handle it
		});
};

export const getBookingInfo = (id) => async (dispatch) => {
	const { data } = await axios.get(`/bookings/booking/${id}`, id);
	dispatch({
		type: GET_BOOKING_INFO,
		payload: data,
	});
};

export const getScanPricesForBooking = (id) => (dispatch) => {
	if (typeof id === typeof undefined) {
		id = -1;
	}

	axios.get(`/scan-prices/booking/dentist/${id}`).then((result) => {
		return dispatch({
			type: SET_SCAN_PRICES,
			payload: result.data,
		});
	});
};

export const getScanPricesForPriceList = (id) => (dispatch) => {
	if (typeof id === typeof undefined) {
		id = -1;
	}

	axios.get(`/scan-prices/pricelist/dentist/${id}`).then((result) => {
		return dispatch({
			type: SET_SCAN_PRICES_STATIC,
			payload: result.data,
		});
	});
};

export const updateScanPriceStatic = (id, type, newValue) => {
	return { type: UPDATE_SCAN_PRICES_STATIC, payload: { id, type, newValue } };
};

export const saveScanPriceStatic = (dentistId, data) => (dispatch) => {
	dispatch(setScanPriceStaticSaving(true));

	const {
		teeth: { ur = [], ul = [], ll = [], lr = [] },
		selectedCBCTFormatOptions = [],
		selected2DImagingOptions = [],
		selectedExtraOptions = [],
		selectedXRayJustificationOptions = [],
		selectedSkullOptions = [],
	} = data;

	let parsedData = [
		...ur,
		...ul,
		...ll,
		...lr,
		...selectedCBCTFormatOptions,
		...selected2DImagingOptions,
		...selectedExtraOptions,
		...selectedXRayJustificationOptions,
		...selectedSkullOptions,
	];

	if (typeof dentistId === typeof undefined) dentistId = -1;

	axios
		.put(`/scan-prices/save/dentist/${dentistId}`, parsedData)
		.then((result) => {
			toastr.success("Success", "Prices Saved");
			dispatch(setScanPriceStaticSaving(false));
		})
		.catch((e) => {
			toastr.error("Error", e);
			dispatch(setScanPriceStaticSaving(false));
		});
};

export const setScanPriceStaticSaving = (loading) => {
	return { type: SET_SCAN_PRICES_STATIC_SAVING, payload: loading };
};
//END

export const getAllBookingsWithoutDate = () => (dispatch) => {
	axios.get(`/bookings/without-date`).then((result) => {
		return dispatch({
			type: GET_ALL_BOOKINGS_WITHOUT_DATE,
			payload: result.data,
		});
	});
};

export const deleteBooking = async (id) => async (dispatch) => {
	await axios
		.delete(`/bookings/booking/${id}`)
		.then(() => {
			toastr.success("Success", "Booking was canceled");
		})
		.then(() => {
			const el = document.querySelector(".ap-event__close");
			el && el.click();
		})
		.catch((e) => toastr.error("Error", e));
};




export const updateVisit = async (bookingId) => async (dispatch) => {
  dispatch(UpdateDataRoutine.request());
	return axios
		.put(`/visits/${bookingId}/visited`, bookingId)
		.then((result) => {
			if (result.data == "PAYMENT_FAILED") {
				toastr.error("Error", "Payment failed");
				return;
			}

      dispatch(UpdateDataRoutine.success());
			toastr.success("Success", "Booking was marked as visited");
		})
		.catch((e) =>  {
      console.log(e);
      if(Number(e) === 409) {
        // already paid do nothing
        return;
      }
      dispatch(UpdateDataRoutine.failure(e));
      toastr.error("Error", e)
    });
};

export const updateVisitUnvisited = async (bookingId) => async (dispatch) => {
    dispatch(UpdateDataRoutine.request());
    return axios
        .put(`/visits/${bookingId}/unvisited`, bookingId)
        .then((result) => {
            dispatch(UpdateDataRoutine.success());
            toastr.success("Success", "Booking was marked as unvisited");
        })
        .catch((e) =>  {
            console.log(e);
            dispatch(UpdateDataRoutine.failure(e));
            toastr.error("Error", e)
        });
};


export const assignVisitTime = async (info) => async (dispatch) => {
	await axios
		.patch(`/bookings/booking/${info.id}`, null, {
			params: {
				appointmentStartTime: info.appointmentStartTime,
				appointmentEndTime: info.appointmentEndTime,
			},
		})
		.then(() => {
			toastr.success("Success", "Booking time was assigned");
		})
		.catch((e) => toastr.error("Error", e));
};

export const unAssignVisitTime = async (id) => async (dispatch) => {
	return await axios
		.patch(`/bookings/unassign-visit-time/${id}`)
		.then((result) => {
			if (result.status === 200) {
				toastr.success("Success", "Booking added to draft");
			}
		})
		.catch((e) => {
			toastr.error("Error", e);
			dispatch(getAllBookingsForCurrentWeek());
		});
};

export const fetchOccupiedSlots = () => (dispatch) => {
	const url = "/bookings/occupied";
	axios.get(url).then(({ data }) => {
		return dispatch({
			type: SET_OCCUPIED_SLOTS,
			slots: data,
		});
	});
};

export const getBookingsByDateRangeAsync = (startDate, endTime) => (
	dispatch
) => {
	axios
		.get("/bookings/date-range", {
			params: { startDate: startDate, endTime: endTime },
		})
		.then((result) => {
			return dispatch({
				type: GET_BOOKINGS_BY_DATE_RANGE,
				payload: result.data,
			});
		});
};

export const getBookingsByDateRange = AwesomeDebouncePromise(
	getBookingsByDateRangeAsync,
	500
);

export const fetchOccupiedSlotsByTimeRangeAsync = (start, end) => (
	dispatch
) => {
	const url = `/bookings/occupied/date-range?startDate=${start}&endTime=${end}`;
	axios.get(url).then(({ data }) => {
		return dispatch({
			type: SET_OCCUPIED_SLOTS_DATE_RANGE,
			slots: data,
		});
	});
};

export const fetchOccupiedSlotsByDateRange = AwesomeDebouncePromise(
	fetchOccupiedSlotsByTimeRangeAsync,
	500
);

export const fetchTemporallyOccupiedSlots = () => (dispatch) => {
	const url = `/bookings/tmpbooking`;
	axios.get(url).then(({ data }) => {
		return dispatch({
			type: SET_TEMPORALLY_OCCUPIED_SLOTS,
			slots: data,
		});
	});
};

export const updateTemporallyOccupiedSlotsAsync = (dto, userId) => (
	dispatch
) => {
	const url = `/bookings/tmpbooking`;
	axios
		.post(url, dto)
		.then(() => {
			return dispatch({
				type: UPDATE_TEMPORALLY_OCCUPIED_SLOTS,
				payload: { dto, userId },
			});
		})
		.catch((e) => {
			toastr.error("Error", e);
			dispatch(fetchOccupiedSlots());
			dispatch(fetchTemporallyOccupiedSlots());
		});
};

export const updateTemporallyOccupiedSlots = AwesomeDebouncePromise(
	updateTemporallyOccupiedSlotsAsync,
	2000
);

export const getLastSelectedPaymentType = (dentistId) => async (dispatch) => {
	const { data } = await axios.get(
		`/bookings/retrieve-latest-payment-type?dentistId=${dentistId}`
	);
	dispatch({
		type: SET_LAST_SELECTED_PAYMENT_TYPE,
		payload: data,
	});
	return data;
};

export const checkFirstCBCTIsOrNot = () => async (dispatch) => {
	const { data } = await axios.get("/bookings/dentist-has-cbct-booking");
	return data;
};

export const getBlockedDatesList = (fromDate, toDate) => async dispatch => {
	const { data } = await axios.get(`/bookings/getOccupiedDates?startDate=${fromDate}&endTime=${toDate}`);
	dispatch({
		type: SET_BLOCKED_SLOTS_DATE_RANGE,
		slots: data,
	});
	return data;
}