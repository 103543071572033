import * as t from "./action-types";
import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { NAVIGATION_CONSTANTS } from "../../constants/navigation.constants";

export const setUserProfile = (profile) => ({
  type: t.SET_USER_PROFILE,
  profile,
});

export const getUserProfile = () => (dispatch) =>
  axios
    .get("/my-profile")
    .then((result) => dispatch(setUserProfile(result.data)))
    .catch((e) => toastr.error("Error", e));

export const toggleEditMode = () => ({
  type: t.TOGGLE_EDIT_MODE,
});

export const setCreditCardDetails = (card) => ({
  type: t.SET_CREDIT_CARD,
  card,
});

export const updateUserInfo = (userInfo) => (dispatch) => {
  if (!userInfo.enabledEmailNotification && !userInfo.customEmails.length) {
    toastr.error('Either activate email notification to registration email or enter an alternative email address')
    return;
  }
  return axios
    .post("/user", userInfo)
    .then((_) => {
      dispatch(setUserProfile(userInfo));
      dispatch(toggleEditMode());
      dispatch(getUserProfile());
      toastr.success("Success", "User info was updated");
    })
    .catch((e) => toastr.error("Error", e));
};

export const updatePassword = ({ newPassword, oldPassword }) => (dispatch) =>
  axios
    .post("/user/update-password", { newPassword, oldPassword })
    .then((_) => toastr.success("Success", "Password was updated"))
    .catch((e) => toastr.error("Error", e));

export const resetPassword = ({ email, history }) => (_) =>
  axios
    .post(`/user/reset-password`, { email })
    .then((_) => {
      history.push(NAVIGATION_CONSTANTS.LOGIN);
      toastr.success(
        "Success",
        `Further instructions have been sent to ${email}`
      );
    })
    .catch((e) => toastr.error("Error", e));

export const changeResetPassword = ({ newPassword, token, history }) => (_) =>
  axios
    .post(`/user/update-reset-password`, {
      newPassword,
      token,
    })
    .then((_) => {
      history.push(NAVIGATION_CONSTANTS.LOGIN);
      toastr.success("Success", `Password was changed. You can login now`);
    })
    .catch((e) => toastr.error("Error", e));

export const activateUser = ({ confirmationToken, history }) => (_) =>
  axios
    .get(`/user/confirm?confirmationToken=${confirmationToken}`)
    .then((_) => {
      history.push(NAVIGATION_CONSTANTS.LOGIN);
      toastr.success("Success", "User was activated");
    })
    .catch((e) => toastr.error("Error", e));

export const getCreditCardDetails = () => (dispatch) =>
  axios
    .get("/payments/card")
    .then((result) => dispatch(setCreditCardDetails(result.data)))
    .catch((e) => toastr.error("Error", e));

export const updatePaymentInformation = ({ cardHolderFullName, cardPopulatedData, paymentMethod, customerId }) => (dispatch) => {
  return axios
    .post("/payments/token", {
      cardholderFullName: cardHolderFullName,
      expYear: cardPopulatedData.exp_year,
      last4: cardPopulatedData.last4,
      expMonth: cardPopulatedData.exp_month,
      brand: cardPopulatedData.brand,
      paymentMethod: paymentMethod,
      customerId: customerId
    })
    .then((_) =>
      dispatch(getCreditCardDetails())
        .then((_) => {
          toastr.success("Success", "Credit card info saved");
          dispatch(getUserProfile());
        })
        .catch((e) => toastr.error("Error", e))
    )
    .catch((e) => toastr.error("Error", e));
};
