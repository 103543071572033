import React, { useEffect, useState } from "react";
import axios from '../../axios-client';
import './index.css';

export default function SurveyDetailsModal({
    registrationSurveyItem,
    onClose
}) {
    const [registrationSurveyDetails, setRegistrationSurveyDetails] = useState([])
    useEffect(() => {
        axios.get(`/registration-surveys/retrieve-registration-surveys?registrationSurveyItemId=${registrationSurveyItem.id}`)
            .then(response => {
                setRegistrationSurveyDetails(response.data)
            })
    }, []);

    return (
        <div className="modal-dialog survey-modal" role="document">
            <div className="modal-content border-0">
                <div className="card border-primary mb-0">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className="survey-details-item-name">{registrationSurveyItem.name}</p>
                            <svg onClick={onClose} className="survey-details-close-icons" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L17 17" strokeWidth="2" />
                                <path d="M17 1L0.999998 17" strokeWidth="2" />
                            </svg>
                        </div>
                        <hr className="survey-details-horizontal-line" />
                        <div className="survey-details-block">
                            {registrationSurveyDetails.map((registrationSurveyDetail, i) =>
                                <div key={registrationSurveyDetail.id} className={i > 0 ? "survey-details-block-item" : ""}>
                                    <p htmlFor={registrationSurveyDetail.dentist.firstName}>
                                        {registrationSurveyDetail.dentist.firstName + " " + registrationSurveyDetail.dentist.lastName}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}