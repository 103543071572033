//Project dependencies
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import visitsAPI from "../../modules/visits";
import radiologyReportsApi from "../../modules/radiologyReports";
import visitDetailsAPI from "../../modules/visit-details";
import profileAPI from "../../modules/profile";
import radiologistsApi from "../../modules/radiologists";
import VisitsContainer from "./container";
import { withRouter } from "react-router";
import dentistAPI from "../../modules/dentists";
import dashboardAPI from "../../modules/dashboard";
import patientsApi from "../../modules/patient";
import { logoutAction } from "../../actions/authActions";

const mapStateToProps = state =>
  createStructuredSelector({
    dentists: dentistAPI.selectors.getDentists,
    radiologists: radiologistsApi.selectors.getRadiologists,
    userRole: profileAPI.selectors.getUserRole,
    userInfo: profileAPI.selectors.getUserProfileInfo,
    hasLinkedAccounts: profileAPI.selectors.getHasLinkedAccounts,
    visits: visitsAPI.selectors.getVisits,
    visitCount: visitsAPI.selectors.getVisitCount,
    filters: visitsAPI.selectors.getVisitFilter,
    visitsByPatient: visitDetailsAPI.selectors.getPastVisits,
    bookingInfo: dashboardAPI.selectors.getBookingInfo
  });

const mapDispatchToProps = dispatch => ({
  profileActions: bindActionCreators(profileAPI.actions, dispatch),
  dentistActions: bindActionCreators(dentistAPI.actions, dispatch),
  visitsActions: bindActionCreators(visitsAPI.actions, dispatch),
  radiologyReportsActions: bindActionCreators(radiologyReportsApi.actions, dispatch),
  visitDetailsActions: bindActionCreators(visitDetailsAPI.actions, dispatch),
  dashboardActions: bindActionCreators(dashboardAPI.actions, dispatch),
  radiologistsActions: bindActionCreators(radiologistsApi.actions, dispatch),
  logoutAction: () => {
    dispatch(logoutAction());
  },
  patientActions: bindActionCreators(patientsApi.actions, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisitsContainer)
);
