import React from "react";
import MaskedInput from "react-text-mask";
import classNames from "classnames";

const TimeAdapter = ({ input, meta, ...rest }) => {
  const mask = [/[0-1]/, /[0-9]/, ":", /[0-5]/, /[0|5]/];
  return (
    <div className="form-group input-group">
      <div className="input-group-prepend">
        <div className="input-group-text">
          <i className="icon-clock" />
        </div>
      </div>
      <MaskedInput
        mask={mask}
        {...input}
        {...rest}
        className={classNames(
          "form-control ap-time-picker time-inputs-overrides",
          {
            "is-invalid": meta.error
          }
        )}
        onChange={event => {
          //   input.onBlur(event);
          input.onChange(event.target.value);
        }}
      />
    </div>
  );
};

export default TimeAdapter;
