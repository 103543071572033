import * as t from "../action-types";
import { prepareExpData } from "../helpers";

export const creditCardReducer = (
  state = {
    cardholderFullName: "Cardholder name",
    expirationDate: "00/00",
    last4: "XXXX"
  },
  action
) => {
  switch (action.type) {
    case t.SET_CREDIT_CARD:
      return {
        ...action.card,
        expirationDate: prepareExpData(action.card)
      };
    default:
      return state;
  }
};
