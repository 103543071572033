import {
	FetchDataRoutine,
	SET_FILTERS,
	UPDATE_REPORT,
	UPDATE_SCAN,
	UPDATE_SCAN_UPLOAD
} from "../action-types";
import {
	updateReportForData,
	updateScanForData,
	updateScanForDataAfterUpload
} from "../helpers";

const initialVisitsState = {
	isFetching: false,
	data: [],
	filters: { searchString: "", startDate: null, endDate: null, orderBy: 'visitDate', desc: true },
	error: null
};

export const visitsReducer = (state = initialVisitsState, action) => {
	switch (action.type) {
		case FetchDataRoutine.SUCCESS:
			return {
				...state,
				isFetching: false,
				data: action.payload.visits.map(i => ({ ...i, scanClouds: i.scans })),
				visitCount: action.payload.visitCount,
				error: null
			};
		case SET_FILTERS:
			return {
				...state,
				filters: action.filters
			};
		case UPDATE_REPORT:
			return {
				...state,
				data: updateReportForData(state.data, action.payload)
			};
		case UPDATE_SCAN:
			return {
				...state,
				data: updateScanForData(state.data, action.payload)
			};
		case UPDATE_SCAN_UPLOAD:
			return {
				...state,
				data: updateScanForDataAfterUpload(state.data, action.scans)
			};
		case FetchDataRoutine.REQUEST:
			return {
				...state,
				isFetching: true,
				error: null
			};
		case FetchDataRoutine.FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.payload
			};

		default:
			return state;
	}
};
