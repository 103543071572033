import { slotDuration } from "../../../../constants/global.constants";

const businessHours = [
	{
		startTime: "10:00",
		endTime: "18:00",
		daysOfWeek: [1, 2, 3, 4, 5]
	},
	{
		startTime: "09:00",
		endTime: "18:00",
		daysOfWeek: [6]
	}
];

const calendarConfig = {
	minTime: "08:00:00",
	maxTime: "20:00:00",
	defaultTimedEventDuration: `00:${slotDuration}:00`,
	slotDuration: `00:${slotDuration}:00`,
	slotLabelInterval: `00:${slotDuration}:00`,
	firstDay: 1
};

const slotLabelFormat = {
	hour: "numeric",
	minute: "2-digit",
	omitZeroMinute: false,
	meridiem: "short"
};

export { businessHours, calendarConfig, slotLabelFormat };
