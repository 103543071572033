export const prepareAddressesInfo = (address) => {
  const addresses = [];

  const city = address.post_town.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });

  if (address.line_1) addresses.push(address.line_1);
  if (address.line_2) addresses.push(address.line_2);
  if (address.line_3) addresses.push(address.line_3);
  addresses.push(city);
  addresses.push(address.postcode_outward);

  const addressesInfo = {
    fullAddress: addresses.join(", "),
    postcode: address.postcode,
    city: city,
  };

  return addressesInfo;
};
