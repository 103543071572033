export const NAVIGATION_CONSTANTS = Object.freeze({
  REGISTER: "/register",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  FORGOT_USERNAME: "/forgot-username",
  VISITS: "/visits",
  PRICE_LIST: "/pricelist",
  RESET_PASSWORD: "/pass-reset",
  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
  CREDIT_CARD_DETAILS: "/credit-card-details",
  JOINED_ACCOUNTS: "/joined-accounts",
  AGREEMENT: "/agreement",
  AGREEMENT_DENTISTS_LIST: "/agreement-dentists-list",
  AGREEMENT_DENTIST_DETAILS: "/agreement-dentist-details",
  DATA_PROCESSING: "/data-processing-agreement",
  TERMS: "/terms-and-conditions",
  WALLET: "/wallet",
  PRICING: "/price-list",
  REGISTRATION_SURVEY: "/registration-survey",
  AUDIT_REPORTS: "/audit-report",
  RADIOLOGIST_REPORTS: "/radiologist-reports",
  VISITS_MOBILE: "/visits-mobile",
  PATIENT_SATISFACTION_REPORTS: '/patient-satisfaction-reports',
  AUDIT_REGISTRATION_REPORT: '/audit-registration-report',
  PATIENT_SATISFACTION_FORM: '/feedback',
  INVOICES: "/invoices",
  VOUCHERS: "/vouchers",
  COVID19SURVEY: "/covid-19-survey",
  RADIOLOGY_REPORT: "/radiology-report",
  REFERRAL_GUIDELINES: "/referral-guidelines",
  RADIOGRAPHERS: "/radiographers",
  RADIOLOGISTS: "/radiologists",
  DENTISTS: "/dentists",
  SCHEDULES: "/schedules",
  RECEIPT: '/receipt',
});
