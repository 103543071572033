import React, { useState } from "react";
import classnames from "classnames";
import EditModeToggler from "../EditModeToggler";
import Modal from "../../../../common/modals/Modal";
import { Form, Field } from "react-final-form";
import DateInputAdapter from "../../../../common/masks/DateInputAdapter";
import CreditCardInputAdapter from "../../../../common/masks/CreditCardInputAdapter";
import { required, composeValidators, hasRequiredLength } from "../../helpers";
import { toastr } from "react-redux-toastr";
import useModal from "../../../../hooks/useModal";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe, 
  useElements
} from '@stripe/react-stripe-js';
import styled from "styled-components";
import axios from '../../../../axios-client';

const CreditCardSettings = ({
  creditCardInfo,
  updatePaymentInformation,
  isUserDentist
}) => {
  const getNumber = () => `'${creditCardInfo.last4}'`;
  const PseudoClassStyle = styled.div`
    &:before {
      content: ${getNumber} !important;
    }
  `;
  const [editMode, setEditMode] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, submit] = useState(false)

  const {
    handleModalVisibilityToggle,
    showModal,
    toggleModalVisibility
  } = useModal();
  const onDiscard = form => {
    handleModalVisibilityToggle();
    form.reset();
    setEditMode(false);
  };
  const handleCreditCardUpdate = async (cardData, form) => {
    submit(true);

    if (!stripe || !elements) {
      return;
    }

    let cardPopulatedData, cardHolderFullName = cardData.cardholderFullName, clientSecret, paymentMethod, customerId;
    await stripe.createToken(elements.getElement(CardNumberElement)).then(result => cardPopulatedData = result.token.card)

    await axios.get('/payments/getClientSecret').then(res => {
      clientSecret = res.data.clientSecret;
      customerId = res.data.customerId;
    });

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    });

    if (result.error) {
        toastr.error("Error", "Please enter valid data");
    } else {
      paymentMethod = result.setupIntent.payment_method;
      updatePaymentInformation({ cardHolderFullName, cardPopulatedData, paymentMethod, customerId });
      setEditMode(false);
    }
    submit(false)
  };
  const noop = () => {};
  return (
    <div className="row ap-card-editor padding-1-rem">
      <div className="ap-col-fixed-450">
        <EditModeToggler
          onToggle={e => setEditMode(true)}
          isEdit={!editMode}
          isUserDentist={isUserDentist}
        >
          Credit Card Information
        </EditModeToggler>
        <div
          className={classnames("ap-credit-card mt-4", {
            "mod-locked": !editMode
          })}
        >
          <Form
            onSubmit={noop}
            initialValues={creditCardInfo}
            render={({ handleSubmit, invalid, values, form }) => (
              <form onSubmit={handleSubmit}>
                <label className="small px-2">Card Number</label>
                {editMode && (
                  <div className="row mx-0 ap-credit-card-number">
                    <div className="col-sm-12 px-2">
                      <CardNumberElement className="form-control" />
                    </div>
                  </div>
                )}
                {!editMode && (
                  <div className="row mx-0 ap-credit-card-number">
                    <div className="col-sm-3 px-2">
                      <div className="form-group mod-masked">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="XXXX"
                          name="input"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 px-2">
                      <div className="form-group mod-masked">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="XXXX"
                          name="input"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 px-2">
                      <div className="form-group mod-masked">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="XXXX"
                          name="input"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 px-2">
                      <PseudoClassStyle
                        className={classnames("form-group mod-masked", {
                          "custom-credit-class": !editMode
                        })}
                      >
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="XXX"
                          name="last4"
                          component={CreditCardInputAdapter}
                        />
                      </PseudoClassStyle>
                    </div>
                  </div>
                )}
                <div className="row mx-0 mt-4">
                  <div className="col-sm-6 px-2">
                    <div className="form-group">
                      <label className="small">Cardholder Full Name</label>
                      <Field
                        type="text"
                        name="cardholderFullName"
                        className="form-control text-uppercase"
                        placeholder="Cardholder Full Name"
                        component="input"
                        validate={required}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3 px-2">
                    <div className="form-group">
                      <label className="small">Expires</label>
                      {editMode && (
                        <CardExpiryElement className="form-control" />
                      )}
                      {!editMode && (
                        <Field
                          type="text"
                          className="form-control"
                          name="expirationDate"
                          component={DateInputAdapter}
                          validate={composeValidators(required, val =>
                            hasRequiredLength(val, 4, "/")
                          )}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-sm-3 px-2 ap-credit-card-cvv">
                    <div className="form-group mod-masked">
                      <label className="small">CSV</label>
                      <CardCvcElement className="form-control" />
                    </div>
                  </div>
                </div>
                {editMode && (
                  <div>
                    <div className="row button-override">
                      <div className="col-sm-6">
                        <button
                          className="btn btn-light ap-discard-credit-information"
                          onClick={toggleModalVisibility}
                        >
                          Discard Changes
                        </button>
                      </div>
                      <div className="col-sm-6 text-right">
                        <button
                          className="btn btn-primary ap-save-credit-information"
                          disabled={invalid || isSubmitting}
                          onClick={() => handleCreditCardUpdate(values, form)}
                        >
                          {isSubmitting ? 'Saving...' : 'Save'}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {showModal && (
                  <Modal
                    onDiscard={() => onDiscard(form)}
                    onClose={handleModalVisibilityToggle}
                  >
                    Are you sure you want to discard your changes?
                    <br />
                    All of the progress will be lost.
                  </Modal>
                )}
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCardSettings;
