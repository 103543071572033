import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { toastr } from "react-redux-toastr";
import "./styles.scss";
// import MobileScansViewer from "../../../visits/components-mobile/MobileScansViewer";
import GroupedScansViewer from "../../../visits/components/GroupedScansViewer";
import TeethSVG from "../../../dashboard/components/TeethSVG";
import SkullSVG from "../../../dashboard/components/SkullSVG";
import { selectedSkullPartsOptionsV2 } from "../../../../constants/priceItems.constants";
import axios from "../../../../axios-client";

export default function ScanInfo({
	isUserRadiographer,
	deleteScan,
	visitDetails,
	preparedVisitDetails: {
		selectedCBCTFormat,
		selectedXRayJustification,
		selectedExtra,
		selected2DImaging,
		selectedTeeth,
		selectedSkullParts,
	}
}) {
	const [verballyConsented, setVerballyConsented] = useState(true)
	const [authorised, setAuthorised] = useState(true)

	useEffect(() => {
		setAuthorised(visitDetails.bookingDetailsDto.authorised)
	}, [visitDetails.bookingDetailsDto.authorised])

	useEffect(() => {
		setVerballyConsented(visitDetails.bookingDetailsDto.verballyConsented)
	}, [visitDetails.bookingDetailsDto.verballyConsented])

	function formatScanType() {
		const config = {
			TWO_D: "2D Imaging",
			CBCT: "CBCT",
			SKULL: "Maxillofacial/ENT",
		};
		return config[visitDetails.bookingDetailsDto.scanType];
	}

	function isCBCT() {
		return visitDetails.bookingDetailsDto.scanType === "CBCT";
	}

	function isSkull() {
		return visitDetails.bookingDetailsDto.scanType === "SKULL";
	}

	function renderPainterSkull() {
		const { bookingItemDtos } = visitDetails.bookingDetailsDto;
		const getSkullPriceItems = bookingItemDtos
			.filter((i) => selectedSkullPartsOptionsV2.includes(i.itemPriceDto.name))
			.map((i) => i.itemPriceDto.name);

		return (
			<SkullSVG
				isDetails
				selectedItems={getSkullPriceItems}
				options={getSkullPriceItems}
			/>
		);
	}

	function handleVerballyConsentedChange(prevState) {
		if (!isUserRadiographer) return;

		const updatedVerballyConsented = !prevState;

		setVerballyConsented(updatedVerballyConsented);

		axios
			.put(
				`/bookings/update-verbally-consented/${visitDetails.id}?verballyConsented=${updatedVerballyConsented}`
			)
			.then(() => {
				/** everything is OK here */
			})
			.catch(() => {
				toastr.error("Error", "Could not complete operation");
				this.setVerballyConsented(prevState);
			});
	};

	function handleAuthoriseChange(prevState) {
		if (!isUserRadiographer) return;
		const updatedAuthorised = !prevState;

		setAuthorised(updatedAuthorised);

		axios
			.put(
				`/bookings/update-authorised/${visitDetails.id}?authorised=${updatedAuthorised}`
			)
			.catch(() => {
				toastr.error("Error", "Could not complete operation");
				this.setAuthorised(prevState);
			});
	}

	return (
		<div className="row">
			<div className="col-8">
				<ul className="list-group list-group-flush">
					<li className="list-group-item">
						<Row>
							<Col>Required service</Col>
							<Col>{formatScanType()} Scan</Col>
						</Row>
					</li>
				</ul>
				<ul className="list-group">
					{isCBCT() && (
						<li className="list-group-item">
							<span className="row">
								<span className="col-5">
									{formatScanType()} Area of Interest
								</span>

								<span className="col-7 ap-tooth-labels">
									{selectedTeeth.map((option, i) => {
										return (
											<label htmlFor={option.htmlFor} key={i}>
												{option.label}
											</label>
										);
									})}
								</span>
							</span>
						</li>
					)}
					<li className="list-group-item">
						<Row>
							<Col>Clinical Indications/Relevant History/Clinical Question</Col>
							<Col className="clinical-indications-wrapper">
								{visitDetails.bookingDetailsDto.clinicalIndications
									? visitDetails.bookingDetailsDto.clinicalIndications
									: " — "}
							</Col>
						</Row>
					</li>
					{!!selectedCBCTFormat.length && (
						<li className="list-group-item">
							<Row>
								{isSkull && (
									<Col>CBCT Format</Col>)}
								{!isSkull && (
									<Col>{formatScanType()} Format</Col>)}
								<Col>
									{selectedCBCTFormat.map((value, i) => {
										return <Row key={i}>{value.label}</Row>;
									})}
								</Col>
							</Row>
						</li>
					)}
					{!!selected2DImaging.length && (
						<li className="list-group-item">
							<Row>
								<Col>{formatScanType()} Format</Col>
								<Col>
									{selected2DImaging.map((value, i) => {
										return <Row key={i}>{value.label}</Row>;
									})}
								</Col>
							</Row>
						</li>
					)}
					{!!selectedSkullParts.length && (
						<li className="list-group-item">
							<Row>
								<Col>Maxillofacial/ENT</Col>
								<Col>
									{selectedSkullParts
										.filter((i) => i.label !== "Maxillofacial")
										.map((value, i) => {
											return <Row key={i}>{value.label}</Row>;
										})}
								</Col>
							</Row>
						</li>
					)}
					{!!selectedXRayJustification.length && (
						<li className="list-group-item">
							<Row>
								<Col>Justification For X-ray</Col>
								<Col>
									{selectedXRayJustification.map((value, i) => {
										return <Row key={i}>{value.label}</Row>;
									})}
								</Col>
							</Row>
						</li>
					)}
					<li className="list-group-item">
						<Row>
							<Col>Include TMJs</Col>
							<Col>
								<Row>
									<input
										type="checkbox"
										checked={visitDetails.bookingDetailsDto.includeTMJs}
										disabled
									/>
								</Row>
							</Col>
						</Row>
					</li>
					<li className="list-group-item">
						<Row>
							<Col>Verbally consented</Col>
							<Col>
								<Row>
									<input
										type="checkbox"
										checked={verballyConsented}
										disabled={!isUserRadiographer}
										onChange={() => handleVerballyConsentedChange(verballyConsented)}
									/>
								</Row>
							</Col>
						</Row>
					</li>
					<li className="list-group-item">
						<Row>
							<Col>Authorised</Col>
							<Col>
								<Row>
									<input
										type="checkbox"
										checked={authorised}
										disabled={!isUserRadiographer}
										onChange={() => handleAuthoriseChange(authorised)}
									/>
								</Row>
							</Col>
						</Row>
					</li>
					{!!selectedExtra.length && (
						<li className="list-group-item">
							<Row>
								<Col>Full radiology report</Col>
								<Col>
									{selectedExtra.map((value, i) => {
										if (value.value.startsWith("e_frr_")) {
											return (
												<Row key={i}>
													{value.label}
													{/* ({radiologist}) */}
												</Row>
											);
										} else {
											return <Row key={i}>{value.label}</Row>;
										}
									})}
								</Col>
							</Row>
						</li>
					)}
				</ul>
				{visitDetails.scans && (
					<ul className="list-group list-group-flush">
						<li className="list-group-item">
							<Row className="row">
								<Col xs="12" style={{ padding: "5px 0" }}>
									Scans from this visit
								</Col>
								{!visitDetails.scans.length && (
									<span className="col-7">
										<i className="icon-close"></i> Upload pending...
									</span>
								)}
								{!!visitDetails.scans.length && !isUserRadiographer && (
									<GroupedScansViewer scans={visitDetails.scans} />
								)}
								{!!visitDetails.scans.length && isUserRadiographer && (
									<GroupedScansViewer
										scans={visitDetails.scans}
										isRadiographer={true}
										visitId={visitDetails.id}
										deleteScan={deleteScan}
									/>
								)}
							</Row>
						</li>
					</ul>
				)}
			</div>
			<div className="col-4">
				<form className="teeth-map">
					{selectedTeeth.map((option, index) => {
						return (
							<input
								type="checkbox"
								id={option.htmlFor}
								className="ap-tooth-checkbox"
								checked="checked"
								disabled="disabled"
								key={index}
							/>
						);
					})}
					{isCBCT() && <TeethSVG />}
					{isSkull() && renderPainterSkull()}
				</form>
			</div>
		</div>
	);
}