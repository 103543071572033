export default (state = {}, action) => {
    switch (action.type) {
     case 'LOGIN_ACTION':
      return {
       auth_token: action.payload
      }
      case 'LOGOUT_ACTION':
       return {
        auth_token: ''
       }
     default:
      return state
    }
   }
