import { FetchDataRoutine } from "../action-types";

const initialCovid19SurveyState = {
  isFetching: false,
  patient: {},
  error: null
};

export const covid19SurveyReducer = (state = initialCovid19SurveyState, action) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        patient: action.payload,
        error: null
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
