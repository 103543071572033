import React, { Fragment } from "react";
import moment from "moment";
import { presentationDateFormat } from "../../../../constants/global.constants";
import BriefScanInfo from "./BriefScanInfo";

import "./styles.css";

export default function ReportDetailsPopup({ close, report }) {

  const {
    dentist,
    dentistName,
    booking,
    orderReportDate,
    scanTakenDate,
    scanIndication,
    implandtPlanned,
    evaluateExisting,
    sinusEvaluation,
    tmjEvaluation,
    fullRadiologyReportUrl,
    fullRadiologyReportName,
    notes,
    patientMobile,
    patientEmail,
    patientTitle,
    patientName,
    patientSurname,
    patientDOB,
    patientData
  } = report;

  let patientFullName = patientTitle ? `${patientTitle}. ${patientName} ${patientSurname}` : `${patientName} ${patientSurname}`;
  const patientDob = booking ? booking.patient.dob : patientDOB;

  if (booking) {
    const { title, firstName, lastName } = booking.patient;
    patientFullName = title ? `${title}. ${firstName} ${lastName}` : `${firstName} ${lastName}`;
  }
  return (
    <div
      className="ap-side-modal"
      id="reportDetails"
      style={{ display: "block" }}
    >
      <div className="ap-side-modal-backdrop" onClick={() => close()} />
      <div className="ap-side-modal-content">
        <div className="report-patient-info">
          <div className="row">
            <div className="col-4">
              <h3>{patientFullName.replace(/undefined|undefined./g,'')}</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <small>{moment(patientDob).format("DD/MM/YYYY")}</small>
            </div>
          </div>
          <div className="ap-patient-details">
            <p>
              <i className="icon-phone" />{" "}
              <span>{booking ? booking.patient.mobile : patientData.mobile}</span>
              <i className="icon-envelope ml-4" />{" "}
              <a
                href={`mailto:${
                  booking ? booking.patient.email : patientData.email
                }`}
              >
                {booking ? booking.patient.email : patientData.email}
              </a>
            </p>
            {booking && (
              <p>
                <i className="icon-map" />{" "}
                <span>
                  {booking.patient.city}, {booking.patient.address},{" "}
                  {booking.patient.postCode}
                </span>
              </p>
            )}

            <p>
              <i className="icon-calendar" />{" "}
              <span>
                {moment(orderReportDate).format(presentationDateFormat)}
              </span>
            </p>
            <p>
              <i className="icon-user"></i>{" "}
              <span>
                Dr. {dentistName ? dentistName : `${dentist.firstName} ${dentist.lastName}`} 
              </span>
            </p>
          </div>
        </div>
        <div className="alert alert-info" role="alert">
          {booking
            ? "Patient is scanned at 3 Beam"
            : "Patient is scanned elswhere"}
        </div>
        {booking && (
          <div className="mb-4">
            <BriefScanInfo visitDetails={booking} />
          </div>
        )}
        <div className="report-details">
          {!booking && (
            <Fragment>
              <div className="report-detail-entry">
                <h4>Date scan was taken</h4>
                <p>{moment(scanTakenDate).format("DD/MM/YYYY")}</p>
              </div>
              <div className="report-detail-entry">
                <h4>Indication for the scan and relevant history</h4>
                <p>
                  {scanIndication || (
                    <span className="text-muted">Not specified</span>
                  )}
                </p>
              </div>
            </Fragment>
          )}

          <div className="report-detail-entry">
            <h4>Type of report</h4>
            <p className="d-flex">
              {implandtPlanned && (
                <span className="btn btn-light mr-3">Implant Planned</span>
              )}
              {evaluateExisting && (
                <span className="btn btn-light mr-3">Evaluate Existing</span>
              )}
              {sinusEvaluation && (
                <span className="btn btn-light mr-3">Sinus Evaluation</span>
              )}
              {tmjEvaluation && (
                <span className="btn btn-light mr-3">TMJ Evaluation</span>
              )}
              {!implandtPlanned &&
              !evaluateExisting &&
              !sinusEvaluation &&
              !tmjEvaluation ? (
                <span className="text-muted">Not selected</span>
              ) : null}
            </p>
          </div>

          <div className="report-detail-entry">
            <h4>Comments and Special Requests</h4>
            <p>{notes || <span className="text-muted">Not specified</span>}</p>
          </div>

          {fullRadiologyReportUrl && (
            <div className="report-detail-entry">
              <h4>Uploaded scan</h4>
              <p className="d-flex">
                <a
                  href={fullRadiologyReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-light mr-3"
                >
                  {fullRadiologyReportName}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
