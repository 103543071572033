import {
  FetchDataRoutine,
  GenerateDataRoutine,
  SET_FILTERS
} from "../action-types";

const initialRadiologyReportsState = {
  isFetching: false,
  data: [],
  filters: { searchString: null, startDate: null, endDate: null },
  error: null
};

export const radiologyReportsListReducer = (state = initialRadiologyReportsState, action) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.orderReports,
        reportCount: action.payload.orderReportsCount,
        error: null
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case GenerateDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [action.payload, ...state.radiologyReports],
        error: null
      };
    case GenerateDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case GenerateDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
