import React, { useState } from "react";

export default function JoinModal({ onClose, onJoin, linkCode }) {
  const [code, setCode] = useState("");
  return (
    <div className="modal-dialog" role="document" style={{ width: "600px" }}>
      <div className="modal-content border-0">
        <div className="card border-primary mb-0">
          <div className="card-header bg-primary">
            Link account
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <div className="form-group">
              <label>Enter the code provided by other dentist:</label>
              <input
                type="text"
                className="form-control"
                onChange={e => setCode(e.target.value)}
              />
            </div>
          </div>
          <div className="card-footer text-right">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={
                code === "" || code === linkCode || code.trim().length === 0
              }
              onClick={() => onJoin(code)}
            >
              Link account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
