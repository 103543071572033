import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import PatientInfo from "../PatientInfo";
import ScanInfo from "../ScanInfo";
import PastVisits from "../PastVisits";
import Comments from "../Comments";
import ReportUploader from "../ReportUploader";
import ScansUpload from "../ScansUpload";
import ScansManualUpload from "../ScansManualUpload";
import PriceBreakDown from "../../../dashboard/components/PriceBreakDown";
import { isBooking } from "../../helpers";
import { preparePricingData } from "../../../../services/pricing.service";
import ApplyVoucherForm from "../../../../common/ApplyVoucherForm";
import { applyVoucher } from "../../../../services/api";
import{ omit } from 'lodash'
import "./index.css";

export default class VisitDetailsPopUp extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleApplyVoucher = this.handleApplyVoucher.bind(this);
    this.state = {
      activeTab: "1",
      voucherInfo: null,
      showVoucherForm: false,
      voucherCode: "",
      applyingVoucher: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentWillMount() {
    this.props.getBookingInfo(
      this.props.visitDetails.visitId || this.props.visitDetails.id
    );

    this.props.fetchRadiologists();
  }

  handleApplyVoucher() {
    const { voucherCode } = this.state;

    this.setState({ applyingVoucher: true });

    applyVoucher(this.props.bookingInfo.id, voucherCode)
      .then((discountAmount) => {
        this.setState({
          voucherInfo: {
            isValid: true,
            discountAmount,
            code: voucherCode,
          },
        });
      })
      .catch(() => {
        this.setState({
          voucherInfo: {
            isValid: false,
            discountAmount: 0,
            code: voucherCode,
          },
        });
      })
      .finally(() => {
        this.setState({ applyingVoucher: false });
      });
  }

  render() {
    const {
      requestReport,
      visitDetails,
      uploadReport,
      isUserRadiologist,
      isUserDentist,
      isUserReceptionist, 
      isUserRadiographer,
      onFileUpload,
      onAddLinkForScan,
      onReportUpload,
      deleteScan,
      radiologists,
      type,
    } = this.props;

    const {
      showVoucherForm,
      applyingVoucher,
      voucherCode,
      voucherInfo,
    } = this.state;

    let currentVisitDetails = { ...visitDetails };

    if (this.props.bookingInfo) {
      currentVisitDetails = {
        ...this.props.bookingInfo,
        // ...visitDetails
        ...omit(visitDetails, 'patient'),
      };

      if (!visitDetails) {
        return null;
      }

    }

    if (!this.props.bookingInfo) {
      return null;
    }

    const preparedPricingData = preparePricingData(
      currentVisitDetails,
      radiologists,
      currentVisitDetails.totalAmount
    );

    return (
      this.props.bookingInfo &&
      currentVisitDetails && (
        <div
          className="ap-side-modal"
          id="visitDetails"
          style={{ display: "block" }}
        >
          <div
            className="ap-side-modal-backdrop"
            onClick={() => this.props.onClose()}
          />
          <div className="ap-side-modal-content">
            <PatientInfo
              key={currentVisitDetails.id}
              {...this.props}
              visitDetails={currentVisitDetails}
            />
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Current Visit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  All Visits
                </NavLink>
              </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3",
                    })}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Comments
                  </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <ScanInfo
                  visitDetails={currentVisitDetails}
                  preparedVisitDetails={preparedPricingData}
                  isUserRadiographer={isUserRadiographer}
                  deleteScan={deleteScan}
                  radiologists={radiologists}
                />

                {isUserRadiographer && (
                  <Row>
                    <Col sm={{ size: 6 }}>
                      <ScansUpload onFileUpload={onFileUpload} />
                    </Col>
                    <Col sm={{ size: 6 }}>
                      <ScansManualUpload
                        handleAdd={onAddLinkForScan}
                        label="Or enter link to cloud"
                        btnLabel="Add link"
                      />
                    </Col>
                    {/* <Col sm={{ size: 4 }}>
                      <ScansManualUpload handleAdd={onAddLinkDosageForScan} label="Specify given dosage" btnLabel="Add dosage"/>
                    </Col> */}
                  </Row>
                )}

                {this.props.type === "visit" && (
                  <ReportUploader
                    requestReport={requestReport}
                    uploadReport={uploadReport}
                    visitDetails={currentVisitDetails}
                    isUserRadiologist={isUserRadiologist}
                    onReportUpload={onReportUpload}
                    deleteScan={deleteScan}
                  />
                )}
              </TabPane>
              <TabPane tabId="2">
                <PastVisits
                  visitsByPatient={this.props.visitsByPatient}
                  bookingInfo={this.props.bookingInfo}
                  retrievePreviousVisits={this.props.retrievePreviousVisits}
                />
              </TabPane>
              <TabPane tabId="3">
                <Comments
                  isUserReceptionist={this.props.isUserReceptionist}
                  isUserRadiographer={this.props.isUserRadiographer}
                  comments={this.props.bookingInfo.bookingComments}
                  addComment={this.props.addComment}
                  bookingId={this.props.bookingInfo.id}
                />
              </TabPane>
            </TabContent>
            <Row className="footer mt-2">
              {(isUserDentist || isUserReceptionist) && (
                <Col sm={{ size: 8 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ marginRight: "10px" }}>
                      <PriceBreakDown
                        {...preparedPricingData.preparePriceBrakeDown}
                        voucherInfo={this.state.voucherInfo}
                        visitDetails={currentVisitDetails}
                      />
                    </div>

                    {voucherInfo && voucherInfo.isValid ? (
                      <span className="text-success">
                        <i className="icon-check d-inline-block mr-1"></i>
                        <span className="d-inline-block">Voucher applied!</span>
                      </span>
                    ) : (
                      <ApplyVoucherForm
                        showVoucherForm={showVoucherForm}
                        loading={applyingVoucher}
                        voucherInfo={voucherInfo}
                        voucherCode={voucherCode}
                        handleApplyVoucher={this.handleApplyVoucher}
                        toggleShowVoucherForm={() =>
                          this.setState({ showVoucherForm: true })
                        }
                        handleInputChange={(e) =>
                          this.setState({ voucherCode: e.target.value })
                        }
                      />
                    )}
                  </div>
                </Col>
              )}
              {(isUserDentist || isUserReceptionist) &&
                !currentVisitDetails.visited &&
                isBooking(type) && (
                  <Col sm={{ size: 4 }} className="text-right">
                    <button
                      className="btn btn-danger"
                      onClick={() =>
                        this.props.cancelBooking({
                          name: currentVisitDetails.patient.name,
                          date: currentVisitDetails.appointmentStartTime,
                          id: currentVisitDetails.id,
                        })
                      }
                    >
                      Cancel Visit
                    </button>
                  </Col>
                )}
            </Row>
          </div>
        </div>
      )
    );
  }
}
