import React, { useState, useEffect, useCallback } from "react";
import {
	Input,
	InputGroup,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import { IoIosSend } from "react-icons/all";
import { Preloader, TailSpin } from "react-preloader-icon";
import Moment from "moment";

import "./index.css";

export default function Comments({
	isUserReceptionist,
	isUserRadiographer,
	comments,
	bookingId,
	addComment,
}) {
	const [isLoading, setLoading] = useState(false);
	const [commentExist, setCommentExistOrNot] = useState(false);
	const [comment, setComment] = useState(false);

	useEffect(() => {
		isUserRadiographer && prepareAutoExpanded();
	}, []);

	const prepareAutoExpanded = useCallback(() => {
		var textarea = document.querySelector("#commentInput");

		textarea.addEventListener("keydown", autosize);

		function autosize() {
			var el = this;
			setTimeout(function () {
				el.style.cssText = "height:auto; padding:0";
				el.style.cssText = "height:" + el.scrollHeight + "px";
			}, 0);
		}
	}, []);

	const saveComment = () => {
		setLoading(true);
		addComment(bookingId, comment).then((commentData) => {
			if (commentData) {
				comments.push(commentData);
				document.getElementById("commentInput").value = "";
				let textarea = document.querySelector("#commentInput");
				textarea.style.height = "4rem";
				setCommentExistOrNot(false);
			}
			setLoading(false);
		});
	};

	const onCommentAdded = (event) => {
		setComment(event.target.value);
		setCommentExistOrNot(event.target.value.trim() !== "");
	};

	return (
		<div className="comments-content">
			<div className="comments-content__list">
				{comments &&
					comments.length > 0 &&
					comments.map((comment) => (
						<div key={comment.id} className="comment">
							<div className="d-flex">
								<span className="comment__date mr-3">
									{comment.user.role === 'RECEPTIONIST' ? comment.user.firstName : comment.user.firstName + " " + comment.user.lastName}
								</span>
								<span className="comment__date">
									{Moment(comment.commentDate).format("DD/MM/YYYY / HH:mm")}
								</span>
							</div>
							<p className="comment__content">{comment.content}</p>
						</div>
					))}
			</div>
			{(isUserRadiographer || isUserReceptionist) && (
				<div className="comments-content__input">
					<FormGroup>
						<InputGroup>
							<Input
								id="commentInput"
								placeholder="Comments goes here"
								type="textarea"
								onChange={onCommentAdded}
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>
									{isLoading && (
										<Preloader
											use={TailSpin}
											size={32}
											strokeWidth={6}
											strokeColor="black"
											duration={2000}
										/>
									)}
									{!isLoading && (
										<IoIosSend
											size={32}
											className={commentExist ? "active" : ""}
											color={commentExist ? "#4FA7D3" : "#C9CED2"}
											onClick={commentExist ? saveComment : null}
										/>
									)}
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
				</div>
			)}
		</div>
	);
}
