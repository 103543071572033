export const updateReportForData = (data, report) => {
	const copy = data.slice();
	return copy.map(i => {
		if (i.visitId === report.visitId) {
			i.report = {
				reportContent: null,
				requestedReportDate: new Date()
			};
		}
		return i;
	});
};

export const updateScanForData = (data, { reportId, fileName }) => {
	const copy = data.slice();
	return copy.map(i => {
		if (i.visitId === reportId) {
			i.report.reportContentList = i.report.reportContentList.filter(report => report.file != fileName);
		}
		return i;
	});
};

export const updateScanForDataAfterUpload = (data, { visitId, scans }) => {
	const copy = data.slice();
	return copy.map(i => {
		if (i.visitId === visitId) {
			i.scans = [...i.scans, ...scans];
		}
		return i;
	});
};
