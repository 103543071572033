import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";
import PatientInfo from "../PatientInfo";
import ScanInfo from "../ScanInfo";
import Comments from "../Comments";
import ReportUploader from "../ReportUploader";
import { preparePricingData } from "../../../../services/pricing.service";
import "./index.css";

export default class VisitDetailsRadiologistPopUp extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentWillMount() {
    this.props.getBookingInfo(
      this.props.visitDetails.visitId || this.props.visitDetails.id
    );
  }

  render() {
    const {
      bookingInfo,
      visitDetails,
      uploadReport,
      isUserRadiologist,
      isUserRadiographer,
      deleteScan,
      onReportUpload,
      onUpdatedReport,
      requestReport,
      radiologists,
    } = this.props;
    let currentVisitDetails = { ...visitDetails };
    if (this.props.bookingInfo) {
      const bookingDetails = this.props.bookingInfo;

      currentVisitDetails = { ...bookingDetails, ...this.props.visitDetails };
      if (!visitDetails) {
        return <></>;
      }
    }

    return (
      bookingInfo &&
      currentVisitDetails && (
        <div
          className="ap-side-modal"
          id="visitDetails"
          style={{ display: "block" }}
        >
          <div
            className="ap-side-modal-backdrop"
            onClick={() => this.props.onClose()}
          />
          <div className="ap-side-modal-content">
            <PatientInfo
              visitDetails={currentVisitDetails}
              isUserRadiologist={isUserRadiologist}
            />
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Current Visit
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Comments
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <ScanInfo
                  visitDetails={currentVisitDetails}
                  preparedVisitDetails={preparePricingData(
                    currentVisitDetails,
                    radiologists,
                    currentVisitDetails.totalAmount
                  )}
                  isUserRadiographer={isUserRadiographer}
                  deleteScan={deleteScan}
                />
                <ReportUploader
                  requestReport={requestReport}
                  onUpdatedReport={onUpdatedReport}
                  uploadReport={uploadReport}
                  visitDetails={currentVisitDetails}
                  isUserRadiologist={isUserRadiologist}
                  onReportUpload={onReportUpload}
                  deleteScan={deleteScan}
                />
              </TabPane>
              <TabPane tabId="2">
                <Comments
                  isUserRadiographer={this.props.isUserRadiographer}
                  comments={this.props.bookingInfo.bookingComments}
                  addComment={this.props.addComment}
                  bookingId={this.props.bookingInfo.id}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      )
    );
  }
}
