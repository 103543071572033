import React, { useState, Fragment } from "react";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import { toastr } from "react-redux-toastr";
import moment from "moment";

import NewReportForm from "./Form";
import Modal from "../../../../common/modals/Modal";

import "./styles.css";

export default function NewReportPopup({
  userInfo,
  patients,
  scanPrices,
  close,
  orderReport,
  orderReportElswhere,
}) {
  const [patientScannetAt3Beam, setPatientScannedAt3Beam] = useState(true);
  const [selectedCreditCard, setSelectedCreditCard] = useState(false);
  const [showConfirmationPopup, toggleConfirmationPopup] = useState(false);

  const { title, firstName, lastName, city, address } = userInfo;

  const fullRadiologyReport = scanPrices.selectedExtraOptions.find(
    (scanPrice) => scanPrice.name === "e_frr_q"
  );

  const formik = useFormik({
    initialValues: {
      dentistId: userInfo.id,
      /** scanned at  3 Beam */
      patientId: null,
      /** scanned elsewhere */
      patientTitle: null,
      patientName: "",
      patientSurname: "",
      patientDOB: "",
      patientGender: "MALE",
      patientMobile: "",
      patientEmail: "",
      scanTakenDate: "",
      scanIndication: "",
      scan: null,
      /** END */
      paymentType: "InvoiceMe",
      notes: "",
      fullRadiologyReport: true,
      implandtPlanned: false,
      evaluateExisting: false,
      tmjEvaluation: false,
      sinusEvaluation: false,
      totalAmount: fullRadiologyReport.price,
    },
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      if (patientScannetAt3Beam) {
        orderReport(values)
          .then(() => {
            close();
          })
          .catch((e) => toastr.error("Error", e))
          .finally(() => setSubmitting(false));
      } else {
        orderReportElswhere(values)
          .then(() => {
            close();
          })
          .catch((e) => toastr.error("Error", e))
          .finally(() => setSubmitting(false));
      }
    },
    validate(values) {
      const errors = {};

      if (!values.fullRadiologyReport) {
        errors.fullRadiologyReport = "Full radiology report should be checked";
      }

      if (patientScannetAt3Beam && !values.patientId) {
        errors.patientId = "Patient should be selected";
      }

      if (selectedCreditCard && !userInfo.hasCard) {
        errors.paymentType = "You don't have creadit card";
      }

      /** patient is not scanne at 3 beam */
      if (!patientScannetAt3Beam && !values.scan) {
        errors.scan = "You should upload patient's scan";
      }

      if (!patientScannetAt3Beam && !values.patientTitle) {
        errors.patientTitle = "Patient's title is required";
      }

      if (!patientScannetAt3Beam && !values.patientName) {
        errors.patientName = "Patient's name is required";
      }

      if (!patientScannetAt3Beam && !values.patientSurname) {
        errors.patientSurname = "Patient's surname is required";
      }
      const patientDobMoment = moment(values.patientDOB);

      if (!patientScannetAt3Beam && !values.patientDOB) {
        errors.patientDOB = "Patient's date of birth is required";
      } else if (!patientScannetAt3Beam && !patientDobMoment.isValid()) {
        errors.patientDOB = "Patient's date of birth is not valid";
      }

      const scanTakenDateMoment = moment(values.scanTakenDate);

      if (!patientScannetAt3Beam && !values.scanTakenDate) {
        errors.scanTakenDate = "Scan date is required";
      } else if (!patientScannetAt3Beam && !scanTakenDateMoment.isValid()) {
        errors.scanTakenDate = "Scan date is not valid";
      }

      if (!patientScannetAt3Beam && !values.scanIndication) {
        errors.scanIndication = "Scan indication is required";
      }

      return errors;
    },
  });

  return (
    <Fragment>
      {showConfirmationPopup && (
        <Modal
          onDiscard={() => close()}
          onClose={() => toggleConfirmationPopup(false)}
        >
          Are you sure you want to discard your changes?
          <br />
          All of the progress will be lost.
        </Modal>
      )}
      <div
        className="ap-side-modal"
        id="newReport"
        style={{ display: "block" }}
      >
        <div
          className="ap-side-modal-backdrop"
          onClick={() => toggleConfirmationPopup(true)}
        />
        <div className="ap-side-modal-content">
          <div className="ap-side-modal-header">
            <div className="row align-items-center">
              <div className="col-sm-10">
                <h3>New report</h3>
              </div>
              <div className="col-sm-2">
                <Button style={{ fontSize: "24px" }} close onClick={close} />
              </div>
            </div>
          </div>

          <div className="new-report-form">
            <div className="row">
              <div className="col-sm-12">
                <div className="dentist-box">
                  <div className="dentist-box__title">
                    <h2>
                      {title}. {firstName} {lastName}
                    </h2>
                    <small>Dentist</small>
                  </div>
                  <div className="dentist-box__info">
                    <strong>Address</strong>
                    <address>
                      {city}, {address}
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <NewReportForm
            formik={formik}
            patientScannetAt3Beam={patientScannetAt3Beam}
            setPatientScannedAt3Beam={setPatientScannedAt3Beam}
            selectedCreditCard={selectedCreditCard}
            setSelectedCreditCard={setSelectedCreditCard}
            patients={patients}
            userInfo={userInfo}
            orderReport={orderReport}
            orderReportElswhere={orderReportElswhere}
          />

          <div className="ap-side-modal-footer">
            <div className="d-flex justify-content-between">
              <div>
                <span className="d-inline-block pr-2">Estimated price:</span>
                <span className="d-inline-block pr-4">
                  <strong className="text-large">
                    &pound; {fullRadiologyReport.price}
                  </strong>
                </span>
              </div>
              <Button
                disabled={!(formik.dirty && formik.isValid)}
                type="submit"
                form="newReportForm"
                color="primary"
              >
                {formik.isSubmitting ? "Sending..." : "Order"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
