import { createSelector } from "reselect";

const userProfileSelector = ({ profile }) => profile;

export const getUserProfileInfo = createSelector(
  userProfileSelector,
  ({ profileReducer }) => {
    return profileReducer.profile;
  }
);

export const getCreditCardInfo = createSelector(
  userProfileSelector,
  ({ creditCardReducer }) => creditCardReducer
);

export const getEditMode = createSelector(
  userProfileSelector,
  ({ profileReducer }) => {
    return profileReducer.editMode;
  }
);

export const getUserRole = createSelector(
  userProfileSelector,
  ({ profileReducer }) => {
    return profileReducer.profile.role;
  }
);

export const getLinkCode = createSelector(
  userProfileSelector,
  ({ profileReducer }) => {
    return profileReducer.profile.linkedCode;
  }
);

export const getHasLinkedAccounts = createSelector(
  userProfileSelector,
  ({ profileReducer }) => {
    return profileReducer.profile.hasLinkedAccounts;
  }
);

export const hasCreditCard = createSelector(
  [getUserProfileInfo],
  profile => profile.hasCard
);
