import React, { Fragment } from 'react';
import { Label, Row, Container, Col } from 'reactstrap';
import {
  renderGroupedScansForRadiographer,
  renderGroupedScans,
  filterCloudScan,
  filterScan,
} from '../../../../services/table.service';

export default function GroupedScansViewer({
  scans,
  isRadiographer,
  visitId,
  deleteScan,
}) {
  const renderCorrectScans = (functor) =>
    isRadiographer
      ? renderGroupedScansForRadiographer(functor(scans), deleteScan, visitId)
      : renderGroupedScans(functor(scans));

  return (
    <Fragment>
      <Container className="p-0">
        <Row>
          <Col sm="4">
            <Label>Radiographer</Label>
          </Col>
          <Col>
            <Label>Attached Scans</Label>
          </Col>
          <Col>
            <Label>Scan date</Label>
          </Col>
        </Row>
        <div>{renderCorrectScans(filterScan)}</div>
      </Container>
      <Container className="p-0">
        <Row>
          <Col sm="4">
            <Label>Radiographer</Label>
          </Col>
        </Row>
        <div>{renderCorrectScans(filterCloudScan)}</div>
      </Container>
    </Fragment>
  );
}
