import React, { useEffect, useState } from "react";
import axios from '../../axios-client'

export default function SurveyModal({
  completeSurvey
}) {
    const [surveyType, setSurveyType] = useState(0);
    const [surveys, setSurveys] = useState([])

    const changeSurveyType = (event) => {
        setSurveyType(event.target.value)
    }

    useEffect(() => {
      axios.get('/registration-surveys/retrieve-registration-survey-items').then(response => {
        setSurveys(response.data)
      })
    }, [])

  return (
    <div className="modal-dialog survey-modal" role="document">
      <div className="modal-content border-0">
        <div className="card border-primary mb-0">
          <div className="card-body">
              <p className="survey-details-item-name">Please, answer the question</p>
              <hr className="survey-details-horizontal-line" />
              <p className="survey-modal-question">How did you hear about us?</p>
              <hr />
        <form className="main-form__questions" onSubmit={e => completeSurvey(e, surveyType)}>
            <div onChange={event => changeSurveyType(event)}>
          {surveys.map(survey =>
              <div key={survey.id} className="d-block custom-control custom-radio custom-control-inline survey-details-block-item">
            <input
              type="radio"
              id={survey.name}
              name="survey"
              className="custom-control-input"
              value={survey.id}
            />
            <label className="custom-control-label" htmlFor={survey.name}>
              {survey.name}
            </label>
          </div>
        )}
          </div>
          <hr className="survey-details-horizontal-line" />
          <div className="d-flex justify-content-center main-form__actions survey-modal-complete-survey-block">
                <button className="btn btn-primary survey-modal-complete-survey" type="submit">Complete survey</button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
  );
}