import {
  FetchDataRoutine,
  SET_FILTERS,
  SET_INVOICES_YEARS
} from "../action-types";

const initialInvoicesState = {
  isFetching: false,
  invoices: [],
  invoicesYears: [],
  filters: { dentistId: -1, month: -1, year: -1 },
  error: null
};

export const invoicesListReducer = (state = initialInvoicesState, action) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        invoices: action.payload,
        error: null
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case SET_INVOICES_YEARS:
      return {
        ...state,
        invoicesYears: action.invoicesYears
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
