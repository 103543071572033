import React, { Component } from "react";
import { createPortal } from "react-dom";
import DiscardModal from "./DiscardModal";
import JoinModal from "./JoinModal";
import RequestModal from "./RequestModal";
import ReportModal from "./ReportModal";
import VoucherModal from "./VoucherModal";
import SurveyModal from "./SurveyModal";
import SurveyDetailsModal from "./SurveyDetailsModal";

const modalRoot = document.getElementById("modal-root");

class Modal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    switch (this.props.mode) {
      case "report":
        return createPortal(<ReportModal {...this.props} />, this.el);
      case "discard":
        return createPortal(<DiscardModal {...this.props} />, this.el);
      case "join":
        return createPortal(<JoinModal {...this.props} />, this.el);
      case "request":
        return createPortal(<RequestModal {...this.props} />, this.el);
      case "voucher":
        return createPortal(<VoucherModal {...this.props} />, this.el);
      case "survey":
        return createPortal(<SurveyModal {...this.props} />, this.el);
      case "surveyDetails":
        return createPortal(<SurveyDetailsModal {...this.props} />, this.el);
      // no default
    }
  }
}

Modal.defaultProps = {
  mode: "discard",
};

export default Modal;
