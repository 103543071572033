import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { FetchPastVisitsRoutine } from "./action-types";

export const retrievePreviousVisits = (patientId) => async (dispatch) => {
  dispatch(FetchPastVisitsRoutine.request());
  try {
    const { data } = await axios.get(
      `/visits/history/patient/${patientId}`,
      patientId
    );
    dispatch(FetchPastVisitsRoutine.success(data));
  } catch (e) {
    toastr.error("Error", e);
    dispatch(FetchPastVisitsRoutine.failure(e));
  }
};

export const addComment = (bookingId, comment) => async () => {
  try {
    const { data } = await axios.post(
      `/bookings/comment?bookingId=${bookingId}&comment=${comment}`
    );
    toastr.success("Success", "Comment successfully added.");
    return data;
  } catch (e) {
    toastr.error("Error", e);
  }
};
