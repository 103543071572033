import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import { dateFormatShort } from "../../constants/global.constants";
import moment from "moment";

const SingleDatePickerAdapter = ({ input }) => {
  const [focused, setFocused] = useState(false);
  return (
    <SingleDatePicker
      id="visit-date-input"
      focused={focused}
      firstDayOfWeek={1}
      displayFormat={() => dateFormatShort}
      numberOfMonths={1}
      small={true}
      date={input.value instanceof moment ? moment(input.value) : null}
      isOutsideRange={date => {
        return (
          date.isBefore(moment().startOf("day")) ||
          date.isoWeekday() === 7
        );
      }}
      hideKeyboardShortcutsPanel
      onDateChange={input.onChange}
      onFocusChange={e => {
        setFocused(e.focused);
      }}
      readOnly
    />
  );
};

export default SingleDatePickerAdapter;
