import { createSelector } from "reselect";

const dentistsSelector = ({ dentists }) => dentists;

export const getDentists = createSelector(
  dentistsSelector,
  ({ dentistsListReducer }) =>
    dentistsListReducer.data.reduce(
      (acc, cur) => [
        ...acc,
        { value: cur.id, label: `${cur.lastName} ${cur.firstName}`, id: cur.id, role: cur.role }
      ],
      []
    )
);

export const getDentistReports = createSelector(
  dentistsSelector,
  ({ dentistsListReducer }) =>
    dentistsListReducer.filters
);

export const getAllDentists = createSelector(
  dentistsSelector,
  ({ dentistsListReducer }) => {
    if(dentistsListReducer.filters.searchString) {
      return dentistsListReducer.data.filter(item => {
        if(item.lastName.toLowerCase().includes(dentistsListReducer.filters.searchString) ||
        item.firstName.toLowerCase().includes(dentistsListReducer.filters.searchString) ||
        item.email.toLowerCase().includes(dentistsListReducer.filters.searchString)){
          return item
        } 
    });
    } else {
      return dentistsListReducer.data
    }
  }
);
