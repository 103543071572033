import { useState } from "react";

const useModal = () => {
  const [showModal, toggleModalVisibility] = useState(false);
  const handleModalVisibilityToggle = () => toggleModalVisibility(!showModal);

  const onDiscard = () => {
    handleModalVisibilityToggle();
  };

  return {
    onDiscard,
    showModal,
    toggleModalVisibility,
    handleModalVisibilityToggle
  };
};

export default useModal;
