import {
  FetchDataRoutine,
  LINK_ACCOUNT,
  UNLINK_ACCOUNT
} from "../action-types";

const initialState = {
  isFetching: false,
  data: [],
  error: null
};

export const linkedAccountsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case LINK_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        data: [...state.data, action.payload],
        error: null
      };
    case UNLINK_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        data: [...state.data.filter(i => i.id !== action.id)],
        error: null
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
