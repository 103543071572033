import React from "react";
import Moment from "moment";
import { Form, Field } from "react-final-form";
import { Row, Col } from 'reactstrap'
import { dateFormat } from "../../constants/global.constants";
import { required } from "../../modules/profile/helpers";
import ReactSelectAdapter from "../../common/masks/ReactSelectAdapter";
import NoData from "../../common/NoData";
import {
  VOUCHER_AMOUNTS,
  VOUCHER_QUANTITIES
} from "../../constants/global.constants";

export default function VoucherModal({
  onClose,
  onGenerateVoucherCodes
}) {

  const generateVoucherCodes = (values) => {
    const { selectedAmount, selectedQuantity } = values;
    onGenerateVoucherCodes(selectedAmount.value, selectedQuantity.value);
    onClose();
  }

  return (
    <div className="modal-dialog" role="document" style={{ width: "500px" }}>
      <div className="modal-content border-0">
        <div className="card border-primary mb-0">
          <div className="card-header">
            Generate voucher codes
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <div>
              <NoData>Choose relevant data to get voucher codes</NoData>
            </div>
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit, values, invalid }) => (
                <form onSubmit={(values) => handleSubmit(values)}>
                  <Row className="d-flex align-items-center">
                    <Col sm="2">
                      <label className="mb-0">Amount:</label>
                    </Col>
                    <Col sm="10">
                      <Field
                        name="selectedAmount"
                        placeholder={"Select an amount"}
                        component={ReactSelectAdapter}
                        options={VOUCHER_AMOUNTS}
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center mt-4">
                    <Col sm="2">
                      <label className="mb-0">Quantity:</label>
                    </Col>
                    <Col sm="10">                      
                      <Field
                        name="selectedQuantity"
                        placeholder={"Select a quantity"}
                        component={ReactSelectAdapter}
                        options={VOUCHER_QUANTITIES}
                        validate={required}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm="12">
                      <button
                        style={{ width: "100%" }}
                        type="button"
                        className="btn btn-primary"
                        disabled={invalid}
                        onClick={() => generateVoucherCodes(values)}
                      >
                        Generate codes
                      </button>
                    </Col>
                  </Row>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
