import React from "react";
import { Row, Col, Card, CardTitle, CardText } from "reactstrap";
import { SUPPORTED_PAYMENTS } from "../../../../constants/global.constants";

const cardWith = {
  width: "200px"
};

export default function SupportedPayments() {
  return (
    <Row>
      <Col md="4">
        <Row>
          {SUPPORTED_PAYMENTS.map(payment => (
            <Col lg="4" key={payment.name} className="pb-4">
              <img src={payment.src} alt={payment.name} title={payment.name} />
            </Col>
          ))}
        </Row>
      </Col>
      <Col>
        <Card body style={cardWith}>
          <CardTitle>
            <strong>Supported Cards</strong>
          </CardTitle>
          <CardText>
            Users can accept Visa, Mastercard, American Express, Discover, JCB,
            Diners Club, China UnionPay credit and debit cards.
          </CardText>
        </Card>
      </Col>
    </Row>
  );
}
