import {
	SET_OCCUPIED_SLOTS,
	SET_OCCUPIED_SLOTS_DATE_RANGE,
	SET_TEMPORALLY_OCCUPIED_SLOTS,
	UPDATE_TEMPORALLY_OCCUPIED_SLOTS,
	SET_BLOCKED_SLOTS_DATE_RANGE
} from "../action-types";

const initialState = {
	occupiedSlots: [],
	occupiedSlotsDateRange: [],
	temporallyOccupiedSlots: [],
	blockedSlotsDateRange: []
};

const updateSlots = (temporallyOccupiedSlots, { dto, userId }) => {
	const copy = temporallyOccupiedSlots.slice();
	const index = [].findIndex(i => i.id === userId);
	return index
		? copy.splice(index, 1, { ...dto, id: userId })
		: [...copy, { ...dto, id: userId }];
};

export const occupiedSlotsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_OCCUPIED_SLOTS: {
			return {
				...state,
				occupiedSlots: action.slots
			};
		}
		case SET_OCCUPIED_SLOTS_DATE_RANGE: {
			return {
				...state,
				occupiedSlotsDateRange: action.slots
			};
		}
		case SET_TEMPORALLY_OCCUPIED_SLOTS: {
			return {
				...state,
				temporallyOccupiedSlots: action.slots
			};
		}
		case UPDATE_TEMPORALLY_OCCUPIED_SLOTS: {
			return {
				...state,
				temporallyOccupiedSlots: updateSlots(
					state.temporallyOccupiedSlots,
					action.payload
				)
			};
		}
		case SET_BLOCKED_SLOTS_DATE_RANGE: {
			return {
				...state,
				blockedSlotsDateRange: action.slots
			};
		}
		default:
			return state;
	}
};
