import React from "react";
import { Alert } from "reactstrap";

const detailsStyle = {
  padding: "5px",
  margin: "2px 0"
};

export default function VisitPayment({ isVisited, isDetailsView }) {
  return (
    <Alert
      color={!isVisited ? "warning" : "info"}
      style={isDetailsView && detailsStyle}
    >
      <i className={!isVisited ? "icon-info mr-1" : "icon-close mr-1"} />
      {isVisited ? (
        <span>Visit is paid by patient</span>
      ) : (
        <span>Visit should be paid by patient</span>
      )}
    </Alert>
  );
}
