import React, { useState, useRef } from "react";
import { Button } from "reactstrap";
import classNames from "classnames";

export default function UploadScan({
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
}) {
  const [selectedFile, setFile] = useState(null);
  const fileInputRef = useRef(null);

  function handleInputChange() {
    const file = fileInputRef.current.files[0];
    setFile(file);
    setFieldValue("scan", file);
    setFieldTouched("scan", true);
  }

  function removeFile() {
    setFile(null);
    fileInputRef.current.value = "";
    setFieldValue("scan", null);
  }

  return (
    <div className="row mt-3">
      <span className="col-sm-3 font-weight-bold">Upload scan:</span>
      <div className="col-sm-9">
        <div
          className={classNames("upload-scan-container mb-2", {
            "has-error": errors.scan && touched.scan,
          })}
        >
          <input
            ref={fileInputRef}
            type="file"
            name="scan"
            onChange={handleInputChange}
            onBlur={() => {
              setFieldTouched("scan", true);
            }}
          />
          <span>
            <i className="icon icon-cloud-download mr-2"></i>
            <span>Upload your files</span>
          </span>
        </div>
        {errors.scan && touched.scan ? (
          <small className="form-text text-danger">{errors.scan}</small>
        ) : (
          <small className="form-text text-muted">
            Please compress all patients files received from your imaging centre
            and upload directly
          </small>
        )}
        {selectedFile && (
          <span className="file-badge">
            <span className="file-info d-inline-block mr-2">
              {selectedFile.name} ({(selectedFile.size / 1024).toFixed(2)} Kb)
            </span>
            <Button
              type="button"
              onClick={removeFile}
              style={{ fontSize: "24px" }}
              close
            />
          </span>
        )}
      </div>
    </div>
  );
}
