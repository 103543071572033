import React, { Component, Fragment } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Moment from "moment";
import _ from "lodash";
import BookingsWithoutTime from "../BookingWithoutTime";
import BookingDetailsShort from "../BookingDetailsShort";
import moment from "moment";
import {
  backendDateFormat,
  bookingCreationFormat,
  recheckTimer,
  slotDuration,
} from "../../../../constants/global.constants";
import { businessHours, calendarConfig, slotLabelFormat } from "./config";
import "./styles.scss";
import CancelBookingModal from "../CancelBookingModal";
import BookScan from "../BookScan";
import CreateEditPatient from "../../../patient/components/CreateEditPatient";
import VisitDetailsPopUp from "../../../visit-details/components/VisitDetailsPopUp";
import axios from "../../../../axios-client";
import { toast } from "react-toastify";

class DashboardContainer extends Component {
  calendarComponentRef = React.createRef();

  state = {
    patientToEdit: null,
    editPatientPopUp: false,
    createPatientPopUp: false,
    showBookingPopup: false,
    patientInfo: null,
    showBookingPopup: false,
    boolTrue: true,
    calendarWeekends: true,
    calendarEvents: [
      // initial event data
      { title: "Event Now", start: new Date() },
    ],
    patientDetails: false,
    bookingDetailsPopupPosition: { x: 0, y: 0 },
    visitDetails: false,
    selectedDate: null,
    showModal: false,
    dateTitle: null,
    viewInfo: {},
    booking: null,
    loading: false,
    blockedDates: [],
    selectedPatientId: null,
  };

  handleLoading = (arg) => {
    this.setState({ loading: arg });
  };

    handlePatientCreated = (newPatientId) => {
        this.setState({ selectedPatientId: newPatientId });
    };

  handleEventClick = (arg) => {
    if (
      !arg.el.classList.contains("forbidden") &&
      !arg.el.classList.contains("blocked")
    ) {
      this.setState({ visitDetails: arg });
      this.setState({ showBookingDetailsShort: true });
      this.setState({
        bookingDetailsPopupPosition: {
          x: arg.jsEvent.pageX,
          y: arg.jsEvent.pageY,
        },
      });
    }
  };

  handleMouseMove(event, props) {
    // console.log(event);
    if (!event.target) {
      return false;
    }
    const tooltip = document.querySelector("#custom-tooltip");
    const fcContainer = document.querySelector(".fc-slats");

    const isOnForbiddenSlot = event.target.classList.contains("forbidden");
    const isOnBlockedSlot = event.target.classList.contains("blocked");
    const isOnActiveSlot = event.target.classList.contains("fc-widget-content");
    const isOnEvent = event.target.classList.contains("fc-event");
    const isOutsideGrid = !fcContainer.contains(event.target);

    tooltip.style.top = `${event.clientY + 20}px`;
    tooltip.style.left = `${event.clientX + 20}px`;

    if (isOnForbiddenSlot || (isOnBlockedSlot && !props.isUserReceptionist)) {
      tooltip.innerHTML = "Not available";
      tooltip.style.backgroundColor = 'rgb(243, 134, 134)';
      tooltip.style.display = "block";
    } else if (isOnBlockedSlot && props.isUserReceptionist) {
      tooltip.innerHTML = "Click to activate the slot";
      tooltip.style.display = "block";
    } else if (isOnActiveSlot && props.isUserDentist) {
      tooltip.innerHTML = "Click to create new booking";
      tooltip.style.display = "block";
      tooltip.style.backgroundColor = '#4dbd74';
    } else if (isOnActiveSlot && props.isUserReceptionist) {
      tooltip.innerHTML = "Click to block the slot";
      tooltip.style.backgroundColor = '#4dbd74';
      tooltip.style.display = "block";
    } else if (isOnEvent || isOutsideGrid) {
      tooltip.style.display = "none";
    }
  }

  handleDateClick = async (arg) => {
    // If user is Receptionist
    if (
      !arg.jsEvent.target.classList.contains("forbidden") &&
      this.props.isUserReceptionist
    ) {
      const req = await axios.post("/bookings/addOrDeleteOccupiedDate", {
        appointmentStartTime: Moment(arg.dateStr).format(bookingCreationFormat),
        appointmentEndTime: Moment(arg.dateStr)
          .add(15, "minutes")
          .format(bookingCreationFormat),
      });
      if (req.status === 200) {
        toast.success(req.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        this.blockedDates();
      } else {
        toast.error(req.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    // If user is Dentist
    if (arg.jsEvent.target.classList.contains("blocked")) {
      return false;
    }
    if (
      !arg.jsEvent.target.classList.contains("forbidden") &&
      this.canBookScan()
    ) {
      this.setState({
        selectedDate: arg.date,
        showBookingPopup: true,
      });
    }
  };

  componentDidMount() {
    let draggableEl = document.getElementsByClassName("ap-clients-list")[0];
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".ap-client-item",
        eventData: function (eventEl) {
          let title = eventEl.getAttribute("title");
          let id = eventEl.getAttribute("data");
          let tel = eventEl.getAttribute("tel");
          let doctor = eventEl.getAttribute("doctor");
          let comment = eventEl.getAttribute("comment");
          return {
            title: title,
            id: id,
            tel: tel,
            doctor: doctor,
            comment: comment,
          };
        },
      });
    }
    this.getDate();
    setTimeout(() => this.refreshData(), 500);
    this.timerID = setInterval(() => this.refreshData(), recheckTimer);
    document
      .getElementsByClassName("fc fc-ltr fc-unthemed")[0]
      .addEventListener("mousemove", (e) =>
        this.handleMouseMove(e, this.props)
      );
    document
      .getElementsByClassName("container-fluid")[0]
      .addEventListener("mouseover", (e) => {
        let tooltip = document.getElementById("custom-tooltip");
        if (tooltip.style.display == "block") {
          tooltip.style.display = "none";
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    document
      .getElementsByClassName("fc fc-ltr fc-unthemed")[0]
      .removeEventListener("mousemove", (e) =>
        this.handleMouseMove(e, this.props)
      );
  }

  refreshData() {
    let activeStart =
        this.calendarComponentRef.current.getApi().view.activeStart,
      activeEnd = this.calendarComponentRef.current.getApi().view.activeEnd;
    const {
      getAllBookingsWithoutDate,
      getBookingsByDateRange,
      fetchOccupiedSlotsByDateRange,
      fetchTemporallyOccupiedSlots,
    } = this.props;

    getAllBookingsWithoutDate();
    fetchTemporallyOccupiedSlots();

    if (activeStart && activeEnd) {
      getBookingsByDateRange(
        Moment(activeStart).format(backendDateFormat),
        Moment(activeEnd).format(backendDateFormat)
      );

      fetchOccupiedSlotsByDateRange(
        Moment(activeStart).format(backendDateFormat),
        Moment(activeEnd).format(backendDateFormat)
      );
    }

    // if (this.calendarComponentRef && this.calendarComponentRef.current) {
    //   const calendarApi = this.calendarComponentRef.current.getApi();

    //   setTimeout(() => {
    //     calendarApi.refetchEvents();
    //   }, 500);
    // }
  }

  async handleBookingUpdate(bookingDetails) {
    const id = bookingDetails.oldEvent.id;
    const { start, end } = bookingDetails.event;
    await this.props.updateBooking({
      id,
      appointmentStartTime: Moment(start).format(bookingCreationFormat),
      appointmentEndTime: Moment(end).format(bookingCreationFormat),
    });
    this.refreshData();
  }

  async handleDrop(dropInfo) {
    const id = dropInfo.draggedEl.id;
    const { bookingsWithoutDate } = this.props;
    const draggedItem = bookingsWithoutDate.find(
      (item) => item.id.toString() === id
    );
    const dateObj = moment(dropInfo.event.start);

    await this.props.assignVisitTime({
      id: draggedItem.id,
      appointmentStartTime: dateObj.local().format(bookingCreationFormat),
      appointmentEndTime: dateObj
        .local()
        .add(Number(slotDuration), "minutes")
        .format(bookingCreationFormat),
    });
    const calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.refetchEvents();
    dropInfo.event.remove();
    this.refreshData();
  }

  goNextWeek() {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.next();
    this.getDate();
    this.refreshData();
    this.blockedDates();
  }

  goPrevWeek() {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.prev();
    this.getDate();
    this.refreshData();
    this.blockedDates();
  }

  goToday() {
    let calendarApi = this.calendarComponentRef.current.getApi();
    calendarApi.today();
    this.getDate();
    this.refreshData();
  }

  async blockedDates() {
    let { currentDate } = this.calendarComponentRef.current.calendar.state;

    let fromDate = Moment(currentDate).format("Y-MM-DD");
    let toDate = Moment(currentDate).add(6, "days").format("Y-MM-DD");
    let list = await this.props.getBlockedDatesList(fromDate, toDate);
    this.setState({
      ...this.state,
      blockedDates: list,
    });
  }

  getDate() {
    this.blockedDates();
    let current = this.calendarComponentRef.current;
    if (current) {
      let calendarApi = current.getApi().view;
      this.setState({ dateTitle: calendarApi.title });
    }
  }

  canBookScanOrUpdateBooking() {
    return this.props.isUserDentist || this.props.isUserReceptionist;
  }

  canBookScan() {
    return this.props.isUserDentist;
  }

  renderHeaderDate(date) {
    let currentDateObj = Moment();
    let dateObj = Moment(date);
    let highlightedClass = "";
    if (currentDateObj.format("DD MM YYYY") === dateObj.format("DD MM YYYY")) {
      highlightedClass = `className="highlighted"`;
    }

    return `
      <span ${highlightedClass}>
        ${dateObj.format("ddd")}
      </span>
      <span ${highlightedClass}>
        ${dateObj.format("D")}
      </span>
    `;
  }

  markAsVisited = async (bookingId) => {
    await this.props.updateVisit(bookingId);
    this.setState({ showBookingDetailsShort: false });
    this.setState({ loading: false });
    this.refreshData();
  };

   markAsUnvisited = async (bookingId) => {
        await this.props.updateVisitUnvisited(bookingId);
        this.setState({ showBookingDetailsShort: false });
        this.setState({ loading: false });
        this.refreshData();
   };

  canStartDrag = (_, e) => {
    const id = +e.id;
    return !this.props.bookings.filter((i) => i.visited && i.id === id).length;
  };

  generateDisabledSlots(info) {
    const startDate = Moment(info.start);
    const endDate = Moment(info.end);
    // const sunday = endDate.subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss");
    const saturday = endDate.subtract(1, "days").format("YYYY-MM-DDTHH:mm:ss");

    const disabledSlotConfig = {
      rendering: "background",
      backgroundColor: "#d1dbe1",
      editable: false,
      overlap: false,
      classNames: ["forbidden"],
    };

    const blockedSlotConfig = {
      rendering: "background",
      backgroundColor: "rgb(243, 134, 134)",
      editable: false,
      overlap: false,
      classNames: ["blocked"],
    };

    const disabledSlotsArray = [
      //   {
      //     ...disabledSlotConfig,
      //     allDay: true,
      //     start: sunday
      //   },
      {
        ...disabledSlotConfig,
        allDay: true,
        start: saturday,
      },
    ];

    for (let i = 0; i < 6; i++) {
      startDate.add(!i ? 0 : 1, "days");

      if (i === 5) {
        disabledSlotsArray.push({
          ...disabledSlotConfig,
          start: startDate.format("YYYY-MM-DDT") + "08:00:00",
          end: startDate.format("YYYY-MM-DDT") + "09:00:00",
        });
        disabledSlotsArray.push({
          ...disabledSlotConfig,
          start: startDate.format("YYYY-MM-DDT") + "16:00:00",
          end: startDate.format("YYYY-MM-DDT") + "20:00:00",
        });
      } else {
        disabledSlotsArray.push({
          ...disabledSlotConfig,
          start: startDate.format("YYYY-MM-DDT") + "08:00:00",
          end: startDate.format("YYYY-MM-DDT") + "10:00:00",
        });

        disabledSlotsArray.push({
          ...disabledSlotConfig,
          start: startDate.format("YYYY-MM-DDT") + "17:00:00",
          end: startDate.format("YYYY-MM-DDT") + "20:00:00",
        });
      }
    }

    for (let occupiedSlot of [
      // ...this.props.occupiedSlots,
      ...this.props.temporallyOccupiedSlots,
      ...this.props.occupiedSlotsTimeRange,
    ]) {
      disabledSlotsArray.push({
        ...disabledSlotConfig,
        end: occupiedSlot.appointmentEndTime,
        start: occupiedSlot.appointmentStartTime,
      });
    }

    for (let blockedSlot of [...this.state.blockedDates]) {
      disabledSlotsArray.push({
        ...blockedSlotConfig,
        end: blockedSlot.appointmentEndTime,
        start: blockedSlot.appointmentStartTime,
      });
    }

    return disabledSlotsArray;
  }

  getEvents(info, successCallback) {
    const disabledSlots = this.generateDisabledSlots(info);
    return successCallback(disabledSlots.concat(this.props.bookings));
  }

  handleEventRender(calEvent, elem, view) {
    if (calEvent.el) {
      if (
        !calEvent.event.end ||
        (calEvent.event.end && Moment(calEvent.event.end).isAfter(Moment()))
      ) {
        calEvent.el.classList.add("mod-feature");
      }
      if (Moment().diff(calEvent.event.end, "minutes") > 0) {
        calEvent.el.classList.add("mod-passed");
        if (!calEvent.event.extendedProps.visited) {
          calEvent.el.classList.add("mod-not-visited");
        } else {
          calEvent.el.classList.add("mod-visited");
        }
      }
      if (window.activeEvent) {
        if (window.activeEvent.id === calEvent.event.id) {
          calEvent.el.classList.add("mod-active");
        }
      }
      if (calEvent.el.isLocked) {
        calEvent.el.classList.add("mod-locked");
      }
    }
  }

  cancelEvent(patientInfo) {
    this.setState({ patientInfo: patientInfo });
  }

  addBookingToDraft = async (info) => {
    const bookingListWithoutDate = document.getElementById("external-events");
    const { x, y, width, height } =
      bookingListWithoutDate.getBoundingClientRect();
    const droppedClientX = info.jsEvent.clientX;
    const droppedClientY = info.jsEvent.clientY;
    if (
      droppedClientX >= x &&
      droppedClientX <= x + width &&
      droppedClientY >= y &&
      droppedClientY <= y + height
    ) {
      const id = info.event.id;
      await this.props.unAssignVisitTime(id);
      this.refreshData();
    }
  };

  render() {
    return (
      <Fragment>
        <span id="custom-tooltip" className="custom-tooltip"></span>

        <div className="app-body aside-menu-lg-show">
          <main className="main bg-white">
            <div className="container-fluid">
              <div className="py-4 ap-headline row align-items-center">
                <div className="col-sm-4">
                  <h3 className="m-0">Bookings</h3>
                </div>
                <div className="col-sm-4 text-center">
                  <h3 className="ap-header-calendar-date m-0">
                    <b>{this.state.dateTitle}</b>
                  </h3>
                </div>
                <div className="col-sm-4 text-right">
                  <button
                    className="btn btn-light ap-header-calendar-prev"
                    onClick={this.goPrevWeek.bind(this)}
                  >
                    <i className="icon-arrow-left" />
                  </button>
                  <button
                    className="btn btn-light ap-header-calendar-next"
                    onClick={this.goNextWeek.bind(this)}
                  >
                    <i className="icon-arrow-right" />
                  </button>
                  <button
                    className="btn btn-light ap-header-calendar-today"
                    onClick={this.goToday.bind(this)}
                  >
                    Today
                  </button>
                  {this.canBookScan() && (
                    <button
                      className="btn btn-primary"
                      data-toggle="side-modal"
                      data-target="#createNewBooking"
                      onClick={() => this.setState({ showBookingPopup: true })}
                    >
                      Book scan
                    </button>
                  )}
                </div>
              </div>
              <FullCalendar
                className="ap-calendar mod-header-btn"
                defaultView="timeGridWeek"
                header={{
                  left: "",
                  center: "",
                  right: "",
                }}
                columnHeaderHtml={(date) => this.renderHeaderDate(date)}
                nowIndicator={this.state.boolTrue}
                plugins={[timeGridPlugin, interactionPlugin]}
                allDaySlot={!this.state.boolTrue}
                events={(info, successCallback, failureCallback) =>
                  this.getEvents(info, successCallback, failureCallback)
                }
                eventAllow={this.canStartDrag.bind(this)}
                editable={this.canBookScanOrUpdateBooking()}
                eventReceive={(dropInfo) => this.handleDrop(dropInfo)}
                droppable={this.state.boolTrue}
                eventDurationEditable={!this.state.boolTrue}
                {...calendarConfig}
                slotLabelFormat={slotLabelFormat}
                eventOverlap={false}
                themeSystem="standard"
                eventClick={this.handleEventClick}
                eventConstraint={businessHours}
                dateClick={this.handleDateClick}
                ref={this.calendarComponentRef}
                eventRender={this.handleEventRender.bind(this)}
                eventDrop={this.handleBookingUpdate.bind(this)}
                eventDragStop={(info) => this.addBookingToDraft(info)}
              />
            </div>
          </main>
          {!this.props.isUserRadiographer && (
            <BookingsWithoutTime
              bookingsWithoutDate={this.props.bookingsWithoutDate}
              onShowDetails={(data) => {
                this.props.onHandleBookingWithoutDateClick(data);
                this.setState({ booking: data });
              }}
            />
          )}
        </div>

        <div className="ap-event-restore card bg-danger">
          <div className="card-body">
            <div className="row">
              <div className="col-10">
                <span className="ap-event-restore__text" /> has been deleted.
              </div>
              <div className="col-2 text-right">
                <span className="ap-event-restore-link link-span">Undo</span>
              </div>
            </div>
          </div>
        </div>
        {this.state.showBookingDetailsShort && (
          <BookingDetailsShort
            visitDetails={this.state.visitDetails}
            detailsPopupPosition={this.state.bookingDetailsPopupPosition}
            onRemove={(patientInfo) => {
              this.cancelEvent(patientInfo);
            }}
            onClose={() => this.setState({ showBookingDetailsShort: false })}
            showVisitDetails={() => {
              this.props.onViewBookingDetailsClicked(
                this.state.visitDetails.event
              );
              this.setState({
                showBookingDetailsShort: false,
                booking: this.state.visitDetails.event,
              });
            }}
            markAsVisited={() =>
              this.markAsVisited(this.state.visitDetails.event.id)
            }
            markAsUnvisited={() =>
                this.markAsUnvisited(this.state.visitDetails.event.id)
            }
            isUserReceptionist={this.props.isUserReceptionist}
            isUserDentist={this.props.isUserDentist}
            loading={this.state.loading}
            handleLoading={this.handleLoading}
          />
        )}
        {this.state.patientInfo && (
          <CancelBookingModal
            patientInfo={this.state.patientInfo}
            closeCancelBooking={() => {
              this.setState({ patientInfo: null, booking: null });
            }}
            deleteBooking={async (id) => {
              await this.props.deleteBooking(id);
              this.refreshData();
            }}
          />
        )}
        {this.state.showBookingPopup && (
          <BookScan
            selectedDate={this.state.selectedDate}
            scanPricesForPayment={this.props.scanPricesForPayment}
            bookings={this.props.bookings}
            userProfile={this.props.userProfile}
            patientsArray={this.props.patientsArray}
            fetchTemporallyOccupiedSlots={
              this.props.fetchTemporallyOccupiedSlots
            }
            getAllPatients={this.props.getAllPatients}
            updateTemporallyOccupiedSlots={
              this.props.updateTemporallyOccupiedSlots
            }
            temporallyOccupiedSlots={this.props.temporallyOccupiedSlots}
            blockedSlotsDateRange={this.props.blockedSlotsDateRange}
            occupiedSlotsTimeRange={this.props.occupiedSlotsTimeRange}
            fetchOccupiedSlotsByDateRange={
              this.props.fetchOccupiedSlotsByDateRange
            }
            occupiedSlots={this.props.occupiedSlots}
            getScanPrices={this.props.getScanPrices}
            getScanPricesForBooking={this.props.getScanPricesForBooking}
            fetchRadiologists={this.props.fetchRadiologists}
            radiologists={this.props.radiologists}
            scanPrices={this.props.scanPrices}
            createBooking={this.props.createBooking}
            hasCreditCard={this.props.hasCreditCard}
            selectedPaymentType={this.props.selectedPaymentType}
            getLastSelectedPaymentType={this.props.getLastSelectedPaymentType}
            checkFirstCBCTIsOrNot={this.props.checkFirstCBCTIsOrNot}
            isUserDentist={this.props.isUserDentist}
            selectedPatientId={this.state.selectedPatientId}
            openCreatePatient={() => {
              this.setState({ createPatientPopUp: true });
            }}
            onClose={() => {
              this.setState({ showBookingPopup: false, selectedDate: null, selectedPatientId: null });
            }}
          />
        )}
        {this.state.createPatientPopUp && (
          <CreateEditPatient
            closeCreatePatient={() => {
              this.setState({ createPatientPopUp: false });
            }}
            createPatient={this.props.createPatient}
            onPatientCreated={this.handlePatientCreated}
          />
        )}
        {this.state.booking && (
          <VisitDetailsPopUp
            visitDetails={this.state.booking}
            getBookingInfo={this.props.getBookingInfo}
            userProfile={this.props.userProfile}
            bookings={this.props.bookings}
            retrievePreviousVisits={this.props.retrievePreviousVisits}
            fetchOccupiedSlotsByDateRange={
              this.props.fetchOccupiedSlotsByDateRange
            }
            updateTemporallyOccupiedSlots={
              this.props.updateTemporallyOccupiedSlots
            }
            bookingInfo={this.props.bookingInfo}
            visitsByPatient={this.props.visitsByPatient}
            fetchRadiologists={this.props.fetchRadiologists}
            radiologists={this.props.radiologists}
            occupiedSlotsTimeRange={this.props.occupiedSlotsTimeRange}
            temporallyOccupiedSlots={this.props.temporallyOccupiedSlots}
            occupiedSlots={this.props.occupiedSlots}
            updateVisit={async (e) => {
              await this.props.updateVisit(e);
              this.setState({ booking: null });
              this.refreshData();
            }}
            updateVisitUnvisited={async (e) => {
                await this.props.updateVisitUnvisited(e);
                this.setState({ booking: null });
                this.refreshData();
            }}
            updateBooking={async (patient) => {
              await this.props.updateBooking(patient);
              this.refreshData();
            }}
            isUserRadiologist={this.props.isUserRadiologist}
            isUserDentist={this.props.isUserDentist}
            isUserReceptionist={this.props.isUserReceptionist}
            isUserRadiographer={this.props.isUserRadiographer}
            type="booking"
            onClose={() => this.setState({ booking: null })}
            cancelBooking={(data) => {
              this.setState({ patientInfo: data });
            }}
            onPatientEdit={(patientToEdit) => {
              this.setState({
                editPatientPopUp: true,
                patientToEdit: patientToEdit,
              });
            }}
            addComment={this.props.addComment}
          />
        )}
        {this.state.editPatientPopUp && (
          <CreateEditPatient
            closeCreatePatient={() => {
              this.setState({ editPatientPopUp: false });
            }}
            mode="edit"
            patient={this.state.patientToEdit}
            editPatient={async (patientToEdit) => {
              await this.props.handlePatientEdit(patientToEdit);
              this.refreshData();
            }}
          />
        )}
      </Fragment>
    );
  }
}

export default DashboardContainer;
