import { FetchPatientsSatisfactionRoutine, SET_PATIENTS_SATISFACTION_REPORT_FILTERS } from "../action-types";
import { backendDateFormat } from "../../../constants/global.constants";
import moment from "moment";

const initialState = {
  isFetching: false,
  data: [],
  filters: {
    startDate: moment()
      .startOf("month")
      .format(backendDateFormat),
    endDate: moment().format(backendDateFormat)
  },
  error: null
};

export const patientsSatisfactionReportsListReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FetchPatientsSatisfactionRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case SET_PATIENTS_SATISFACTION_REPORT_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case FetchPatientsSatisfactionRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchPatientsSatisfactionRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
