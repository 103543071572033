export const GENDERS = Object.freeze([
  "MALE",
  "FEMALE",
  "OTHER"
]);

export const TITLES = Object.freeze([
  "MR",
  "MRS",
  "MISS",
  "MS",
  "DR",
  "PROF",
  "LADY",
  "LORD"
]);

export const INITIAL_FORM_STATE = Object.freeze({
  address: "",
  city: "",
  email: "",
  surname: "",
  forename: "",
  mobile: "",
  postcode: "",
  dob: "",
  telephoneNumber: "",
  title: ""
});
