import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { FetchDataRoutine, SET_FILTERS } from "./action-types";
import { API_CONSTANTS } from "./constants";

export const fetchRadiologyReports = ({page = 1, size = 10, criteria, startDate, endDate, orderBy = 'orderReportDate', desc = true} = {}) => async (dispatch) => {
  dispatch(FetchDataRoutine.request());
  try {
    const { data } = await axios.get(API_CONSTANTS.GET_RADIOLOGY_REPORTS, {params: {page, size, criteria, startDate, endDate, orderBy, desc}});
    dispatch(FetchDataRoutine.success(data));
  } catch (e) {
    toastr.error("Error", e);
    dispatch(FetchDataRoutine.failure(e));
  }
};

export const orderReport = (order) => (dispatch) => {
  return axios.post(API_CONSTANTS.ORDER_REPORT, order).then(() => {
    dispatch(fetchRadiologyReports());
    toastr.success("Success", "report ordered");
  });
};

export const orderReportElswhere = (order) => (dispatch) => {
  const formData = new FormData();
  Object.keys(order).forEach((key) => {
    formData.append(key, order[key]);
  });

  return axios
    .post(API_CONSTANTS.ORDER_REPORT_ESLEWHERE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      toastr.success("Success", "report ordered");
      dispatch(fetchRadiologyReports());
    });
};

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters,
});
