import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styled from "styled-components";
import { saveLocalStoreData } from "../../../../services/localStorage.service";
import { LOCAL_STORAGE_CONSTANTS } from "../../../../constants/localStorage.constants";
import { impersonateDentist } from "../../../../services/api";
import { ImpersonatingService } from "../../../../services/impersonating.service";

const StyledSelect = styled(Select)`
  position: absolute !important;
  width: 200px;
  top: 10px;
  z-index: 999;
`;

const { getPreselectedUser, isImpersonating } = ImpersonatingService();

const ImpersonateDentist = ({ fetchDentists, dentists, history }) => {
  const [selectedOption, setSelected] = useState(getPreselectedUser);
  const [selectVisible, setSelectVisibility] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSelectVisibility(false);
    }
  };

  useEffect(() => {
    !isImpersonating && fetchDentists && fetchDentists(); // for now not to brake dashboard
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [fetchDentists]);

  const actAs = () => {
    if (isImpersonating) return null;
    return selectedOption.label ? (
      <span>Acting as {selectedOption.label}</span>
    ) : (
      <span>Act as...</span>
    );
  };

  return (
    <li ref={ref}>
      <button
        className="btn btn-link"
        onClick={() => {
          setSelectVisibility(!selectVisible);
        }}
      >
        {actAs()}
      </button>
      {selectVisible && (
        <StyledSelect
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          value={selectedOption}
          placeholder="Select user"
          onChange={(e) => {
            setSelected(e);
            saveLocalStoreData(LOCAL_STORAGE_CONSTANTS.ACTING_AS, e);
            setSelectVisibility(false);
            impersonateDentist(e.id, history);
          }}
          options={dentists}
        />
      )}
    </li>
  );
};

export default ImpersonateDentist;
