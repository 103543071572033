import React, { useState } from "react";
import ProfileForm from "../ProfileForm";
import Modal from "../../../../common/modals/Modal";

export default function PersonalInformationEdit({
  children,
  userProfile,
  updateUserInfo,
  toggleEditMode,
  isUserDentist
}) {

  const [showModal, toggleModalVisibility] = useState(false);
  const handleModalVisibilityToggle = () => toggleModalVisibility(!showModal);
  const onDiscard = () => {
    handleModalVisibilityToggle();
    toggleEditMode();
  };
  return (
    <div id="personal-info" aria-labelledby="personal-info-tab">
      <div className="row ap-info-editor">
        <div className="ap-col-fixed-450">
          {children}
          <div className="pt-4">
            <ProfileForm
              mode="edit"
              onSubmit={updateUserInfo}
              onDiscard={e => {
                e.preventDefault();
                handleModalVisibilityToggle();
              }}
              userProfile={userProfile}
              isUserDentist={isUserDentist}
            />
            {showModal && (
              <Modal
                onDiscard={onDiscard}
                onClose={handleModalVisibilityToggle}
              >
                Are you sure you want to discard your changes?
                <br />
                All of the progress will be lost.
              </Modal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
