import { FetchAuditRegistrationRoutine  } from "../action-types";
import { backendDateFormat } from "../../../constants/global.constants";
import moment from "moment";

const initialAuditReportState = {
  isFetching: false,
  data: [],
  filters: {
    startDate: moment()
      .startOf("month")
      .format(backendDateFormat),
    endDate: moment().format(backendDateFormat)
  },
  error: null
};

export const auditRegistrationListReducer = (
  state = initialAuditReportState,
  action
) => {
  switch (action.type) {
    case FetchAuditRegistrationRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.map(i => ({...i, dentist: ''})),
        error: null
      };
    case FetchAuditRegistrationRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchAuditRegistrationRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
