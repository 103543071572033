import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { composeValidators, required, isValidPassword } from "../../helpers";
import classNames from "classnames";

export default function ChangePassword({ onSubmit }) {
  const [hidePassword, toggleHidePassword] = useState(true);
  const [hideNewPassword, toggleHideNewPassword] = useState(true);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, values }) => (
        <div className="row padding-1-rem">
          <div className="ap-col-fixed-450">
            <h2>Change Password</h2>
            <form className="pt-4">
              <Field
                name="oldPassword"
                validate={composeValidators(required, isValidPassword)}
                render={({ input, meta }) => (
                  <div className="form-group mod-switch row">
                    <label className="col-sm-4 col-form-label">
                      Current Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        {...input}
                        type={hidePassword ? "password" : "text"}
                        data-type-switch="text"
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error
                        })}
                        placeholder="Create your password"
                      />
                      <span
                        className="show-hide-password"
                        onClick={e => {
                          e.preventDefault();
                          toggleHidePassword(!hidePassword);
                        }}
                      >
                        {hidePassword ? "Show" : "Hide"}
                      </span>
                    </div>
                  </div>
                )}
              />
              <Field
                name="newPassword"
                validate={composeValidators(required, isValidPassword)}
                render={({ input, meta }) => (
                  <div className="form-group mod-switch row">
                    <label className="col-sm-4 col-form-label">
                      New Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        {...input}
                        type={hideNewPassword ? "password" : "text"}
                        data-type-switch="text"
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error
                        })}
                        placeholder="Create your password"
                      />
                      <span
                        className="show-hide-password"
                        onClick={e => {
                          e.preventDefault();
                          toggleHideNewPassword(!hideNewPassword);
                        }}
                      >
                        {hideNewPassword ? "Show" : "Hide"}
                      </span>
                      <small className="form-text text-muted">
                        Password should contain at least 6 characters, at least
                        one capital letter and a number.
                      </small>
                    </div>
                  </div>
                )}
              />
              <div className="form-group row pt-4">
                <div className="col-sm-8 offset-sm-4">
                  <button
                    className="btn btn-primary"
                    onClick={e => {
                      e.preventDefault();
                      handleSubmit(values);
                    }}
                    disabled={invalid}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    />
  );
}
