import React from "react";

export default function DiscardModal({
  onDiscard,
  onClose,
  children,
  title = "Unsaved Changes",
  confirmBtn = "Discard"
}) {
  return (
    <div className="modal-dialog">
      <div className="modal-content border-0">
        <div className="card border-danger mb-0">
          <div className="card-header bg-danger">
            {title}
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <p className="card-text">{children}</p>
          </div>
          <div className="card-footer text-right">
            <button
              className="btn btn-secondary mr-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger ap-discard-changes"
              onClick={onDiscard}
            >
              {confirmBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
