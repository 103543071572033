import React from "react";
import Moment from "moment";
import VisitedLabel from "../../../visit-details/components/VisitedLabel";
import VisitPayment from "../../../visit-details/components/VisitPayment";
import {
	presentationDateFormat,
	slotDuration,
} from "../../../../constants/global.constants";
import Modal from "../../../../common/modals/Modal";
import useModal from "../../../../hooks/useModal";
import "./styles.scss";
// import { createPostDICOMOrder } from "../../../../plugins/postdicom/util";

export default function BookingDetailsShort({
	visitDetails,
	isUserReceptionist,
	isUserDentist,
	detailsPopupPosition,
	onRemove,
	showVisitDetails,
	markAsVisited,
    markAsUnvisited,
	onClose,
	loading,
	handleLoading
}) {
	const { showModal, handleModalVisibilityToggle } = useModal();
	const isVisited = visitDetails.event.extendedProps.visited;
	const isPaidByPatient = visitDetails.event.extendedProps.paidByPatient;

	const canCancel = () => {
		return (isUserReceptionist || isUserDentist) && !isVisited;
	};
	const patientName = visitDetails.event.extendedProps.patient.name;
	const patientDate =
		Moment(visitDetails.event.start).format(presentationDateFormat) +
		" – " +
		Moment(visitDetails.event.start)
			.add(Number(slotDuration), "minutes")
			.format("h:mm a");

	const bookingId = visitDetails.event.id;

	function renderStatus(status) {
		let className = "text-warning",
			text = "Not filled out";

		if (status === 1) {
			// negative
			className = "text-success";
			text = "Negative";
		} else if (status === 2) {
			// positive
			className = "text-danger";
			text = "Positive";
		}

		return <span className={`font-weight-bold ${className}`}>{text}</span>;
	}

	return (
		<div
			className="ap-event"
			style={{
				top: detailsPopupPosition.y,
				left: detailsPopupPosition.x,
				display: "block",
			}}
		>
			<div className="border-secondary card">
				<div className="card-header">
					<h4 className="ap-event__name">{visitDetails.event.title}</h4>
					{canCancel() && (
						<span
							href="#removeEvent"
							className="ap-event__trash icon-trash"
							data-toggle="modal"
							onClick={() =>
								onRemove({
									name: patientName,
									date: patientDate,
									id: bookingId,
								})
							}
						/>
					)}
					<a className="ap-event__close" onClick={() => onClose()}>
						&times;
					</a>
				</div>
				<div className="card-body">
					<div className="ap-patient-details">
						{visitDetails.event.extendedProps.tel && (
							<p>
								<i className="icon-phone" />
								&nbsp; {visitDetails.event.extendedProps.tel}
							</p>
						)}
						<p>
							<i className="icon-calendar" />
							&nbsp;
							<span className="ap-event__date">{patientDate}</span>
						</p>
						<p>
							<i className="icon-user" />
							&nbsp;{" "}
							<span className="ap-event__doc">
								{visitDetails.event.extendedProps.doctor}
							</span>
						</p>
						<p>
							<i className="icon-chemistry" />
							&nbsp;{" "}
							<span className="ap-event__doc">
								COVID-19 survey result:{" "}
								{renderStatus(
									visitDetails.event.extendedProps.selectedCovidStatus
								)}
							</span>
						</p>
						{isPaidByPatient && (isUserReceptionist || isUserDentist) && (
							<div className="mt-2">
								<VisitPayment isVisited={isVisited} />
							</div>
						)}
					</div>
				</div>
				<div className="card-footer">
					<div className="row">
						<div className="col flex-grow-2 ap-event__details">
							<button
								className="btn btn-link btn-block text-uppercase"
								data-toggle="side-modal"
								data-target="#visitDetails"
								onClick={() => showVisitDetails()}
							>
								View details
							</button>
						</div>
						{!isUserReceptionist && !visitDetails.event.extendedProps.visited && (
							<div className="col flex-grow-2 ap-event__details mt-2">
								<VisitedLabel isVisited={isVisited} />
							</div>
						)}
						{!visitDetails.event.extendedProps.visited && isUserReceptionist && (
							<div className="col flex-grow-2 ap-event__mark">
								<button
									className="btn btn-outline-dark btn-block"
									data-toggle="modal"
									title="Cannot mark as visited future bookings"
									disabled={Moment(
										visitDetails.event.extendedProps.appointmentEndTime
									).isAfter(Moment().endOf("day")) || loading}
									data-target="#markAsVisited"
									onClick={() => {
										handleLoading(true)
										// create patient order in PostDICOM asynchronously
										// createPostDICOMOrder({
										//   id: +visitDetails.event.id,
										//   ...visitDetails.event.extendedProps,
										// });
										isPaidByPatient
											? (function () { handleModalVisibilityToggle(); handleLoading(false) })()
											: markAsVisited(bookingId);
									}}
								>
									Mark as Visited
								</button>
							</div>
						)}

                        {visitDetails.event.extendedProps.visited && (
                            <div className="col flex-grow-2 ap-event__mark">
                                <button
                                    disabled=""
                                    className="btn btn-link btn-block text-center"
                                >
                                    <i className="icon-check text-success" /> Visited
                                </button>
                            </div>
                        )}
                        {visitDetails.event.extendedProps.visited && isUserReceptionist && (
                            <div className="col flex-grow-3 ap-event__mark">
                                        <button
                                            className="btn btn-outline-danger btn-block"
                                            data-toggle="modal"
                                            disabled={loading}
                                            onClick={() => {
                                                handleLoading(true);
                                                markAsUnvisited(bookingId);
                                                handleLoading(false);
                                            }}
                                        >Return to unvisited
                                        </button>
                            </div>
                        )}
					</div>
				</div>
			</div>
			{showModal && (
				<Modal
					onDiscard={markAsVisited}
					onClose={handleModalVisibilityToggle}
					confirmBtn="Mark as visited"
					title="Patient Payment"
				>
					By clicking <b>Mark as visited</b>
					<br /> I confirm that patient payment was successful
				</Modal>
			)}
		</div>
	);
}
