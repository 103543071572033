import _ from "lodash";
import {
  GET_ALL_BOOKINGS_FOR_CURRENT_WEEK,
  CREATE_BOOKING,
  GET_BOOKING_INFO,
  ASSIGN_VISIT_TIME_FOR_BOOKING,
  GET_BOOKINGS_BY_DATE_RANGE,
  GET_ALL_BOOKINGS_WITHOUT_DATE,
  UPDATE_VISIT,
  DELETE_BOOKING,
  CREATE_BOOKING_WITHOUT_DATE,
  SET_LAST_SELECTED_PAYMENT_TYPE,
  UPDATE_BOOKING_INFORMATION
} from "../action-types";

export const bookingsReducer = (
  state = {
    bookingsWithoutDate: [],
    scanPrices: [],
    bookings: [],
    bookingInfo: null,
    selectedPaymentType: null
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_BOOKINGS_FOR_CURRENT_WEEK: {
      return {
        ...state,
        bookings: action.payload
      };
    }

    case GET_ALL_BOOKINGS_WITHOUT_DATE: {
      return {
        ...state,
        bookingsWithoutDate: action.payload.map(i => ({...i, patientData: i.patient}))
      };
    }

    case ASSIGN_VISIT_TIME_FOR_BOOKING: {
      let newBookingWithoutDate = [...state.bookingsWithoutDate];
      newBookingWithoutDate = newBookingWithoutDate.filter(
        item => item.id !== action.payload.id
      );

      return {
        ...state,
        bookingsWithoutDate: newBookingWithoutDate
      };
    }

    case GET_BOOKINGS_BY_DATE_RANGE: {
      const bookings = action.payload.map(item => ({
        ...item,
        start: item.appointmentStartTime,
        end: item.appointmentEndTime,
        title:
          _.startCase(_.toLower(item.patient.title)) + ". " + item.patient.name,
        doctor: "Dr. " + item.dentist.firstName + " " + item.dentist.lastName
      }));
      return {
        ...state,
        bookings: bookings
      };
    }

    case GET_BOOKING_INFO: {
      return {
        ...state,
        bookingInfo: action.payload
      };
    }

    case CREATE_BOOKING: {
      let bookingsArray = state.bookings;
      bookingsArray = [...bookingsArray];
      let currentObject = action.payload;
      currentObject.start = currentObject.appointmentStartTime;
      currentObject.end = currentObject.appointmentEndTime;
      currentObject.title =
        currentObject.patient.title + " " + currentObject.patient.name;
      bookingsArray.push(action.payload);

      return {
        ...state,
        bookings: bookingsArray
      };
    }

    case CREATE_BOOKING_WITHOUT_DATE: {
      let bookingsWithoutDateArray = state.bookingsWithoutDate;
      bookingsWithoutDateArray = [...bookingsWithoutDateArray];
      let currentObject = action.payload;
      currentObject.start = currentObject.appointmentStartTime;
      currentObject.end = currentObject.appointmentEndTime;
      currentObject.title =
        currentObject.patient.title + " " + currentObject.patient.name;
      bookingsWithoutDateArray.push(action.payload);

      return {
        ...state,
        bookingsWithoutDate: bookingsWithoutDateArray
      };
    }

    case DELETE_BOOKING: {
      return {
        ...state,
        bookings: state.bookingsWithoutDate.filter(
          item => item.id.toString() !== action.payload.id.toString()
        )
      };
    }

    case UPDATE_VISIT: {
      const bookings = state.bookings.map(booking => {
        if (parseInt(booking.id) === parseInt(action.payload.bookingId)) {
          const updatedBooking = { ...booking, visited: true };
          return updatedBooking;
        } else {
          return booking;
        }
      });
      return {
        ...state,
        bookings
      };
    }
    case UPDATE_BOOKING_INFORMATION: {
      const bookings = state.bookings.map(booking => {
        if (parseInt(booking.id) === parseInt(action.payload.id)) {
          const updatedBooking = { ...booking, ...action.payload };
          return updatedBooking;
        } else {
          return booking;
        }
      });
      return {
        ...state,
        bookings
      }; 
    }
    case SET_LAST_SELECTED_PAYMENT_TYPE: {
      return {
        ...state,
        selectedPaymentType: action.payload
      };
    }

    default:
      return state;
  }
};
