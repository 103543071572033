import { USER_ROLES } from "../constants/user-roles.constants";

export const hasAllowedRole = role => Object.keys(USER_ROLES).includes(role);
export const isUserDentist = role => role === USER_ROLES.DENTIST;
export const isUserRadiographer = role => role === USER_ROLES.RADIOGRAPHER || role === USER_ROLES.LEAD_RADIOGRAPHER;
export const isUserLeadRadiographer = role => role === USER_ROLES.LEAD_RADIOGRAPHER;
export const isUserRadiologist = role => role === USER_ROLES.RADIOLOGIST;
export const isUserReceptionist = role => role === USER_ROLES.RECEPTIONIST;

export const rolePermittedNavigationItems = { 
    DENTIST: ["PROFILE_DETAILS", "PERSONAL_INFORMATION", "CHANGE_PASSWORD", "PAYMENT_DETAILS", "CREDIT_CARD_INFORMATION", "OTHER", "LINKED_ACCOUNTS_PRACTICE_VIEW"],
    RECEPTIONIST: ["PROFILE_DETAILS", "CHANGE_PASSWORD"],
    RADIOGRAPHER: ["PROFILE_DETAILS", "CHANGE_PASSWORD"],
    LEAD_RADIOGRAPHER: ["PROFILE_DETAILS", "CHANGE_PASSWORD", "OTHER", "SCHEDULES"],
    RADIOLOGIST: ["PROFILE_DETAILS", "CHANGE_PASSWORD"]
  };