import React, { Component } from "react";
import TeethSVG from "../TeethSVG";
import ToothItem from "../ToothItem";
import StyledLabelItem from "../ToothLabel";

class TeethSelector extends Component {
  selectAllTeethInJaw(leftAnchor, rightAnchor) {
    const allTeeth = document.querySelectorAll("input.ap-tooth-checkbox");
    const teethToWorkWith = Array.from(allTeeth).filter(i => {
      return i.id.startsWith(leftAnchor) || i.id.startsWith(rightAnchor);
    });
    const atLeastOneChecked = teethToWorkWith.some(i => i.checked);
    const allChecked = teethToWorkWith.every(i => i.checked);
    for (let tooth of teethToWorkWith) {
      if (atLeastOneChecked) {
        tooth.checked = atLeastOneChecked;
      } else {
        tooth.checked = false;
      }

      if (allChecked) {
        tooth.checked = !tooth.checked;
      } else {
        tooth.checked = true;
      }
    }
    this.props.notifyToothSelection();
  }

  handleToothCheckboxClick(event) {
    if (event.target.className === "ap-tooth-checkbox") {
      this.props.notifyToothSelection();
    }
  }

  toothSelectionHandler(event) {
    const toothName = event.target.getAttribute("for");
    if (toothName) {
      let checkbox = document.getElementById(toothName);
      checkbox.checked = !checkbox.checked;
    }
    this.props.notifyToothSelection();
  }

  render() {
    const { teeth } = this.props;
    return teeth ? (
      <div>
        <p>
          CBCT Area of Interest*
          <br />
          <small className="text-muted">
            Select teeth that need to be scanned
          </small>
        </p>
        <div onClick={event => this.handleToothCheckboxClick(event)}>
          {teeth.ul.map((tooth, index) => (
            <ToothItem key={index} tooth={tooth} />
          ))}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {teeth.ur.map((tooth, index) => (
            <ToothItem key={index} tooth={tooth} />
          ))}
          {teeth.ll.map((tooth, index) => (
            <ToothItem key={index} tooth={tooth} />
          ))}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {teeth.lr.map((tooth, index) => (
            <ToothItem key={index} tooth={tooth} />
          ))}
          <p>
            Maxilla (upper jaw){" "}
            <span
              className="link-span"
              onClick={() => this.selectAllTeethInJaw("ul", "ur")}
            >
              Select all
            </span>
          </p>
          <div className="row ap-tooth-labels">
            <div className="col-sm-5">
              {teeth.ur.map((tooth, index) => (
                <StyledLabelItem key={index} tooth={tooth} number={8 - index} />
              ))}
              <br />
              <span
                className="link-span"
                onClick={() => this.selectAllTeethInJaw("ur")}
              >
                Select upper right quadrant
              </span>
            </div>
            <div className="col-sm-5">
              {teeth.ul.map((tooth, index) => {
                return (
                  <StyledLabelItem
                    key={index}
                    tooth={tooth}
                    number={index + 1}
                    index={index}
                  />
                );
              })}
              <br />
              <span
                className="link-span"
                onClick={() => this.selectAllTeethInJaw("ul")}
              >
                Select upper left quadrant
              </span>
            </div>
          </div>
          <p className="pt-5">
            Mandible (lower jaw){" "}
            <span
              className="link-span"
              onClick={() => this.selectAllTeethInJaw("ll", "lr")}
            >
              Select all
            </span>
          </p>
          <div className="row ap-tooth-labels">
            <div className="col-sm-5">
              {teeth.lr.map((tooth, index) => (
                <StyledLabelItem key={index} tooth={tooth} number={8 - index} />
              ))}
              <br />
              <span
                className="link-span"
                onClick={() => this.selectAllTeethInJaw("lr")}
              >
                Select lower right quadrant
              </span>
            </div>
            <div className="col-sm-5">
              {teeth.ll.map((tooth, index) => (
                <StyledLabelItem key={index} tooth={tooth} number={index + 1} />
              ))}
              <br />
              <span
                className="link-span"
                onClick={() => this.selectAllTeethInJaw("ll")}
              >
                Select lower left quadrant
              </span>
            </div>
          </div>
          <TeethSVG
            toothSelectionHandler={this.toothSelectionHandler.bind(this)}
          />
        </div>
      </div>
    ) : null;
  }
}

export default TeethSelector;
