//Project dependencies
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createStructuredSelector } from "reselect";
import dashboardAPI from "../../modules/dashboard";
import profileAPI from "../../modules/profile";
import visitsAPI from "../../modules/visits";
import visitDetailsAPI from "../../modules/visit-details";
import patientsAPI from "../../modules/patient";
import Dashboard from "./container";
import { withRouter } from "react-router";
import dentistAPI from "../../modules/dentists";
import radiologistsApi from '../../modules/radiologists'
import { logoutAction } from "../../actions/authActions";

const mapStateToProps = () =>
	createStructuredSelector({
		dentists: dentistAPI.selectors.getDentists,
		userRole: profileAPI.selectors.getUserRole,
		userProfile: profileAPI.selectors.getUserProfileInfo,
		bookings: dashboardAPI.selectors.getBookings,
		bookingInfo: dashboardAPI.selectors.getBookingInfo,
		scanPricesForPayment: dashboardAPI.selectors.getScanPricesForPayment,
		visitsByPatient: visitDetailsAPI.selectors.getPastVisits,
		hasCreditCard: profileAPI.selectors.hasCreditCard,
		bookingsWithoutDate: dashboardAPI.selectors.getBookingsWithoutDate,
		patientsArray: patientsAPI.selectors.getPatientsArray,
		scanPrices: dashboardAPI.selectors.getScanPrices,
		radiologists: radiologistsApi.selectors.getRadiologists,
		occupiedSlotsTimeRange: dashboardAPI.selectors.getOccupiedSlotsTimeRange,
		occupiedSlots: dashboardAPI.selectors.getOccupiedSlotsAll,
		temporallyOccupiedSlots: dashboardAPI.selectors.getTemporallyOccupiedSlots,
		blockedSlotsDateRange: dashboardAPI.selectors.getBlockedDatesList,
		selectedPaymentType: dashboardAPI.selectors.getSelectedPaymentType
	});

const mapDispatchToProps = dispatch => ({
	dashboardActions: bindActionCreators(dashboardAPI.actions, dispatch),
	patientActions: bindActionCreators(patientsAPI.actions, dispatch),
	profileActions: bindActionCreators(profileAPI.actions, dispatch),
	dentistActions: bindActionCreators(dentistAPI.actions, dispatch),
	visitsActions: bindActionCreators(visitsAPI.actions, dispatch),
	visitDetailsActions: bindActionCreators(visitDetailsAPI.actions, dispatch),
	radiologistsActions: bindActionCreators(radiologistsApi.actions, dispatch),
	logoutAction: () => {
		dispatch(logoutAction());
	}
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Dashboard)
);
