import React from "react";
import ProfileSideMenuItem from "../ProfileSideMenuItem";
import { NAV_ITEMS } from "../../constants";
import { rolePermittedNavigationItems } from "../../../../services/permission.service"

export default function ProfileSideMenu({
  history: { location },
  userRole
}) {
  const comp = i => rolePermittedNavigationItems[userRole].includes(i.permission);
  
  return (
    <aside className="aside-menu mod-border-right">
      <ul className="nav nav-tabs flex-column border-0">
        {NAV_ITEMS.filter(comp).map(i => (
          <ProfileSideMenuItem {...i} location={location} key={i.label} />
        ))}
      </ul>
    </aside>
  );
}
