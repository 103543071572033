import React from "react";
import { Field } from "react-final-form";

export default function RadiologySelect({ radiologists }) {
  return (
    <div className="form-group row">
      {/* <label className="col-sm-4 col-form-label"> Radiologists</label> */}
      <div className="col-sm-8">
        {/* <Field
          name="radiologistId"
          component={ReactSelectAdapter}
          options={radiologists}
          validate={required}
        /> */}
        <Field
          name="sameDayProcessing"
          type="checkbox"
          render={({ input }) => (
            <div className="form-group form-check mt-3">
              <input
                className="form-check-input"
                {...input}
                id="ap-form-checkbox-101"
              />
              <label className="form-check-label" htmlFor="ap-form-checkbox-101">
                Same day processing (+£10)
              </label>
            </div>
          )}
        />
      </div>
    </div>
  );
}
