import { loadLocalStoreData } from "./localStorage.service";
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localStorage.constants";

export const ImpersonatingService = () => ({
  token: loadLocalStoreData(LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN, null, false),
  originalToken: loadLocalStoreData(
    LOCAL_STORAGE_CONSTANTS.ORIGINAL_AUTH_TOKEN,
    null,
    false
  ),
  isImpersonating: loadLocalStoreData(
    LOCAL_STORAGE_CONSTANTS.ACTING_AS,
    null,
    false
  ),
  getPreselectedUser: loadLocalStoreData(
    LOCAL_STORAGE_CONSTANTS.ACTING_AS,
    { label: "" },
    false
  )
});
