import React from "react";

export default function EditModeToggler({
  onToggle,
  isEdit,
  children,
  isUserDentist
}) {
  const renderMode = () =>
    isEdit ? (
      isUserDentist ? (
        <button
          className="btn btn-light ap-edit-personal-information"
          onClick={onToggle}
        >
          Edit
        </button>
      ) : null
    ) : (
      <small>
        <i>Edit mode</i>
      </small>
    );
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h2>{children}</h2>
      <div className="ap-edit-button">{renderMode()}</div>
    </div>
  );
}
