import React, { Component } from "react";
import SkullSVG from "../SkullSVG";
import {  Row, Col, Button } from "reactstrap";

class SkullSelector extends Component {
 
  handlePartSelectHandler(clicked, e) {
    const el = e.target;
    this.buttonPainter(el)
    this.thisPainter(clicked.name);
  }

  handleSkullClickHandler(ev) {
    if(ev.target.classList.contains("cls-32")) {
        // this is tooth go out
        return 
    }
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    const buttonEl = document.getElementById(`${ev.currentTarget.id}-button`);
    this.buttonPainter(buttonEl)
    this.thisPainter(ev.currentTarget.id);
  }

  buttonPainter(buttonEl) {
    if(buttonEl) {
        buttonEl.classList.contains("btn-primary")
        ? buttonEl.classList.remove("btn-primary")
        : buttonEl.classList.add("btn-primary");
    }
  }

  thisPainter(id) {
    const el = document.getElementById(id);
    if (el) {
      el.classList.contains("active")
        ? el.classList.remove("active")
        : el.classList.add("active");
      el.checked = !el.checked;
    }

    this.props.notifySkullSelection();
  }

  render() {    
    return (
      <Row>
        <Col xs="8">
          <p>
            Maxillofacial/ENT Area of Interest*
            <br />
            <small className="text-muted">
                Select areas that need to be scanned
            </small>
          </p>
          <div>
            {this.props.options.map(i => {
              return (
                i.name !== "maxillofacial" && (
                  <Button
                    color="secondary"
                    key={i.id}
                    className="ap-skull-checkbox"
                    outline 
                    id={`${i.name}-button`}
                    name={i.name}
                    onClick={e => this.handlePartSelectHandler(i, e)}
                  >
                    {i.humanReadablePrice}
                  </Button>
                )
              );
            })}
          </div>
        </Col>
        <Col>
          <SkullSVG
            handlePartSelectHandler={this.handleSkullClickHandler.bind(this)}
          />
        </Col>
      </Row>
    );
  }
}

export default SkullSelector;
