// Bookings
export const GET_ALL_BOOKINGS_FOR_CURRENT_WEEK =
	"[Bookings] GET_ALL_BOOKINGS_FOR_CURRENT_WEEK";
export const CREATE_BOOKING = "[Bookings] CREATE_BOOKING";
export const CREATE_BOOKING_WITHOUT_DATE =
	"[Bookings] CREATE_BOOKING_WITHOUT_DATE";
export const DELETE_BOOKING = "[Bookings] DELETE_BOOKING";
export const GET_BOOKING_INFO = "[Bookings] GET_BOOKING_INFO";
export const ASSIGN_VISIT_TIME_FOR_BOOKING =
	"[Bookings] ASSIGN_VISIT_TIME_FOR_BOOKING";
export const GET_BOOKINGS_BY_DATE_RANGE =
	"[Bookings] GET_BOOKINGS_BY_DATE_RANGE";
export const GET_ALL_BOOKINGS_WITHOUT_DATE =
	"[Bookings] GET_ALL_BOOKINGS_WITHOUT_DATE";
export const UPDATE_VISIT = "[Bookings] UPDATE_VISIT";
export const UPDATE_BOOKING_INFORMATION = "[Bookings] UPDATE_BOOKING_INFORMATION";

// Prices
export const SET_SCAN_PRICES = "[Bookings] SET_SCAN_PRICES";
export const SET_SCAN_PRICES_STATIC = "[Bookings] SET_SCAN_PRICES_STATIC";
export const UPDATE_SCAN_PRICES_STATIC = "[Bookings] UPDATE_SCAN_PRICES_STATIC";
//NEW
export const SET_SCAN_PRICES_STATIC_SAVING = "[Bookings] SET_SCAN_PRICES_STATIC_SAVING";

//NEW END
// Time Slots
export const SET_OCCUPIED_SLOTS = "[Bookings] SET_OCCUPIED_SLOTS";
export const SET_OCCUPIED_SLOTS_DATE_RANGE =
	"[Bookings] SET_OCCUPIED_SLOTS_DATE_RANGE";
export const SET_BLOCKED_SLOTS_DATE_RANGE =
	"[Bookings] SET_BLOCKED_SLOTS_DATE_RANGE";
export const SET_TEMPORALLY_OCCUPIED_SLOTS =
	"[Bookings] SET_TEMPORALLY_OCCUPIED_SLOTS";
export const UPDATE_TEMPORALLY_OCCUPIED_SLOTS =
	"[Bookings] UPDATE_TEMPORALLY_OCCUPIED_SLOTS";
export const SET_LAST_SELECTED_PAYMENT_TYPE =
	"[Bookings] SET_LAST_SELECTED_PAYMENT_TYPE";
