import { combineReducers } from "redux";
import { bookingsReducer } from "./reducers/bookings";
import { pricesReducer } from "./reducers/prices";
import { occupiedSlotsReducer } from "./reducers/bookings-slots";

export default combineReducers({
  bookingsReducer,
  pricesReducer,
  occupiedSlotsReducer
});
