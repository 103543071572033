import {
  FetchDataRoutine,
  GenerateDataRoutine,
  SET_FILTERS
} from "../action-types";

const initialVouchersState = {
  isFetching: false,
  vouchers: [],
  filters: { status: null },
  error: null
};

export const vouchersListReducer = (state = initialVouchersState, action) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        vouchers: action.payload,
        error: null
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case GenerateDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        vouchers: [...action.payload, ...state.vouchers],
        error: null
      };
    case GenerateDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case GenerateDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};
