import { createSelector } from "reselect";
import Moment from "moment";
import { orderBy, get } from "lodash";

const radiologyReportsSelector = ({ radiologyReports }) => radiologyReports;

export const getReportCount = createSelector(
  radiologyReportsSelector,
  ({ radiologyReportsListReducer }) => {
    return radiologyReportsListReducer.reportCount;
  }
);

export const getReportFilter = createSelector(
  radiologyReportsSelector,
  ({ radiologyReportsListReducer }) => {
    return radiologyReportsListReducer.filters;
  }
);

export const getRadiologyReports = createSelector(
  radiologyReportsSelector,
  ({ radiologyReportsListReducer }) => {
    return radiologyReportsListReducer.data;
  }
);
