import { FetchDataRoutine } from "../action-types";

const initialRadiologistsState = {
    isFetching: false,
    data: [],
    error: null
};

export const radiologistsListReducer = (
  state = initialRadiologistsState,
  action
) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
