import React, { Fragment, useEffect } from "react";

import { Link } from "react-router-dom";
import { NAVIGATION_CONSTANTS } from "../constants/navigation.constants";
import ImpersonateDentist from "../modules/dentists/components/ImpersonateDentist";
import { ImpersonatingService } from "../services/impersonating.service";
// import { ReactComponent as Logo } from "../assets/img/3beam_logo_white_bg.svg";
import { UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { LOCAL_STORAGE_CONSTANTS } from "../constants/localStorage.constants";
import { loadLocalStoreData } from "../services/localStorage.service";
import classNames from "classnames";
// import styled from "styled-components";

// const StyledLogo = styled(Logo)`
//   position: absolute;
//   max-height: 115px;
//   cursor: default;
// `;

export default function Header({
  isUserDentist,
  history,
  logoutAction,
  isUserRadiologist,
  isUserReceptionist,
  isUserLeadRadiographer,
  fetchDentists,
  dentists,
}) {
  const { isImpersonating } = ImpersonatingService();

  const overRideCss = {
    right: " 0",
    left: "auto",
  };

  const openTeamViewer = () =>
    window.open("https://get.teamviewer.com/6hktufm");

  const logoutHandler = (e) => {
    e.preventDefault();
    logoutAction();
    history.push(NAVIGATION_CONSTANTS.LOGIN);
  };

  const loggedAs = loadLocalStoreData(
    LOCAL_STORAGE_CONSTANTS.ACTING_AS,
    {},
    true
  ).label;

  const handleLeaveSession = async () => {
    localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.ACTING_AS);
    localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.AUTH_TOKEN);
    window.location.reload();
  };

  useEffect(() => {
    var widget = document.getElementById('chat-widget-container')
    if (widget) {
      widget.style.display = 'block'
      return
    }

    if (!document.querySelector('script[src="http://cdn.livechatinc.com/tracking.js"]')) {
      window.__lc = window.__lc || {};
      window.__lc.license = 12490635;
      var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
      lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
      document.getElementById('root').append(lc)
    }
  })

  useEffect(() => () => {
    const chatWidgetContainer = document.getElementById('chat-widget-container');
    if (chatWidgetContainer) {
      chatWidgetContainer.style.display = 'none'
    }
  }, []);

  return (
    <header className="app-header navbar">
      <span className="navbar-brand link-span position-relative">
        {/* <StyledLogo /> */}
        <img
          src={require("../assets/img/3Beam-01.png")}
          alt="logo"
          style={{
            height: "60px",
            marginLeft: "5px",
            width: "auto",
          }}
        />
      </span>
      {isImpersonating && (
        <Fragment>
          <ul className="navbar-nav">
            {(isUserDentist || isUserRadiologist) && (
              <li
                className={classNames("nav-item px-3", {
                  active:
                    history.location.pathname ===
                    NAVIGATION_CONSTANTS.RADIOLOGY_REPORT,
                })}
              >
                <Link className="nav-link" to="/radiology-report">
                  Order a report
                </Link>
              </li>
            )}
            <li className="d-xl-inline d-none">
              <i className="icon-check" />
              &nbsp; You are now logged in as <strong>{loggedAs}</strong>. You
              can Book, Edit patients as this dentist would.
            </li>
            <li className="nav-item px-3 active">
              <button className="btn btn-primary" onClick={handleLeaveSession}>
                Leave session
              </button>
            </li>
            {isUserRadiologist && (
              <li className="nav-item px-3 active d-xl-inline d-lg-inline d-md-inline d-none">
                <a
                  className="dropdown-item"
                  href="/login"
                  onClick={logoutHandler}
                >
                  Log Out
                </a>
              </li>
            )}
          </ul>
          {isUserDentist && (
            <div className="ml-auto d-flex pr-3">
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="btn btn-link dropdown-toggle"
                >
                  My Profile
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu" style={overRideCss}>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.PROFILE}
                  >
                    Profile details
                  </Link>
                  <span
                    className="dropdown-item span-link"
                    onClick={logoutHandler}
                  >
                    Log Out
                  </span>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}
        </Fragment>
      )}
      {!isImpersonating && (
        <ul className="navbar-nav">
          {!isUserRadiologist && (
            <li
              className={classNames("nav-item px-3", {
                active:
                  history.location.pathname === NAVIGATION_CONSTANTS.DASHBOARD,
              })}
            >
              <Link className="nav-link" to="/dashboard">
                Bookings
              </Link>
            </li>
          )}
          <li
            className={classNames("nav-item px-3", {
              active: history.location.pathname === NAVIGATION_CONSTANTS.VISITS,
            })}
          >
            <Link className="nav-link" to="/visits">
              {isUserRadiologist ? "Report requests" : "Visits"}
            </Link>
          </li>
          {isUserReceptionist && (
            <ImpersonateDentist
              history={history}
              fetchDentists={fetchDentists}
              dentists={dentists}
            />
          )}
          {isUserReceptionist && (
            <li
              className={classNames("nav-item px-3", {
                active:
                  history.location.pathname === NAVIGATION_CONSTANTS.VOUCHERS,
              })}
            >
              <Link className="nav-link" to="/vouchers">
                Vouchers
              </Link>
            </li>
          )}
          {isUserReceptionist && (
            <UncontrolledDropdown>
             <DropdownToggle
               tag="button"
               className="btn btn-link dropdown-toggle"
             >
               Staff
             </DropdownToggle>
             <DropdownMenu className="dropdown-menu" style={overRideCss}>
                <Link
                  className="dropdown-item"
                  to="/radiographers"
                >
                  Radiographers
                </Link>
                <Link
                  className="dropdown-item"
                  to="/radiologists"
                >
                  Radiologists
                </Link>
                <Link
                  className="dropdown-item"
                  to="/dentists"
                >
                  Dentists
                </Link>
             </DropdownMenu>
           </UncontrolledDropdown>
          )}
          {(!isUserLeadRadiographer && !isUserRadiologist) && (
            <li
              className={classNames("nav-item px-3", {
                active:
                  history.location.pathname ===
                  NAVIGATION_CONSTANTS.RADIOLOGY_REPORT,
              })}
            >
              <Link className="nav-link" to="/radiology-report">
                Order a report
              </Link>
            </li>
          )}
          {isUserDentist && (
            <li
              className={classNames("nav-item px-3", {
                active:
                  history.location.pathname ===
                  NAVIGATION_CONSTANTS.WALLET,
              })}
            >
              <Link className="nav-link" to="/wallet">
                Educational rewards
              </Link>
            </li>
          )}
        </ul>
      )}
      {!isImpersonating && (
        <div className="ml-auto d-flex pr-3">
          {isUserReceptionist && (
            <ul className="navbar-nav">
              <li
                className={classNames("nav-item px-3", {
                  active:
                    history.location.pathname ===
                    NAVIGATION_CONSTANTS.PRICE_LIST,
                })}
              >
                <Link className="nav-link" to={NAVIGATION_CONSTANTS.PRICE_LIST}>
                  Price List
                </Link>
              </li>
              <UncontrolledDropdown>
                <DropdownToggle
                  tag="button"
                  className="btn btn-link dropdown-toggle"
                >
                  Reports
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu"
                  style={{ left: "-30px" }}
                >
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.AUDIT_REGISTRATION_REPORT}
                    target="_blank"
                  >
                    Registration Audit Report
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.RADIOLOGIST_REPORTS}
                    target="_blank"
                  >
                    Radiologist Report
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.AUDIT_REPORTS}
                    target="_blank"
                  >
                    Audit Report
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.PATIENT_SATISFACTION_REPORTS}
                    target="_blank"
                  >
                    Patient Satisfaction Report
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ul>
          )}
          {isUserDentist && (
            <button
              className="btn btn-outline-primary mr-5"
              onClick={openTeamViewer}
            >
              <img
                className="team-viewer-icon"
                src={require("../assets/img/teamviewer.png")}
                alt="Team viewer"
              />
              TeamViewer
            </button>
          )}
          {(isUserDentist || isUserReceptionist) && (
            <UncontrolledDropdown>
              <DropdownToggle
                tag="button"
                className="btn btn-link dropdown-toggle"
              >
                Information
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu" style={{ left: "-30px" }}>
                {isUserDentist && (
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.AGREEMENT}
                    target="_blank"
                  >
                    Service Level Agreement
                  </Link>
                )}
                {isUserReceptionist && (
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.AGREEMENT_DENTISTS_LIST}
                    target="_blank"
                  >
                    Service Level Agreement
                  </Link>
                )}
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.DATA_PROCESSING}
                  target="_blank"
                >
                  Data Processing Agreement
                </Link>
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.TERMS}
                  target="_blank"
                >
                  Standard Terms and Conditions
                </Link>
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.REFERRAL_GUIDELINES}
                >
                  Referral Guidelines
                </Link>
                {(isUserReceptionist || isUserDentist) &&
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.INVOICES}
                  >
                    Invoices
            </Link>}
                {isUserReceptionist && <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.WALLET}
                >
                  Educational Rewards
                </Link>}
                {isUserReceptionist && <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.REGISTRATION_SURVEY}
                >
                  Registration Survey
                </Link>}
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.PRICING}
                  target="_blank"
                >
                  Pricing
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
          <UncontrolledDropdown>
            <DropdownToggle
              tag="button"
              className="btn btn-link dropdown-toggle"
            >
              My Profile
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu" style={overRideCss}>
              {isUserDentist && (
                <Fragment>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.PROFILE}
                  >
                    Profile details
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={NAVIGATION_CONSTANTS.CREDIT_CARD_DETAILS}
                  >
                    Credit card information
                  </Link>
                </Fragment>
              )}
              <Link
                className="dropdown-item"
                to={NAVIGATION_CONSTANTS.CHANGE_PASSWORD}
              >
                Change password
              </Link>
              {isUserDentist && (
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.JOINED_ACCOUNTS}
                >
                  Linked accounts - Practice view
                </Link>
              )}
              {isUserLeadRadiographer && (
                <Link
                  className="dropdown-item"
                  to={NAVIGATION_CONSTANTS.SCHEDULES}
                >
                  Schedules
                </Link>
              )}
              <span className="dropdown-item span-link" onClick={logoutHandler}>
                Log Out
              </span>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      )}
    </header>
  );
}
