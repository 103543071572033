import React, { Fragment, useState, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Row, Col, Button } from "reactstrap";
import Moment from "moment";
import { DateRangePicker } from "react-dates";

import { dateFormatShort } from "../../../../constants/global.constants";
const heightCss = {
  height: "35px",
};

export default function RadiologyReportsFilter({ onFilter, fetchRadiologyReports }) {
  const [focused, setFocused] = useState(null);
  const [filters, setFilters] = useState({});
  const [dates, setDates] = useState({startDate: null, endDate: null });
  const inputRef = useRef(null);
  const handleFocusChange = (focused) => {
    setFocused(focused);
  };

  const selectionChange = (event, type) => {
    let formattedStartDate = null;
    let formattedEndDate = null;
    const { startDate, endDate } = dates;
    if(startDate && endDate){
      formattedStartDate = Moment(startDate).format('DD/MM/YYYY')
      formattedEndDate = Moment(endDate).format('DD/MM/YYYY')
    }
    setFilters({ [type]: event.target.value })
    onFilter({ [type]: event.target.value, startDate: formattedStartDate, endDate: formattedEndDate });
    fetchRadiologyReports({startDate: formattedStartDate, endDate: formattedEndDate, criteria: event.target.value })
  };

  const onDatesChange = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
    if(startDate && endDate){
      const formattedStartDate = Moment(startDate).format('DD/MM/YYYY')
      const formattedEndDate = Moment(endDate).format('DD/MM/YYYY')
      onFilter({ ...filters, startDate: formattedStartDate, endDate: formattedEndDate });
      fetchRadiologyReports({startDate: formattedStartDate, endDate: formattedEndDate, criteria: filters.searchString})
    }
  };

  const clearFilter = (e) => {
    setDates({startDate: null, endDate: null });
    inputRef.current.value = "";
    onFilter({});
    fetchRadiologyReports({})
  }

  return (
    <Fragment>
      <Row className="d-flex flex-row align-items-center justify-content-start mx-0">
        <Col md="3" sm="12" xs="12" className="pl-0">
          <div className="form-group input-group mb-0">
            <div className="input-group-prepend">
              <div className="input-group-text ">
                <i className="icon-magnifier" />
              </div>
            </div>
            <input
              type="text"
              ref={inputRef}
              className="form-control ap-table-sortable-search"
              style={heightCss}
              placeholder="Type in ID, patient name"
              onChange={(event) => selectionChange(event, "searchString")}
            />
          </div>
        </Col>
        <Col
          md="5"
          sm="6"
          xs="12"
          className="text-center"
          style={{ maxWidth: "240px" }}
        >
          <div className="form-group mb-0">
            <DateRangePicker
              onFocusChange={handleFocusChange}
              onDatesChange={onDatesChange}
              startDateId={"startDate"}
              endDateId={"endDate"}
              keepOpenOnDateSelect={true}
              firstDayOfWeek={1}
              reopenPickerOnClearDates={false}
              displayFormat={() => dateFormatShort}
              focusedInput={focused}
              small={true}
              hideKeyboardShortcutsPanel
              isOutsideRange={(date) => date.isAfter(Moment())}
              startDate={dates.startDate}
              endDate={dates.endDate}
              minimumNights={0}
            />
          </div>
        </Col>
        <Col md="4" xs="3" sm="6">
          <Button
            color="primary"
            size="md"
            onClick={(e) => clearFilter()}
            style={{
              display: "flex",
            }}
          >
            <span className="mr-2">Clear</span>{" "}
            <span className="d-none d-sm-block">filter</span>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
}
