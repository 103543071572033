import React from "react";
import { formatSpeciality } from "../../helpers";

export default function PersonalInformation({
  children,
  userProfile: {
    email,
    enabled,
    firstName,
    id,
    lastName,
    nameOfPractice,
    password,
    role,
    speciality,
    telephoneNumber,
    title,
    gdc,
    postCode,
    address,
    city,
    visitsEnabled,
    enabledEmailNotification,
  },
}) {
  const buildName = () => {
    return `${title}. ${lastName} ${firstName}`;
  };

  return (
    <div id="personal-info" aria-labelledby="personal-info-tab">
      <div className="row ap-info-editor">
        <div className="ap-col-fixed-500">
          {children}
          <div className="ap-actual-info">
            <p className="pt-4">
              <i className="icon-user mr-2" />
              <strong>{buildName()}</strong>
            </p>
            <ul className="list-group mb-4">
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Speciality</span>
                  <span className="col-sm-7">
                    {formatSpeciality(speciality)}
                  </span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Name of Practice</span>
                  <span className="col-sm-7">{nameOfPractice}</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">GDC / GMC No.</span>
                  <span className="col-sm-7">{gdc}</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-11 text-muted">
                    Please, send me patient appointment confirmations by email
                  </span>
                  <span className="col-sm-1">
                    {visitsEnabled ? "Yes" : "No"}
                  </span>
                </span>
              </li>
            </ul>
            <p className="pt-4">
              <strong>Address of the practice</strong>
            </p>
            <ul className="list-group mb-4">
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Country</span>
                  <span className="col-sm-7">United Kingdom</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">City</span>
                  <span className="col-sm-7">{city}</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Postcode</span>
                  <span className="col-sm-7">{postCode}</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Address Line</span>
                  <span className="col-sm-7">{address}</span>
                </span>
              </li>
            </ul>
            <p className="pt-4">
              <strong>Contact information</strong>
            </p>
            <ul className="list-group mb-4">
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Phone Number</span>
                  <span className="col-sm-7">{telephoneNumber}</span>
                </span>
              </li>
              <li className="list-group-item">
                <span className="row">
                  <span className="col-sm-5 text-muted">Email Address</span>
                  <span className="col-sm-7">{email}</span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
