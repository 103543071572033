import React, { Component } from "react";
import { Row, Col, Alert } from "reactstrap";
import { isNil } from "lodash";
import ScansUpload from "../ScansUpload";
import {
	scansStorageUri
} from "../../../../constants/global.constants";
import RequestReport from "../../../visits/components/RequestReport";
import "./styles.scss";
import { store } from "../../../../index";
import { fetchVisits, updateScan } from "../../../visits/actions";
import axios from "../../../../axios-client";
import { toastr } from "react-redux-toastr";

export default class ReportInfo extends Component {

	handleReportUpdate(values) {
		this.props.uploadReport({
			reportContent: values.reportText,
			visitId: this.props.visitDetails.id
		});
		this.setState({
			isEditing: false
		});
		this.props.onUpdatedReport();
	}

	isDisabled() {
		return (
			!this.props.isUserRadiologist
		);
	}

	renderReport(report, deleteScan) {

		const urlBuilder = file => scansStorageUri + file;
    if(report && !report.reportContentList) {
      return null
    }
		return report.reportContentList.map((item, i) => <a
			key={i}
			className="btn btn-light btn-sm mr-1 mb-1"
			href={report.fullRadiologyReportUrl || urlBuilder(item.file)}
			target="_blank"
			rel="noopener noreferrer"
			onClick={e => e.stopPropagation()}
		>
			<div className="d-flex align-items-center">
				<span title={item.file || report.fullRadiologyReportName}>
					{item.file || report.fullRadiologyReportName}
				</span>
				{!this.isDisabled() && (
					<span
						className="font-xl px-1 ml-1"
						onClick={e => {
							e.preventDefault();
							// deleteScan(report.id, report.id, true);
							this.deleteReport(report.id, item.file);
						}}
					>
						&times;
					</span>
				)}
			</div>
		</a>);
	}

	async deleteReport(reportId, fileName) {
		await axios.delete(`/reports/deleteReportFile/${reportId}/${fileName}`);
		store.dispatch(fetchVisits());
		store.dispatch(
			updateScan({ reportId, fileName })
		);
		toastr.success('Success', 'Report was deleted');
	}

	renderReportForm() {

		const { report, orderReportDto } = this.props.visitDetails;
		const { isUserRadiologist, requestReport, isUserDentist, onReportUpload, deleteScan } = this.props;

		if ((!report && !orderReportDto) && !isUserDentist) {
			return (
				<Alert color="info" className="text-center mt-4">
					Report was not requested yet.
				</Alert>
			);
		}
		if (isUserDentist && isNil(report || orderReportDto)) {
			return <RequestReport requestReport={requestReport} />;
		}
		if (!isUserRadiologist && report && orderReportDto && isNil(report.reportContent || orderReportDto.fullRadiologyReportName)) {
			return (
				<Alert color="info" className="text-center mt-4">
					Report was requested but not completed yet.
				</Alert>
			);
		}
		return (
			<div>
				<Row>
					<Col sm={{ size: 8 }}>
						<span>Radiology Report</span>
					</Col>
				</Row>
				<Row>
					<Col sm={{ size: 8 }}>
						{report && (
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									<Row className="row">
										{!report.reportContent && (
											<span className="col-7">
												<i className="icon-close"></i> Upload pending...
											</span>
										)}
										{!!report.reportContentList.length && (
											<div>{this.renderReport(report, deleteScan)}</div>
										)}
									</Row>
								</li>
							</ul>
						)}
						{orderReportDto && (
							<ul className="list-group list-group-flush">
								<li className="list-group-item">
									<Row className="row">
										{!orderReportDto.fullRadiologyReportName && (
											<span className="col-7">
												<i className="icon-close"></i> Upload pending...
											</span>
										)}
										{!!orderReportDto.fullRadiologyReportName && (
											<div>{this.renderReport(orderReportDto, deleteScan)}</div>
										)}
									</Row>
								</li>
							</ul>
						)}
					</Col>
				</Row>
				{((report && report.reportContentList.length < 5) || (orderReportDto && !orderReportDto.fullRadiologyReportName)) && (
					<Row>
						<Col sm={{ size: 8 }}>
							{!this.isDisabled() && (
								<ScansUpload onFileUpload={onReportUpload} multiple="false" />
							)}
						</Col>
					</Row>)}
			</div>
		);
	}

	render() {
		return this.renderReportForm();
	}
}