import React, { PureComponent, Fragment } from "react";
import moment from "moment";
import { Button, Alert, Row, Col } from "reactstrap";
import { OnChange } from "react-final-form-listeners";
import { Form, Field, FormSpy } from "react-final-form";
import { Link } from "react-router-dom";
import {
	toUpper,
	uniqBy,
	flatten,
	keys,
	range,
	includes,
	isEmpty,
} from "lodash";
import Countdown from "react-countdown-now";
import classNames from "classnames";

import Modal from "../../../../common/modals/Modal";
import ApplyVoucherForm from "../../../../common/ApplyVoucherForm";
import TeethSelector from "../TeethSelector";
import SkullSelector from "../SkullSelector";
import { required } from "../../../../modules/profile/helpers";
import { NAVIGATION_CONSTANTS } from "../../../../constants/navigation.constants";
import {
	calculateEstimatedBookScanPrice,
	SCAN_TYPES,
} from "../../../../services/bookScan.service";
import ReactSelectAdapter from "../../../../common/masks/ReactSelectAdapter";
import ReactSelectGroupedAdapter from "../../../../common/masks/ReactSelectGroupedAdapter";
import SingleDatePickerAdapter from "../../../../common/masks/SingleDatePickerAdapter";
import TimeAdapter from "../../../../common/masks/TimeAdapter";

import RadiologySelect from "../../../radiologists/components/RadiologySelect";
import PatientPicker from "../../../patient/components/PatientPicker";
import SelectedPatientDetails from "../../../patient/components/SelectedPatientDetails";
import PriceBreakDown from "../PriceBreakDown";
import OccupiedSlots from "../OccupiedSlots";
import { companyInfo } from "../../../../services/legal.service";
import { checkVoucherCode } from "../../../../services/api";
import {
	bookingCreationFormat,
	backendDateFormat,
	slotDuration,
	PAYMENT_OPTIONS,
	fullRadiologyReportNoOption,
} from "../../../../constants/global.constants";
import "./styles.scss";
import axios from '../../../../axios-client'

class BookScan extends PureComponent {
	state = {
		scanType: SCAN_TYPES.CBCT,
		date: null,
		hasTimeConflict: false,
		selectedPaymentType: null,
		voucherCode: "",
		showVoucherForm: false,
		checkingVoucherInfo: false,
		voucherInfo: null,
		existCBCT: false,
		weekdays: ['10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45',
			'14:00', '14:15', '14:30', '14:45', '15:00',
			'15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45'],
		weekends: ['09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45',
			'12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45',
			'15:00', '15:15', '15:30', '15:45'],
		times: [],
        selectedPatient: this.props.selectedPatientId || '',
	};

	countDownTimer = Date.now() + 60000 * 30; // 30 m

    componentDidUpdate(prevProps) {
        if (prevProps.selectedPatientId !== this.props.selectedPatientId) {
            this.setState({ selectedPatient: this.props.selectedPatientId || '' });
        }
    }

	handleModalVisibilityToggle = () => {
		this.setState({ showModal: !this.state.showModal });
	};

	onDiscard = () => {
		this.handleModalVisibilityToggle();
		this.props.onClose();
	};

	handleApplyVoucher = () => {
		const { voucherCode } = this.state;

		this.setState({ checkingVoucherInfo: true });

		checkVoucherCode(voucherCode)
			.then((discountAmount) => {
				this.setState({
					voucherInfo: {
						isValid: true,
						discountAmount,
						code: voucherCode,
					},
				});
			})
			.catch(() => {
				this.setState({
					voucherInfo: {
						isValid: false,
						discountAmount: 0,
						code: voucherCode,
					},
				});
			})
			.finally(() => {
				this.setState({ checkingVoucherInfo: false });
			});
	};

	componentDidMount() {
		const {
			selectedDate,
			fetchRadiologists,
			getScanPricesForBooking,
			getAllPatients,
		} = this.props;

		fetchRadiologists();
		getScanPricesForBooking(this.props.userProfile.id);

		this.props
			.getLastSelectedPaymentType(this.props.userProfile.id)
			.then((paymentType) => {
				const selectedPayment = PAYMENT_OPTIONS.find(
					(payment) => payment.value === paymentType
				);
				this.setState({
					selectedPaymentType: selectedPayment,
				});
			});

		this.props.isUserDentist &&
			this.props.checkFirstCBCTIsOrNot().then((response) => {
				this.setState({
					existCBCT: response,
				});
			});

		if (selectedDate) {
			const dateObj = moment(selectedDate);

			this.setState({
				date: dateObj,
				hoursMinutes: dateObj.format("hh:mm")
			});
		}
		getAllPatients();
		// this.blockedSlotsDefine();
		this.switchScanPrices(SCAN_TYPES.CBCT);
	}

	blockedSlotsDefine(selectedDate) {
		const timeClone = [...this.state.times];
		const { blockedSlotsDateRange } = this.props;
		blockedSlotsDateRange.map(({ appointmentStartTime }) => {
			let appointmentDate = moment(appointmentStartTime).format('Y-M-D');
			let _selectedDate = moment(selectedDate).format('Y-M-D');
			if (appointmentDate == _selectedDate) {
				let time = moment(appointmentStartTime).format('HH:mm');
				var index = timeClone.indexOf(time);
				timeClone.splice(index, 1);
			}
		});
		this.setState({ times: timeClone, date: selectedDate });
	}

	switchScanPrices(type) {
		this.setState({
			scanType: type,
			selectedTeeth: [],
			toothSelectionInvalid: this.isTwoD,
		});
	}

	handleLockUpdate(value, old, { hoursMinutes, selectedDate }) {
		if (value === old) return;
		const endDate = this.calculateEndTime(hoursMinutes, selectedDate);
		const appointmentStartTime = endDate
			? endDate.format(bookingCreationFormat)
			: endDate;
		const appointmentEndTime = endDate
			? endDate.add(slotDuration, "minutes").format(bookingCreationFormat)
			: endDate;

		if (
			appointmentStartTime &&
			appointmentEndTime
		) {
			this.props.updateTemporallyOccupiedSlots({
				appointmentStartTime,
				appointmentEndTime,
			}) &&
				this.props.fetchOccupiedSlotsByDateRange(
					moment(appointmentStartTime)
						.startOf("week")
						.format(backendDateFormat),
					moment(appointmentEndTime).endOf("week").format(backendDateFormat)
				);
		}
	}

	retrieveBookedAppointmentTimes(selectedDate) {
		axios.get('/bookings/retrieve-booked-appointment-times', {
			params: {
				date: moment(selectedDate).format('YYYY-MM-DD')
			}
		}).then(response => {
			if (moment(selectedDate).day() === 6) {
				this.setState({ times: this.state.weekends.filter((el) => !response.data.includes(el)), date: selectedDate })
			}
			else {
				this.setState({ times: this.state.weekdays.filter((el) => !response.data.includes(el)), date: selectedDate })
			}
		}).then(_ => {
			this.blockedSlotsDefine(selectedDate)
		})
	}

	get isCBCT() {
		return this.state.scanType === SCAN_TYPES.CBCT;
	}

	get isTwoD() {
		return this.state.scanType === SCAN_TYPES.TWO_D;
	}

	get isSkull() {
		return this.state.scanType === SCAN_TYPES.SKULL;
	}

	collectSelectedTeeth() {
		return document.querySelectorAll("input.ap-tooth-checkbox:checked");
	}

	collectSelectedSkullAreas() {
		return document.querySelectorAll(".ap-skull-checkbox.btn-primary");
	}

	calculateEndTime(hoursMinutes, selectedDate) {
		let selectedTime = {};
		let endDate = null;
		if (hoursMinutes && selectedDate) {
			selectedTime = moment(hoursMinutes, ["h:mm A"]);
			endDate = selectedDate.set({
				hours: selectedTime.hours(),
				minutes: selectedTime.minutes(),
			});
			return endDate;
		}
		return null;
	}

	createBooking(values) {
		const {
			clinicalIndications,
			termsAndConditions,
			subjectToSafeguarding,
			consentsUnderTheReferalProcess,
			patientComeWithTemplate,
			possibilityOfPregnancy,
			selectedPayment,
			selectedDate,
			hoursMinutes,
			selectedPatient,
			includeTMJs,
		} = values;

		const { scanPrices } = this.props;
		const { voucherInfo } = this.state;

		let endDate = this.calculateEndTime(hoursMinutes, selectedDate);
		let bookingItemDtos = [];

		const selectedTeeth = this.collectSelectedTeeth();
		if ((selectedTeeth && selectedTeeth.length > 0) || this.isTwoD) {
			selectedTeeth.forEach((item) => {
				const teethArray = [
					...flatten(
						keys(scanPrices.teeth).reduce(
							(acc, i) => [...acc, scanPrices.teeth[i]],
							[]
						)
					),
				];
				let mappedItem = teethArray.find((scan) => {
					return scan.name === item.id;
				});

				bookingItemDtos.push({
					itemPriceDto: {
						id: mappedItem.id,
						price: mappedItem.price,
					},
				});
			});
		}

		const data = calculateEstimatedBookScanPrice(
			values,
			this.collectSelectedTeeth(),
			this.props.scanPrices,
			this.isTwoD,
			this.isSkull,
			this.collectSelectedSkullAreas()
		);
		const extraData = flatten(data).map((res) => ({
			itemPriceDto: res,
		}));
		bookingItemDtos = [...bookingItemDtos, ...extraData];
		const booking = {
			appointmentStartTime: endDate
				? endDate.format(bookingCreationFormat)
				: endDate,
			appointmentEndTime: endDate
				? endDate
					.add(Number(slotDuration), "minutes")
					.format(bookingCreationFormat)
				: endDate,
			patient: selectedPatient,
			selectedPayment: selectedPayment.value,
			possibilityOfPregnancy,
			dentist: this.props.userProfile,
			bookingDetailsDto: {
				scanType: this.state.scanType,
				clinicalIndications,
				bookingItemDtos,
				termsAndConditions,
				subjectToSafeguarding,
				consentsUnderTheReferalProcess,
				patientComeWithTemplate,
				verballyConsented: true,
				authorised: true,
				includeTMJs,
			},
		};

		const isFullRadiologyReportSelected =
			values &&
			values.selectedFullRadiologyReport &&
			values.selectedFullRadiologyReport.label === "Yes (+£90)";

		if (isFullRadiologyReportSelected) {
			booking.bookingDetailsDto.radiologist = {
				radiologistId: companyInfo.defaultRadiologistId,
			};
		}

		if (values.sameDayProcessing) {
			const {
				scanPrices: { selectedExtraOptions },
			} = this.props;
			const expressProcessingFlag = selectedExtraOptions.find(
				(i) => i.name === "e_epr_2j"
			);
			booking.bookingDetailsDto.bookingItemDtos.push({
				itemPriceDto: expressProcessingFlag,
			});
		}

		if (voucherInfo) {
			booking.voucherCode = voucherInfo.code;
		}

		this.props.createBooking(booking);
		this.props.onClose();
	}

	teethSelectInvalid() {
		return (
			this.collectSelectedTeeth() || this.collectSelectedTeeth().length > 0
		);
	}

	notifyToothSelection() {
		const selectedTeeth = this.collectSelectedTeeth();
		if (selectedTeeth) {
			this.setState({
				toothSelectionInvalid: false,
			});
		} else {
			this.setState({
				toothSelectionInvalid: true,
			});
		}
		this.forceUpdate();
	}

	onSkullSelection(ev) {
		this.forceUpdate();
	}

	canAffordBooking(selectedPayment) {
		return (
			(selectedPayment &&
				Object.keys(selectedPayment).length > 0 &&
				selectedPayment.value >= 0) ||
			this.props.hasCreditCard
		);
	}

	optionsFormatter(options) {
		return (
			options &&
			uniqBy(
				options.map((i) => ({ value: i.id, label: i.humanReadablePrice })),
				"label"
			)
		);
	}

	radiologyReportOptionsFormatter(options) {
		const uniqueOptions =
			options &&
			uniqBy(
				options.map((i) => ({ value: i.id, label: "Yes (+£90)" })),
				"label"
			);
		return uniqueOptions
			? [...uniqueOptions, fullRadiologyReportNoOption]
			: [fullRadiologyReportNoOption];
	}

	optionsGrouper(options) {
		if (isEmpty(options)) return [];

		// Building id list doing this way cause in future we may add a new item that will belong to old group
		const dentalIds = range(70, 78);
		// DO NOT DO THIS AT HOME
		// THIS IS CRAZYNESS
		dentalIds.push(...range(105, 111));
		dentalIds.push(...range(120, 132));
		const EntIds = range(78, 88);
		const maxFaxIds = range(88, 91);
		const functor = (ids) => ({ value }) => includes(ids, value);

		const groupedOptions = [
			{
				label: "Dental",
				options: options.filter(functor(dentalIds)),
			},
			{
				label: "Ent",
				options: options.filter(functor(EntIds)),
			},
			{
				label: "Max Fax",
				options: options.filter(functor(maxFaxIds)),
			},
		];
		return groupedOptions;
	}

	displayPrice(values) {
		const { scanPrices } = this.props;
		const data =
			calculateEstimatedBookScanPrice(
				values,
				this.collectSelectedTeeth(),
				scanPrices,
				this.isTwoD,
				this.isSkull,
				this.collectSelectedSkullAreas()
			) || [];
		let calculatedPrice = 0;
		let pricesBrakeDown = [];

		if (this.state.existCBCT || !this.isCBCT || values.selectedFullRadiologyReport.label !== "No") {
			const preparePriceView = (data, price = 0) => {
				return flatten(data).reduce((acc, i) => {
					if (this.isSkull) {
						if (i.name !== "cbct_f_dc_q" && i.name !== "cbct_f_rv_q") {
							return acc += i.price
						}
						return acc;
					}
					return acc += i.price
				}, price);
			}

			const preparePriceBrakeDownView = (data) =>
				flatten(data)
					.filter((i) => !i.name.startsWith("x_ray_"))
					.map((i) => ({
						value: i.humanReadablePrice,
						price: i.price,
						label: i.humanReadablePrice,
					}));
          
			if (values.sameDayProcessing) {
				const foundExtra = scanPrices.selectedExtraOptions.find((i) =>
					i.name.startsWith("e_epr")
				); // same day processing stuff

				calculatedPrice = preparePriceView(data, foundExtra.price);
				pricesBrakeDown = preparePriceBrakeDownView([...data, [foundExtra]]);
			} else {
				calculatedPrice = preparePriceView(data);
				pricesBrakeDown = preparePriceBrakeDownView(data);
			}
		}

		return (
			<PriceBreakDown
				calculatedPrice={calculatedPrice}
				pricesBrakeDown={pricesBrakeDown}
				voucherInfo={this.state.voucherInfo}
			/>
		);
	}

	renderRadiologistSelect(
		{ selectedFullRadiologyReport, sameDayProcessing },
		clearCheckboxSameDayProcessing
	) {
		const isFullRadiologyReportSelected =
			selectedFullRadiologyReport &&
			selectedFullRadiologyReport.label === "Yes (+£90)";

		if (sameDayProcessing && !isFullRadiologyReportSelected) {
			clearCheckboxSameDayProcessing();
		}

		if (selectedFullRadiologyReport && isFullRadiologyReportSelected) {
			return <RadiologySelect radiologists={this.props.radiologists} />;
		}

		return null;
	}

	canBook(invalid, values) {
		const selectedTeeth = this.collectSelectedTeeth();
		let hasSelectedSkullArea;
		if (this.isSkull) {
			const selectedSkullAreas = this.collectSelectedSkullAreas();
			hasSelectedSkullArea = selectedSkullAreas.length;
		}

		return (
			!invalid &&
			(selectedTeeth.length > 0 ||
				this.isTwoD ||
				(this.isSkull && hasSelectedSkullArea)) &&
			this.canAffordBooking(values.selectedPayment) &&
			!this.state.hasTimeConflict
		);
	}

	renderCountdown({ selectedDate, hoursMinutes }, clearFn) {
		if (selectedDate && hoursMinutes) {
			const {
				occupiedSlots = [],
				temporallyOccupiedSlots = [],
				occupiedSlotsTimeRange = [],
				userProfile = {},
				bookings = [],
			} = this.props;

      if(userProfile && !userProfile.id) {
        return null
      }
      
			const endDate = this.calculateEndTime(
				hoursMinutes,
				selectedDate
			);
			const allSlots = [
				...occupiedSlots,
				...temporallyOccupiedSlots,
				...occupiedSlotsTimeRange,
				...bookings,
			].filter(
				(i) =>
					moment(i.appointmentStartTime).isSame(endDate, "day") &&
					i.dentistId !== userProfile.id
			);
			return (
				<Fragment>
					<Countdown
						date={this.countDownTimer}
						onComplete={clearFn}
						daysInHours
					/>{" "}
					<OccupiedSlots
						occupiedSlots={allSlots}
						userId={userProfile.id}
						endDate={endDate}
						notifyHasConflict={(flag) => {
							this.setState({ hasTimeConflict: flag });
						}}
					/>
				</Fragment>
			);
		}
		this.countDownTimer = Date.now() + 60000 * 30; // 30 mins
	}

	render() {
		const {
			hoursMinutes,
			date,
			showVoucherForm,
			voucherCode,
			voucherInfo,
			checkingVoucherInfo,
            selectedPatient
		} = this.state;

		const {
			patientsArray,
			scanPrices: {
				selectedCBCTFormatOptions,
				selected2DImagingOptions,
				selectedExtraOptions,
				selectedXRayJustificationOptions,
				selectedSkullOptions,
				teeth,
			},
		} = this.props;

		let CBCTFormatOptions = []
		if (this.isSkull && selectedCBCTFormatOptions) {
			CBCTFormatOptions = selectedCBCTFormatOptions.filter(format => {
				return format?.humanReadablePrice?.toLowerCase() !== "SIMPLANT".toLowerCase() &&
				format?.humanReadablePrice?.toLowerCase() !== "Carestream Viewer".toLowerCase() &&
				format?.humanReadablePrice?.toLowerCase() !== "Morita".toLowerCase();
			})
		} else {
			CBCTFormatOptions = selectedCBCTFormatOptions
		}

		return (
			<div>
				{this.state.showModal && (
					<Modal
						onDiscard={() => this.onDiscard()}
						onClose={() => this.handleModalVisibilityToggle()}
					>
						Are you sure you want to discard your changes?
						<br />
						All of the progress will be lost.
					</Modal>
				)}
				<div
					className="ap-side-modal"
					id="createNewBooking"
					style={{ display: "block" }}
				>
					<div
						className="ap-side-modal-backdrop"
						onClick={() => this.setState({ showModal: true })}
					></div>
					<div className="ap-side-modal-content mod-footer mod-header">
						<div className="ap-side-modal-header">
							<div className="row align-items-center">
								<div className="col-sm-10">
									<h3>New scan booking</h3>
								</div>
								<div className="col-sm-2">
									<Button
										style={{ fontSize: "24px" }}
										close
										onClick={this.handleModalVisibilityToggle}
									/>
								</div>
							</div>
						</div>
						<Form
							onSubmit={this.createBooking}
							initialValues={{
								selectedDate: date,
                                selectedPatient: this.state.selectedPatient ? this.props.patientsArray.find(patient => patient.id === this.state.selectedPatient) : '',
								hoursMinutes: this.state.times.find(time => time === moment(this.props.selectedDate).format('HH:mm')),
								selectedFullRadiologyReport: fullRadiologyReportNoOption || {},
								selectedPayment: this.state.selectedPaymentType,
							}}
							mutators={{
								typeToggler: (args, state, utils) => {
									utils.changeValue(state, "selectedCBCTFormat", () => []);
									utils.changeValue(state, "selected2DImaging", () => []);
								},
								clearSameDayCheckBox: (args, state, utils) => {
									utils.changeValue(state, "sameDayProcessing", () => false);
								},
								clearDates: (args, state, utils) => {
									utils.changeValue(state, "selectedDate", () => null);
									utils.changeValue(state, "hoursMinutes", () => null);
								},
								clearHours: (args, state, utils) => {
									utils.changeValue(state, "hoursMinutes", () => null);
								},
							}}
							render={({ handleSubmit, invalid, values, form }) => (
								<form
									className="pt-4"
									onSubmit={(values) => handleSubmit(values)}
								>
									<Row>
										<Col sm="2">
											<label className="col-form-label">Visit time</label>
										</Col>
										<Col sm="3" className="override-date-picker-booking">
											<div className="input-group-prepend react-dates">
												<div className="input-group-text">
													<i className="icon-calendar"></i>
												</div>
											</div>
											<div className="single-calendar-container">
												<Field
													id="visit-date-input"
													name="selectedDate"
													component={SingleDatePickerAdapter}
												/>
												<OnChange name="selectedDate">
													{(value, previous) => {
														this.handleLockUpdate(value, previous, values)
														this.retrieveBookedAppointmentTimes(values.selectedDate)
														form.mutators.clearHours();
													}
													}
												</OnChange>
											</div>
										</Col>
										<Col sm="3">
											<Field
												name="hoursMinutes"
												component={({ input, meta, ...rest }) =>
													<div className="form-group input-group">
														<div className="input-group-prepend">
															<div className="input-group-text">
																<i className="icon-clock" />
															</div>
														</div>
														<select
															{...input}
															className="form-control ap-time-picker time-inputs-overrides"
															id="reg-speciality"
														>
															<option disabled hidden value="">
																Please Choose...
															</option>
															{this.state.times.map((time, i) => <option key={i} value={time}>{moment(time, "hh:mm").format('LT')}</option>)}
														</select>
													</div>
												}
											/>
											<OnChange name="hoursMinutes">
												{(value, previous) =>
													this.handleLockUpdate(value, previous, values)
												}
											</OnChange>
										</Col>
										<Col className="mt-2">
											{this.renderCountdown(values, form.mutators.clearDates)}
										</Col>
									</Row>
									<Row>
										<small className="text-muted ml-2 p-2">
											*If no visit time is selected, 3Beam receptionists will
											contact the patient to schedule.
										</small>
									</Row>
									<div className="form-group row align-items-center">
										<label
											htmlFor="new-booking-patient"
											className="col-sm-2 col-form-label"
										>
											Patient*
										</label>
										<div className="col-sm-6">
                                            <PatientPicker
                                                patients={patientsArray}
                                            />
										</div>
										<div className="col-sm-4">
											<Field
												name="possibilityOfPregnancy"
												type="checkbox"
												render={({ input, meta }) => (
													<div className="form-check">
														<input
															className="form-check-input"
															id="ap-form-checkbox-0"
															{...input}
														/>
														<label
															className="form-check-label"
															htmlFor="ap-form-checkbox-0"
														>
															Possibility of pregnancy
														</label>
													</div>
												)}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-sm-12">
											{values.selectedPatient && (
												<SelectedPatientDetails
													patient={values.selectedPatient}
												/>
											)}
										</div>
									</div>
									<div className="row">
										<div className="col-sm-6 offset-sm-2">
											<span
												className="link-span"
												data-toggle="side-modal"
												onClick={() => this.props.openCreatePatient()}
											>
												+ Add new patient
											</span>
										</div>
									</div>
									<div className="row border-top mt-5 pt-4 form-wrapper-booking-scan">
										<div className="col-sm-8">
											<div className="form-group row">
												<div className="col-sm-12">
													<div className="nav nav-pills">
														<span
															className={classNames(
																"nav-item nav-link link-span",
																{
																	active: this.isCBCT,
																}
															)}
															onClick={() => {
																form.mutators.typeToggler();
																this.switchScanPrices(SCAN_TYPES.CBCT);
															}}
														>
															CBCT Scan
														</span>
														<span
															className={classNames(
																"nav-item nav-link link-span",
																{
																	active: this.isTwoD,
																}
															)}
															onClick={() => {
																form.mutators.typeToggler();
																this.switchScanPrices(SCAN_TYPES.TWO_D);
															}}
														>
															2D Imaging
														</span>
														<span
															className={classNames(
																"nav-item nav-link link-span",
																{
																	active: this.isSkull,
																}
															)}
															onClick={() => {
																form.mutators.typeToggler();
																this.switchScanPrices(SCAN_TYPES.SKULL);
															}}
														>
															Maxillofacial/ENT
														</span>
													</div>
												</div>
											</div>
											<Field
												name="patientComeWithTemplate"
												type="checkbox"
												render={({ input }) => (
													<div className="form-group form-check">
														<input
															className="form-check-input"
															type="checkbox"
															id="ap-form-checkbox-2"
															{...input}
														/>
														<label
															className="form-check-label"
															htmlFor="ap-form-checkbox-2"
														>
															Patient comes with a radiographic template
														</label>
													</div>
												)}
											/>
											<div className="tab-content border-0" id="nav-tabContent">
												<div
													className="tab-pane show p-0 active"
													id="nav-cbct"
													role="tabpanel"
													aria-labelledby="nav-cbct"
												>
													{this.isCBCT && (
														<TeethSelector
															notifyToothSelection={() =>
																this.notifyToothSelection(values)
															}
															teeth={teeth}
														/>
													)}
													{this.isSkull && (
														<SkullSelector
															options={selectedSkullOptions}
															notifySkullSelection={this.onSkullSelection.bind(
																this
															)}
														/>
													)}
													<div className="form-group pt-3">
														<Field
															name="includeTMJs"
															type="checkbox"
															render={({ input }) => (
																<div className="form-group form-check">
																	<input
																		className="form-check-input"
																		id="ap-form-checkbox-13"
																		{...input}
																	/>
																	<label
																		className="form-check-label"
																		htmlFor="ap-form-checkbox-13"
																	>
																		Include TMJs
																	</label>
																</div>
															)}
														/>
													</div>
													<div className="form-group pt-3">
														<Field
															name="clinicalIndications"
															validate={required}
															render={({ input }) => (
																<div className="form-group pt-3">
																	<label className="col-form-label">
																		Clinical Indications/Relevant History/Clinical Question*
																	</label>
																	<textarea
																		className="form-control"
																		rows="3"
																		{...input}
																	></textarea>
																</div>
															)}
														/>
													</div>
													{(this.isCBCT || this.isSkull) && (
														<div className="form-group row">
															<label className="col-sm-4 col-form-label">
																CBCT Formats*
															</label>
															<div className="col-sm-8">
																<Field
																	name="selectedCBCTFormat"
																	component={ReactSelectAdapter}
																	options={this.optionsFormatter(
																		CBCTFormatOptions
																	)}
																	validate={required}
																/>
															</div>
														</div>
													)}
													{this.isTwoD && (
														<div className="form-group row">
															<label className="col-sm-4 col-form-label">
																2D Digital Imaging*
															</label>
															<div className="col-sm-8">
																<Field
																	name="selected2DImaging"
																	component={ReactSelectAdapter}
																	options={this.optionsFormatter(
																		selected2DImagingOptions
																	)}
																	validate={required}
																	isMulti
																/>
															</div>
														</div>
													)}
													<div className="form-group row">
														<label className="col-sm-4 col-form-label">
															Justification For X-ray *
														</label>
														<div className="col-sm-8">
															<Field
																name="selectedXRayJustification"
																component={ReactSelectGroupedAdapter}
																options={this.optionsGrouper(
																	this.optionsFormatter(
																		selectedXRayJustificationOptions
																	)
																)}
																validate={required}
																isMulti
															/>
														</div>
													</div>
													<div className="form-group row">
														<label className="col-sm-4 col-form-label">
															Full radiology report
														</label>
														<div className="col-sm-8">
															<Field
																name="selectedFullRadiologyReport"
																component={ReactSelectAdapter}
																options={
																	selectedExtraOptions &&
																	this.radiologyReportOptionsFormatter(
																		selectedExtraOptions.filter(
																			(i) =>
																				!i.name.startsWith("e_epr") &&
																				!i.name.startsWith("e_cd")
																		)
																	)
																}
															/>
														</div>
													</div>
													{this.renderRadiologistSelect(
														values,
														form.mutators.clearSameDayCheckBox
													)}
												</div>
												<Field
													name="consentsUnderTheReferalProcess"
													validate={required}
													type="checkbox"
													render={({ input }) => (
														<div className="form-group form-check">
															<input
																className="form-check-input"
																{...input}
																id="ap-form-checkbox-10"
															/>
															<label
																className="form-check-label"
																htmlFor="ap-form-checkbox-10"
															>
																*The patient consents under the referral process
																to have an x-ray exposure and understands what
																it involves. I have provided the patient with
																adequate information relating to the benefits
																and risks associated with the radiation dose.
																For children under the age of 16 the parent or
																guardian agrees. I have read and agree to abide by{" "}
																{companyInfo.name}
																<Link
																	to={NAVIGATION_CONSTANTS.TERMS}
																	className="ml-2"
																	target="_blank"
																>
																	Standard Terms and Conditions
																</Link>
																.
															</label>
														</div>
													)}
												/>
												<Field
													name="subjectToSafeguarding"
													type="checkbox"
													render={({ input }) => (
														<div className="form-group form-check">
															<input
																className="form-check-input"
																{...input}
																id="ap-form-checkbox-11"
															/>
															<label
																className="form-check-label"
																htmlFor="ap-form-checkbox-11"
															>
																This patient is subject to safeguarding.
															</label>
														</div>
													)}
												/>
											</div>
										</div>
									</div>

									{!this.canAffordBooking(values.selectedPayment) && (
										<Row className="mt-2 my-2 message-box">
											<Alert color="warning" className="cant-pay text-center">
												Please attach your credit card or select payment option
											</Alert>
										</Row>
									)}

									<div className="ap-side-modal-footer">
										<div className="row align-items-center">
											<div className="col-sm-12 d-flex justify-content-between align-items-center">
												<div className="mr-4">
													<FormSpy subscription={{ values: true }}>
														{({ values }) => this.displayPrice(values)}
													</FormSpy>
												</div>

												{(this.state.existCBCT || !this.isCBCT) && (
													<div className="mr-auto">
														<ApplyVoucherForm
															showVoucherForm={showVoucherForm}
															loading={checkingVoucherInfo}
															voucherInfo={voucherInfo}
															voucherCode={voucherCode}
															handleApplyVoucher={this.handleApplyVoucher}
															toggleShowVoucherForm={() =>
																this.setState({ showVoucherForm: true })
															}
															handleInputChange={(e) =>
																this.setState({ voucherCode: e.target.value })
															}
														/>
													</div>
												)}

												{!this.state.existCBCT && this.isCBCT && (
													<div className="mr-auto">
														<Alert
															color="warning"
															className="cant-pay text-center mb-0"
														>
															Your first CBCT appointment is free
														</Alert>
													</div>
												)}

												<div className="col-sm-5 d-flex flex-row justify-content-between align-items-center pr-0">
													<div className="col-sm-9 pr-0">
														<Field
															name="selectedPayment"
															component={ReactSelectAdapter}
															options={PAYMENT_OPTIONS}
															menuPosition="fixed"
															validate={required}
															value={this.state.selectedPaymentType}
														/>
													</div>
													<div className="col-sm-3 pr-0">
														<button
															className="btn btn-primary btn-block"
															onClick={() => this.createBooking(values)}
															disabled={!this.canBook(invalid, values)}
														>
															Book
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							)}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default BookScan;
