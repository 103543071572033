// fetchers for routines
import { createThunkRoutine } from "redux-thunk-routine";

// Audit reports
export const FetchDataRoutine = createThunkRoutine(
  "reports/FETCH_AUDIT_REPORT_DATA"
);
export const SET_AUDIT_REPORT_FILTERS = "reports/SET_AUDIT_REPORT_FILTERS";

// Radiologist reports
export const FetchRadiologistReportRoutine = createThunkRoutine(
  "reports/FETCH_RADIOLOGIST_REPORT_DATA"
);

export const SET_RADIOLOGIST_REPORT_FILTERS =
  "reports/SET_RADIOLOGIST_REPORT_FILTERS";

// Patients satisfaction reports
export const FetchPatientsSatisfactionRoutine = createThunkRoutine(
  "reports/FETCH_PATIENTS_SATISFACTION_REPORT_DATA"
);
export const SET_PATIENTS_SATISFACTION_REPORT_FILTERS =
  "reports/SET_PATIENTS_SATISFACTION_REPORT_FILTERS";

  // Audit registration
  export const FetchAuditRegistrationRoutine = createThunkRoutine(
    "reports/FETCH_REGISTRATION_AUDIT_REPORT_DATA"
  );
  