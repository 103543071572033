import React from "react";

export default function VisitedLabel({ isVisited }) {
  return isVisited ? (
    <span>
      <i className="icon-check text-success mr-2" />
      Visited
    </span>
  ) : (
    <span>
      <i className="icon-close text-warning mr-2" /> Not Visited
    </span>
  );
}
