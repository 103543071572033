import React from "react";
import Moment from "moment";
import RadiologySelect from "../../modules/radiologists/components/RadiologySelect";
import { Form } from "react-final-form";
import { dateFormat } from "../../constants/global.constants";

export default function RequestModal({
  onClose,
  onConfirm,
  visitDetails,
  radiologists
}) {
  const { patientName, visitDate } = visitDetails;
  const formatPatientVisit = () =>
    `${patientName} on ${Moment(visitDate).format(dateFormat)}`;
  return (
    <div className="modal-dialog" role="document" style={{ width: "600px" }}>
      <div className="modal-content border-0">
        <div className="card border-primary mb-0">
          <div className="card-header bg-primary">
            New Repost Request
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="card-body">
            <Form
              onSubmit={() => {}}
              render={({ handleSubmit, values, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group row">
                    <label
                      htmlFor="reg-surname"
                      className="col-sm-4 col-form-label"
                    >
                      Visit:
                    </label>
                    <div className="col-sm-8 pt-2">{formatPatientVisit()}</div>
                  </div>
                  <RadiologySelect radiologists={radiologists} />
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={invalid}
                      onClick={() => onConfirm(values)}
                    >
                      Request
                    </button>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
