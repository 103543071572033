import React, {useCallback, useEffect, useState} from "react";
import './index.css';
import { Form, Field } from "react-final-form";
import classNames from "classnames";
import {
    composeValidators,
    required,
    isEmail
} from "../../../profile/helpers";
import Select from "react-select";
import axios from '../../../../axios-client';
import { toastr } from "react-redux-toastr";
const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
};
export default function ShareScans({ attachedScans, getAttachedScans, reports, getReports, onClose }) {
    const [patients, setPatients] = useState([]);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchPatients = useCallback((searchTerm = '', pageNum = 0) => {
        setLoading(true);
        axios.get(`/patients/retrieve-patients`, {
            params: {
                page: pageNum,
                size: 100,
                search: searchTerm
            }
        }).then(response => {
            const newPatients = response.data.content.map(p => ({
                value: p.id,
                label: `${p.firstName} ${p.lastName}`,
                email: p.email
            }));
            if (pageNum === 0) {
                setPatients(newPatients);
            } else {
                setPatients(prevPatients => [...prevPatients, ...newPatients]);
            }
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching patients:', error);
            setLoading(false);
        });
    }, []);

    const handleSearchChange = useCallback(debounce((value) => {
        setSearch(value);
        setPage(0);
        fetchPatients(value, 0);
    }, 300), [fetchPatients]);

    useEffect(() => {
        fetchPatients();
    }, []);

    const handleSubmit = values => {
        let scanIds = values.selectedAttachedScans ? values.selectedAttachedScans.map(selectedAttachedScan => selectedAttachedScan.value) : [];
        let reportIds = values.selectedReports ? values.selectedReports.map(selectedReport => selectedReport.value) : [];
        axios.post('/visits/share-scan-or-report-with-dentist-by-email', {
            scanIds: scanIds,
            reportIds: reportIds,
            email: values.emailAddress
        }).then(response => {
            toastr.success('success', response.data);
            onClose();
        })
        .catch(error => {
            toastr.error('error', error);
        })
    }

    const validateOneOfScansOrReport = (values) => {
        const selectedAttachedScans = values.selectedAttachedScans || [];
        const selectedReports = values.selectedReports || [];
        if (selectedAttachedScans.length === 0 && selectedReports.length === 0) {
            return { selectedAttachedScans: 'Either attached scans or reports must be selected', selectedReports: 'Either attached scans or reports must be selected' };
        }
        return {};
    };

    return (
        <div className="ap-side-modal d-block">
            <div className="ap-side-modal-backdrop" onClick={() => onClose()} />

            <div className="ap-side-modal-content share-scan-modal-content">
                <div className="ap-side-modal-header share-scan-modal-header-styling">
                  <h3>Share</h3>
                </div>

                <Form
                    onSubmit={handleSubmit}
                    mutators={{
                        resetSelectedAttachedScans: (args, state, utils) => {
                          utils.changeValue(state, 'selectedAttachedScans', () => [])
                        },
                        resetSelectedReports: (args, state, utils) => {
                            utils.changeValue(state, 'selectedReports', () => [])
                        },
                      }}
                    validate={validateOneOfScansOrReport}
                    render={({ handleSubmit, invalid, form }) => (
                        <form onSubmit={(values) => handleSubmit(values)}>
                            <div className="form-group row align-items-center">
                                <label
                                    htmlFor="selectedPatient"
                                    className="col-sm-4 col-form-label share-scan-text-bold"
                                >
                                    Patient Name
                                </label>
                                <div className="col-sm-8">
                                    <Field
                                        name="selectedPatient"
                                        placeholder="Select patient name"
                                        validate={required}
                                        options={patients}
                                        render={({input, meta, ...rest}) => (
                                            <Select
                                                {...input}
                                                {...rest}
                                                onInputChange={(inputValue, {action}) => {
                                                    if (action === 'input-change') {
                                                        handleSearchChange(inputValue);
                                                    }
                                                }}
                                                onChange={patient => {
                                                    input.onChange(patient);
                                                    getAttachedScans(patient.value);
                                                    getReports(patient.value);
                                                    form.mutators.resetSelectedAttachedScans();
                                                    form.mutators.resetSelectedReports();
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="form-group row align-items-center">
                                <label
                                    htmlFor="selectedAttachedScans"
                                    className="col-sm-4 col-form-label share-scan-text-bold"
                                >
                                    Attached scans
                                </label>
                                <div className="col-sm-8">
                                    <Field
                                        name="selectedAttachedScans"
                                        placeholder="Choose attached scans"
                                        isMulti={true}
                                        options={attachedScans}
                                        render={({input, meta, ...rest}) => (
                                            <Select
                                                {...input}
                                                {...rest}
                                                onChange={patient => {
                                                    input.onChange(patient);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className="form-group row align-items-center">
                                <label
                                    htmlFor="selectedReports"
                                    className="col-sm-4 col-form-label receptionist-share-scan-text-bold"
                                >
                                    Report
                                </label>
                                <div className="col-sm-8">
                                    <Field
                                        name="selectedReports"
                                        placeholder="Choose reports"
                                        isMulti={true}
                                        options={reports}
                                        render={({input, meta, ...rest}) => (
                                            <Select
                                                {...input}
                                                {...rest}
                                                onChange={patient => {
                                                    input.onChange(patient);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            <Field
                                type="email"
                                name="emailAddress"
                                validate={composeValidators(required, isEmail)}
                                render={({input, meta}) => (
                                    <div className="form-group row">
                                        <label
                                            htmlFor="reg-email-address"
                                            className="col-sm-4 col-form-label share-scan-text-bold"
                                        >
                                            Email Address
                                        </label>
                                        <div className="col-sm-8">
                                            <input
                                                type="email"
                                                {...input}
                                                className={classNames("form-control share-scan-email-address", {
                                                    "is-invalid": meta.touched && meta.error,
                                                })}
                                                id="reg-email-address"
                                                placeholder="example_e@gmail.com"
                                            />
                                        </div>
                                    </div>
                                )}
                            />

                            <div className="share-scan-button-block">
                                <button
                                    type="submit"
                                    disabled={invalid}
                                    className="btn btn-primary share-scan-button-position"
                                >
                                    Share
                                </button>
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    )
}