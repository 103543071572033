import { NAVIGATION_CONSTANTS } from "../../constants/navigation.constants.js";

const NAV_ITEMS = [
  {
    label: "Profile details",
    permission: "PROFILE_DETAILS"
  },
  {
    label: "Personal Information",
    id: "personal-info-tab",
    icon: "icon-user",
    navigateTo: NAVIGATION_CONSTANTS.PROFILE,
    permission: "PERSONAL_INFORMATION"
  },
  {
    label: "Change Password",
    id: "",
    icon: "icon-lock",
    navigateTo: NAVIGATION_CONSTANTS.CHANGE_PASSWORD,
    permission: "CHANGE_PASSWORD"
  },
  {
    label: "Payment Details",
    permission: "PAYMENT_DETAILS"
  },
  {
    label: "Credit Card Information",
    id: "credit-card-tab",
    icon: "icon-credit-card",
    navigateTo: NAVIGATION_CONSTANTS.CREDIT_CARD_DETAILS,
    permission: "CREDIT_CARD_INFORMATION"
  },
  {
    label: "Other",
    permission: "OTHER"
  },
  {
    label: "Linked Accounts - Practice view",
    id: "joined-acc-tab",
    icon: "icon-people",
    navigateTo: NAVIGATION_CONSTANTS.JOINED_ACCOUNTS,
    permission: "LINKED_ACCOUNTS_PRACTICE_VIEW"
  },
  {
    label: "Schedules",
    id: "schedules",
    icon: "icon-clock",
    navigateTo: NAVIGATION_CONSTANTS.SCHEDULES,
    permission: "SCHEDULES"
  }
];

NAV_ITEMS.forEach(Object.freeze);

export { NAV_ITEMS };

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY