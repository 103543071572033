//Project dependencies
import React, { Fragment, useState } from "react";
//Module dependencies
import dashboardAPI from "../../modules/dashboard";
import {
	isUserReceptionist,
	isUserDentist,
	isUserRadiologist,
	isUserRadiographer,
	isUserLeadRadiographer
} from "../../services/permission.service";
import DefaultLayout from "../../layouts/DefaultLayout";

const {
	DashboardContainer,
} = dashboardAPI.components;

const Dashboard = ({
	userRole,
	bookings,
	bookingsWithoutDate,
	userProfile,
	bookingInfo,
	patientsArray,
	scanPrices,
	scanPricesForPayment,
	hasCreditCard,
	dashboardActions: {
		fetchOccupiedSlotsByTimeRange,
		fetchOccupiedSlots,
		fetchOccupiedSlotsByDateRange,
		fetchTemporallyOccupiedSlots,
		updateTemporallyOccupiedSlots,
		getAllBookingsForCurrentWeek,
		getBookingsByDateRange,
		updateVisit,
        updateVisitUnvisited,
        assignVisitTime,
		unAssignVisitTime,
		getAllBookingsWithoutDate,
		deleteBooking,
		getBookingInfo,
		getScanPrices,
		getScanPricesForBooking,
		createBooking,
		updateBooking,
		getLastSelectedPaymentType,
		checkFirstCBCTIsOrNot,
		getBlockedDatesList
	},
	occupiedSlotsTimeRange,
	occupiedSlots,
	temporallyOccupiedSlots,
	blockedSlotsDateRange,
	selectedPaymentType,
	radiologistsActions: { fetchRadiologists },
	radiologists,
	patientActions: { getAllPatients, createPatient, editPatient },
	visitDetailsActions: { retrievePreviousVisits, addComment },
	visitsByPatient,
	dentistActions: { fetchDentists },
	dentists,
	logoutAction,
	history
}) => {
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [patientToEdit, setPatientToEdit] = useState({});

	const handlePatientEdit = editedPatient => {
		setPatientToEdit(editedPatient);
		editPatient(
			{ id: patientToEdit.id, ...editedPatient },
			false,
			selectedBooking.id
		);
	};

	return (
		<Fragment>
			<DefaultLayout
				history={history}
				fetchDentists={fetchDentists}
				dentists={dentists}
				isUserReceptionist={isUserReceptionist(userRole)}
				isUserDentist={isUserDentist(userRole)}
				isUserRadiologist={isUserRadiologist(userRole)}
				isUserLeadRadiographer={isUserLeadRadiographer(userRole)}
				logoutAction={logoutAction}
			>
				<DashboardContainer
					deleteBooking={deleteBooking}
					handlePatientEdit={handlePatientEdit}
					getBookingInfo={getBookingInfo}
					bookingInfo={bookingInfo}
					retrievePreviousVisits={retrievePreviousVisits}
					addComment={addComment}
					bookings={bookings}
					bookingsWithoutDate={bookingsWithoutDate}
					visitsByPatient={visitsByPatient}
					getAllBookingsForCurrentWeek={getAllBookingsForCurrentWeek}
					fetchOccupiedSlotsByTimeRange={fetchOccupiedSlotsByTimeRange}
					fetchOccupiedSlots={fetchOccupiedSlots}
					getBlockedDatesList={getBlockedDatesList}
					fetchTemporallyOccupiedSlots={fetchTemporallyOccupiedSlots}
					fetchOccupiedSlotsByDateRange={fetchOccupiedSlotsByDateRange}
					updateTemporallyOccupiedSlots={updateTemporallyOccupiedSlots}
					getBookingsByDateRange={getBookingsByDateRange}
					fetchRadiologists={fetchRadiologists}
					getAllBookingsWithoutDate={getAllBookingsWithoutDate}
					updateVisit={updateVisit}
                    updateVisitUnvisited ={updateVisitUnvisited}
					updateBooking={updateBooking}
					assignVisitTime={assignVisitTime}
					unAssignVisitTime={unAssignVisitTime}
					radiologists={radiologists}
					userProfile={userProfile}
					hasCreditCard={hasCreditCard}
					occupiedSlotsTimeRange={occupiedSlotsTimeRange}
					occupiedSlots={occupiedSlots}
					temporallyOccupiedSlots={temporallyOccupiedSlots}
					blockedSlotsDateRange={blockedSlotsDateRange}
					onHandleBookingWithoutDateClick={setSelectedBooking}
					getScanPrices={getScanPrices}
					getScanPricesForBooking={getScanPricesForBooking}
					createBooking={createBooking}
					patientsArray={patientsArray}
					scanPrices={scanPrices}
					scanPricesForPayment={scanPricesForPayment}
					getLastSelectedPaymentType={getLastSelectedPaymentType}
					checkFirstCBCTIsOrNot={checkFirstCBCTIsOrNot}
					selectedPaymentType={selectedPaymentType}
					getAllPatients={getAllPatients}
					createPatient={createPatient}
					onViewBookingDetailsClicked={data => {
						setSelectedBooking(data);
					}}
					isUserReceptionist={isUserReceptionist(userRole)}
					isUserRadiographer={isUserRadiographer(userRole)}
					isUserDentist={isUserDentist(userRole)}
				/>
			</DefaultLayout>
		</Fragment>
	);
};

Dashboard.propTypes = {};

export default Dashboard;
