import DefaultTheme from "react-dates/lib/theme/DefaultTheme";

export const theme = {
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      ...DefaultTheme.reactDates.border,
      input: {
        ...DefaultTheme.reactDates.border.input,
        borderBottomFocused: "2px solid #20a8d8"
      }
    },
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        backgroundColor: "#20a8d8",
        backgroundColor_active: "#20a8d8",
        backgroundColor_hover: "#20a8d8",
        borderColor_active: "20a8d8",
        borderColor_hover: "20a8d8",
        color: "#ffffff",
        color_active: "#ffffff",
        color_hover: "#ffffff"
      },
      selectedSpan: {
        backgroundColor: "#87e0ff",
        backgroundColor_active: "#51caf5",
        backgroundColor_hover: "#39bfed",
        borderColor_active: "#39bfed",
        borderColor_hover: "#39bfed",
        borderColor: "#39bfed",
        color: "#ffffff",
        color_active: "#ffffff",
        color_hover: "#ffffff"
      }
    }
  }
};
