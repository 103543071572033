import React from "react";
import { NavLink } from "react-router-dom";

import PatientFields from "./PatientFields";
import UploadScan from "./UploadScan";
import { NAVIGATION_CONSTANTS } from "../../../../../constants/navigation.constants";

export default function NewReportForm({
  patients,
  userInfo,
  patientScannetAt3Beam,
  setPatientScannedAt3Beam,
  formik,
  selectedCreditCard,
  setSelectedCreditCard,
}) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = formik;

  return (
    <form
      autoComplete="off"
      id="newReportForm"
      onSubmit={handleSubmit}
      className="mt-4 mb-5"
    >
      <div className="row">
        <span className="col-sm-3 font-weight-bold">
          Please select patient:
        </span>
        <div className="col-sm-9">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="scannedAt3Beam"
              name="patient"
              className="custom-control-input"
              onChange={() => {
                setPatientScannedAt3Beam(true);
              }}
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="scannedAt3Beam">
              Patient scanned at 3 Beam
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="scannedElswhere"
              name="patient"
              className="custom-control-input"
              onChange={() => {
                setPatientScannedAt3Beam(false);
              }}
            />
            <label className="custom-control-label" htmlFor="scannedElswhere">
              Patient scanned elsewhere
            </label>
          </div>
        </div>
      </div>
      <div className="border-top pt-4 mt-4"></div>
      <PatientFields
        scannedAt3Beam={patientScannetAt3Beam}
        patients={patients}
        handleBlur={handleBlur}
        handleChange={handleChange}
        values={values}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <div className="row mt-4">
        <span className="font-weight-bold col-sm-3">Type report:</span>
        <div className="col-sm-9">
          <div className="htmlForm-check">
            <input
              className="htmlForm-check-input d-inline-block"
              type="checkbox"
              id="fullRadiologyReport"
              name="fullRadiologyReport"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullRadiologyReport}
              defaultChecked
            />
            <label
              className="htmlForm-check-label ml-2 d-inline-block"
              htmlFor="fullRadiologyReport"
            >
              Full radiology report
            </label>
            {errors.fullRadiologyReport && touched.fullRadiologyReport ? (
              <div className="invalid-feedback">
                {errors.fullRadiologyReport}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-sm-9 offset-sm-3">
          <div className="type-report-details">
            <div className="form-check mb-4">
              <input
                className="form-check-input d-inline-block"
                type="checkbox"
                id="implandtPlanned"
                name="implandtPlanned"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.implandtPlanned}
              />
              <label
                className="form-check-label ml-2 d-inline-block"
                htmlFor="implandtPlanned"
              >
                Implant planned
              </label>
            </div>
            <div className="form-check mb-4">
              <input
                className="form-check-input d-inline-block"
                type="checkbox"
                id="evaluateExisting"
                name="evaluateExisting"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.evaluateExisting}
              />
              <label
                className="form-check-label ml-2 d-inline-block"
                htmlFor="evaluateExisting"
              >
                Evaluate existing
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input d-inline-block"
                type="checkbox"
                id="tmjEvaluation"
                name="tmjEvaluation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.tmjEvaluation}
              />
              <label
                className="form-check-label ml-2 d-inline-block"
                htmlFor="tmjEvaluation"
              >
                TMJ Evaluation
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input d-inline-block"
                type="checkbox"
                id="sinusEvaluation"
                name="sinusEvaluation"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sinusEvaluation}
              />
              <label
                className="form-check-label ml-2 d-inline-block"
                htmlFor="sinusEvaluation"
              >
                Sinus Evaluation
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <span className="col-sm-3 font-weight-bold">Payment:</span>
        <div className="col-sm-9">
          <div className="custom-control custom-radio custom-control-inline mr-5">
            <input
              type="radio"
              id="invoiceMe"
              name="paymentType"
              className="custom-control-input"
              onChange={(e) => {
                setSelectedCreditCard(false);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value="InvoiceMe"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="invoiceMe">
              Invoice me
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="creditCard"
              name="paymentType"
              className="custom-control-input"
              onChange={(e) => {
                setSelectedCreditCard(true);
                handleChange(e);
              }}
              onBlur={handleBlur}
              value="CreditCard"
            />
            <label className="custom-control-label" htmlFor="creditCard">
              Credit card
            </label>
          </div>
        </div>
      </div>
      {selectedCreditCard && !userInfo.hasCard ? (
        <div className="row mt-4">
          <div className="col-sm-9 offset-sm-3">
            <div className="add-credit-card-alert">
              <span className="d-block mb-2">
                Please, add your credit card information to your profile
                beforehand
              </span>
              <NavLink to={NAVIGATION_CONSTANTS.CREDIT_CARD_DETAILS}>
                +Add credit card information
              </NavLink>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row mt-4">
        <span className="font-weight-bold col-sm-3">
          Comments and Special Requests:
        </span>
        <div className="col-sm-9">
          <label htmlFor="notes" className="sr-only">
            Comments and Special Requests
          </label>
          <textarea
            className="form-control"
            id="notes"
            name="notes"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.notes}
            placeholder="Comments and special requests"
            rows="3"
          ></textarea>
        </div>
      </div>
      {!patientScannetAt3Beam && (
        <UploadScan
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
      )}
    </form>
  );
}
