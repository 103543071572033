// fetchers for routines
import { createThunkRoutine } from "redux-thunk-routine";

export const FetchDataRoutine = createThunkRoutine("visits/FETCH_DATA");
export const FetchPastVisitsRoutine = createThunkRoutine(
  "visitsPast/FETCH_DATA"
);
export const SET_FILTERS = "visits/SET_FILTERS";
export const UPDATE_REPORT = "visits/UPDATE_REPORT";
export const UPDATE_SCAN = "visits/UPDATE_SCAN";
export const UPDATE_SCAN_UPLOAD = "visits/UPDATE_SCAN_UPLOAD";
export const CLEAR_RECEIPT ='visits/CLEAR_RECEIPT';

export const UpdateDataRoutine = createThunkRoutine("visits/UPDATE_DATA");

// Audit reports
export const FetchReceiptRoutine = createThunkRoutine(
  "reports/FETCH_RECEIPT_DATA"
);