import { combineReducers } from "redux";
import auth from "./authReducer";
import bookings from "../modules/dashboard/reducers";
import patients from "../modules/patient/reducers";
import linkedAccounts from "../modules/linkedAccounts/reducers";
import dentists from "../modules/dentists/reducers";
import radiologists from "../modules/radiologists/reducers";
import invoices from "../modules/invoices/reducers";
import vouchers from "../modules/voucher/reducers";
import covid19Survey from "../modules/covid-19-survey/reducers";
import radiologyReports from "../modules/radiologyReports/reducers";

// ToDo: after cleanup delete
// import visits from "./visitsReducer";
import visits from "../modules/visits/reducers";
import reports from "../modules/reports/reducers";
import visitDetails from "../modules/visit-details/reducers";

import { reducer as toastrReducer } from "react-redux-toastr";

import profile from "../modules/profile/reducers";
const appReducer = combineReducers({
  auth,
  bookings,
  patients,
  visits,
  visitDetails,
  dentists,
  radiologists,
  linkedAccounts,
  toastr: toastrReducer,
  profile,
  reports,
  invoices,
  vouchers,
  covid19Survey,
  radiologyReports
});

export default (state, action) => {
  if (action.type === 'LOGOUT_ACTION') {
    state = undefined;
  }

  return appReducer(state, action);
}
