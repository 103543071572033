import React from "react";
import Select from "react-select";
import { Field } from "react-final-form";
import { required } from "../../../../modules/profile/helpers";
import { orderBy } from "lodash";

const AutoCompleteAdapter = ({ input, meta, ...rest }) => {
    const selectProps = {
        styles: {
            menu: provided => ({ ...provided, zIndex: 9999 })
        },
        ...input,
        ...rest,
        onChange: e => {
            input.onChange(e);
        }
    };

    if (input.value.id) {
        selectProps.value = rest.options.find(option => option.value === input.value.id);
    }

    return (
        <Select {...selectProps} />
    );
};


export default function PatientPicker({ patients = [] }) {
  const preparedPatients = orderBy(
    patients.map(i => ({
      ...i,
      value: i.id,
      label: `${i.lastName} ${i.firstName}`
    })),
    ["id"],
    ["desc"]
  );
  if (preparedPatients) {
    return (
      <Field
        name="selectedPatient"
        component={AutoCompleteAdapter}
        options={preparedPatients}
        validate={required}
      />
    );
  }

  return null;
}
