import React, { Fragment, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: lightgray;
  border-style: dashed;
  outline: none;
  transition: border 0.24s ease-in-out;
  &:hover {
    background-color: lightblue;
    opacity: 0.5;
  }
`;

export default function ScansUpload({ onFileUpload }) {
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [progress, setProgress] = useState(0);

	const onDrop = useCallback(
		acceptedFiles => {
			setProgress(0);
			const allFiles = [...selectedFiles, ...acceptedFiles];
			setSelectedFiles(allFiles);

			onFileUpload(acceptedFiles, (event) => {
				setProgress(Math.round((100 * event.loaded) / event.total))
			});
		},
		[onFileUpload]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop});
	return (
		<Fragment>
			<DropZone {...getRootProps()} multiple={true}>
				<input {...getInputProps()} />
				<p>
					Drag and drop or <span className="link-span">click here</span>
				</p>
			</DropZone>

			{selectedFiles && selectedFiles.length > 0 && (
				<Fragment>
					<div className="progress" style={{ marginTop: 5 }}>
						<div
							className="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							aria-valuenow={progress}
							aria-valuemin="0"
							aria-valuemax="100"
							style={{ width: progress + "%" }}
						>
							{progress}%
						</div>
					</div>

					<div className="card" style={{ marginTop: 5 }}>
						<div className="card-header">List of Files</div>
						<ul className="list-group list-group-flush">
							{selectedFiles.map((file, index) => (
								<li className="list-group-item" key={index}>
									<span>{file.name}</span>
								</li>
							))}
						</ul>
					</div>
				</Fragment>
			)}

		</Fragment>
	);
}
