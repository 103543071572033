import React, { Fragment } from "react";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import moment from "moment";
import classNames from "classnames";

import { TITLES } from "../../../../../constants/patient.constants";

const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

const selectStyles = (hasError = false) => ({
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (styles) => ({
    ...styles,
    ...(hasError && { borderColor: "#f86c6b" }),
  }),
});

const capitalize = (val) =>
  val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();

export default function PatientInfo({
  scannedAt3Beam,
  patients,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
}) {
  const patientsArray = patients || [];

  return scannedAt3Beam ? (
    <div className="row">
      <span className="font-weight-bold col-sm-3">Patient's name:</span>
      <div className="col-sm-9">
        <Select
          styles={selectStyles(errors.patientId && touched.patientId)}
          options={patientsArray.map((patient) => ({
            label: patient.firstName + " " + patient.lastName,
            value: patient.id,
          }))}
          onChange={(option) => {
            setFieldValue("patientId", option.value);
          }}
          onBlur={() => {
            setFieldTouched("patientId", true);
          }}
        />
        {errors.patientId && touched.patientId ? (
          <small className="invalid-feedback">{errors.patientId}</small>
        ) : null}
      </div>
    </div>
  ) : (
    <Fragment>
      <div className="row">
        <span className="col-sm-3 font-weight-bold">Patient's title:</span>
        <div className="col-sm-9">
          <Select
            styles={selectStyles(errors.patientTitle && touched.patientTitle)}
            placeholder="Choose title"
            options={TITLES.map((title) => ({
              value: title,
              label: capitalize(title),
            }))}
            onChange={(option) => {
              setFieldValue("patientTitle", option.value);
            }}
            onBlur={() => {
              setFieldTouched("patientTitle", true);
            }}
          />
          {errors.patientTitle && touched.patientTitle ? (
            <small className="invalid-feedback">{errors.patientTitle}</small>
          ) : null}
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">Patient's name:</span>
        <div className="col-sm-9">
          <input
            type="text"
            className={classNames("form-control", {
              "is-invalid": errors.patientName && touched.patientName,
            })}
            placeholder="Name"
            name="patientName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.patientName}
            aria-label="Patient's name"
          />
          {errors.patientName && touched.patientName ? (
            <small className="invalid-feedback">{errors.patientName}</small>
          ) : null}
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">Patient's surname:</span>
        <div className="col-sm-9">
          <input
            type="text"
            placeholder="Surname"
            className={classNames("form-control", {
              "is-invalid": errors.patientSurname && touched.patientSurname,
            })}
            name="patientSurname"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.patientSurname}
            aria-label="Patient's surname"
          />
          {errors.patientSurname && touched.patientSurname ? (
            <small className="invalid-feedback">{errors.patientSurname}</small>
          ) : null}
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">Patient's DOB:</span>
        <div className="col-sm-9">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon icon-calendar"></i>
              </div>
            </div>
            <MaskedInput
              mask={dateMask}
              placeholder="DD/MM/YYYY"
              className={classNames("form-control", {
                "is-invalid": errors.patientDOB && touched.patientDOB,
              })}
              name="patientDOB"
              onChange={(event) => {
                setFieldValue(
                  "patientDOB",
                  moment(event.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
                );
              }}
              onBlur={handleBlur}
              aria-label="Patient's Date of Birth"
            />
            {errors.patientDOB && touched.patientDOB ? (
              <div className="invalid-feedback">{errors.patientDOB}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <span className="col-sm-3 font-weight-bold">Gender:</span>
        <div className="col-sm-9">
          <div className="custom-control custom-radio custom-control-inline mr-5">
            <input
              type="radio"
              id="male"
              name="patientGender"
              onChange={handleChange}
              onBlur={handleBlur}
              value="MALE"
              className="custom-control-input"
              defaultChecked
            />
            <label className="custom-control-label" htmlFor="male">
              Male
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline mr-5">
            <input
              type="radio"
              id="female"
              name="patientGender"
              onChange={handleChange}
              onBlur={handleBlur}
              value="FEMALE"
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="female">
              Female
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="other"
              name="patientGender"
              onChange={handleChange}
              onBlur={handleBlur}
              value="OTHER"
              className="custom-control-input"
            />
            <label className="custom-control-label" htmlFor="other">
              Other
            </label>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">Patient's email:</span>
        <div className="col-sm-9">
          <input
            type="email"
            className="form-control"
            name="patientEmail"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.patientEmail}
            placeholder="someone@example.com"
          />
          <small className="form-text text-muted">
            For verification (optional)
          </small>
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">Date scan was taken:</span>
        <div className="col-sm-9">
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <i className="icon icon-calendar"></i>
              </div>
            </div>
            <MaskedInput
              mask={dateMask}
              name="scanTakenDate"
              onChange={(event) => {
                setFieldValue(
                  "scanTakenDate",
                  moment(event.target.value, "DD/MM/YYYY").format("YYYY-MM-DD")
                );
              }}
              onBlur={handleBlur}
              placeholder="DD/MM/YYYY"
              className={classNames("form-control", {
                "is-invalid": errors.scanTakenDate && touched.scanTakenDate,
              })}
              aria-label="Date scan was taken"
            />
            {errors.scanTakenDate && touched.scanTakenDate ? (
              <div className="invalid-feedback">{errors.scanTakenDate}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <span className="col-sm-3 font-weight-bold">
          Indication for the scan and relevant history:
        </span>
        <div className="col-sm-9">
          <textarea
            name="scanIndication"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.scanIndication}
            className={classNames("form-control", {
              "is-invalid": errors.scanIndication && touched.scanIndication,
            })}
            aria-label="Indication for the scan and relevant history"
          ></textarea>
          {errors.scanIndication && touched.scanIndication ? (
            <div className="invalid-feedback">{errors.scanIndication}</div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}
