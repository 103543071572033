// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import { getUserProfile } from "./modules/profile/actions";
import { ImpersonatingService } from "./services/impersonating.service";
import { Provider } from "react-redux";
import configureStore from "./store";

const { token, originalToken } = ImpersonatingService();
export const store = configureStore();

const renderApp = () =>
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );

if (token || originalToken) {
  store
    .dispatch(getUserProfile())
    .then((res) => renderApp())
    .catch((e) => console.log(e));
} else {
  renderApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
