//Project dependencies
import React, { Fragment, useEffect, useState } from "react";
import Header from "../../common/Header";
import PropTypes from 'prop-types';
import axios from '../../axios-client'
import Modal from '../../common/modals/Modal'
import { toastr } from "react-redux-toastr";
// import { loadLocalStoreData } from "../../services/localStorage.service";
// import { LOCAL_STORAGE_CONSTANTS } from "../../constants/localStorage.constants";

const Loading = () => (
  <div className="animated fadeIn pt-3 text-center" style = {{
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#5a5d8a7a",
    zIndex: 100,
    display: "flex"
  }}>
    <div className="sk-spinner sk-spinner-pulse m-auto" /> 
  </div>
);

const DefaultLayout = ({
  children,
  history,
  fetchDentists,
  dentists,
  isUserReceptionist,
  isUserDentist,
  isUserRadiologist,
  isUserLeadRadiographer,
  logoutAction,
  contentLoading,
  ...rest
}) => {
  // const isImpersonating = loadLocalStoreData(
  //   LOCAL_STORAGE_CONSTANTS.ACTING_AS,
  //   null,
  //   false
  // );
  const [showSurveyModal, setShowSurveyModal] = useState(false)
  const completeSurvey = (e, surveyType) => {
      e.preventDefault();
      axios.post('/registration-surveys/create-registration-survey', {
        registrationSurveyItemId: surveyType
      }).then(response => {
        toastr.success('success', response.data)
        setShowSurveyModal(false)
      }).catch(error => {
        toastr.error(error)
      })
  }
  useEffect(() => {
    if (isUserDentist) {
    axios.get('my-profile').then(response => {
      setShowSurveyModal(!response.data.registrationSurveyPassed)
    })
  }
  }, [])

  return (
    <Fragment>
      <Header
        fetchDentists={fetchDentists}
        dentists={dentists}
        isUserReceptionist={isUserReceptionist}
        isUserRadiologist={isUserRadiologist}
        isUserLeadRadiographer={isUserLeadRadiographer}
        history={history}
        isUserDentist={isUserDentist}
        logoutAction={logoutAction}
      />
      {/* {isImpersonating && <ImpersonationInfo />} */}
      <div className="app-body aside-menu-show">
        {contentLoading && <Loading/>}
        <main className="main bg-white">
          <div className="container-fluid">
            <div className="border-0 pt-4">{children}</div>
          </div>
        </main>
      </div>
      {showSurveyModal && <Modal completeSurvey={completeSurvey} mode="survey" />}
    </Fragment>
  );
};

DefaultLayout.propTypes  = {
  contentLoading: PropTypes.bool
}

DefaultLayout.defaultProps = {
  contentLoading: false
}
export default DefaultLayout;
