import React, { Fragment, useState, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Row, Col, Button } from "reactstrap";
import Moment from "moment";
import { DateRangePicker } from "react-dates";

import {
	VERTICAL_ORIENTATION,
	HORIZONTAL_ORIENTATION,
} from "react-dates/constants";
import { dateFormatShort } from "../../../../constants/global.constants";
const heightCss = {
	height: "35px",
};

export default function VisitsFilter({ onFilter, isMobile, fetchVisits, isUserRadiologist, userRole }) {
	const [focused, setFocused] = useState(null);
	const [filters, setFilters] = useState({});
	const [dates, setDates] = useState({ startDate: null, endDate: null });
	const inputRef = useRef(null);
	const handleFocusChange = (focused) => {
		setFocused(focused);
	};

	const selectionChange = (event, type) => {
		let formattedStartDate = null;
		let formattedEndDate = null;
		const { startDate, endDate } = dates;
		if (startDate && endDate) {
			formattedStartDate = Moment(startDate).format('YYYY/MM/DD')
			formattedEndDate = Moment(endDate).format('YYYY/MM/DD')
		}
		onFilter({ [type]: event.target.value, startDate: formattedStartDate, endDate: formattedEndDate });
		setFilters({ [type]: event.target.value });
    if(type === 'searchString' ) {
      fetchVisits({ startDate: formattedStartDate, endDate: formattedEndDate, criteria: event.target.value })
    }
	};

	const onDatesChange = ({ startDate, endDate }) => {
		setDates({ startDate, endDate });
		if (startDate && endDate) {
			const formattedStartDate = Moment(startDate).format('YYYY/MM/DD');
			const formattedEndDate = Moment(endDate).format('YYYY/MM/DD');
			onFilter({ ...filters, startDate: formattedStartDate, endDate: formattedEndDate });
			fetchVisits({ startDate: formattedStartDate, endDate: formattedEndDate, criteria: filters.searchString })
		} else {
			onFilter({ ...filters, startDate, endDate });
		}
	};

	const clearFilter = (e) => {
		setFilters({})
		setDates({ startDate: null, endDate: null });
		inputRef.current.value = "";
		onFilter({});
		fetchVisits({})
	}

	return (
		<Fragment>
			<Row>
				<Col md="3" sm="12">
					<form>
						<div className="form-group input-group">
							<div className="input-group-prepend">
								<div className="input-group-text ">
									<i className="icon-magnifier" />
								</div>
							</div>
							<input
								type="text"
								ref={inputRef}
								className="form-control ap-table-sortable-search"
								style={heightCss}
								placeholder="Type in ID, patient name or dentist name"
								onChange={(event) => selectionChange(event, "searchString")}
							/>
						</div>
					</form>
				</Col>
				<Col
					md="5"
					sm="6"
					xs="9"
					className="text-center"
					style={{ maxWidth: "240px" }}
				>
					<div className="form-group single-calendar-container">
						<DateRangePicker
							onFocusChange={handleFocusChange}
							onDatesChange={onDatesChange}
							startDateId={"startDate"}
							endDateId={"endDate"}
							keepOpenOnDateSelect={true}
							firstDayOfWeek={1}
							reopenPickerOnClearDates={false}
							displayFormat={() => dateFormatShort}
							focusedInput={focused}
							small={true}
							hideKeyboardShortcutsPanel
							orientation={
								isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
							}
							withFullScreenPortal={isMobile}
							isOutsideRange={(date) => date.isAfter(Moment())}
							startDate={dates.startDate}
							endDate={dates.endDate}
              minimumNights={0}
						/>
					</div>
				</Col>
				<Col xs="3" sm="6">
					<Button
						color="primary"
						size="md"
						onClick={(e) => clearFilter()}
						style={{
							display: "flex",
						}}
					>
						<span className="mr-2">Clear</span>{" "}
						<span className="d-none d-sm-block">filters</span>
					</Button>
				</Col>
			</Row>
		</Fragment>
	);
}
