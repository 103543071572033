import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { FetchDataRoutine, SET_DENTIST_FILTERS, SET_ALL_DENTIST_FILTERS } from "./action-types";
import { API_CONSTANTS } from "./constants";

export const fetchDentists = () => async dispatch => {
  dispatch(FetchDataRoutine.request());
  try {
    const { data } = await axios.get(`${API_CONSTANTS.GET_DENTISTS}`);
    dispatch(FetchDataRoutine.success(data));
    return data;
  } catch (e) {
    toastr.error("Error", e); // ToDo: move me to service to avoid writing 1st arg all the time
    dispatch(FetchDataRoutine.failure(e));
  }
};

export const setDentistFilters = filters => {
  return { type: SET_DENTIST_FILTERS, payload: filters };
}

export const fetchAllDentists = () => async dispatch => {
  dispatch(FetchDataRoutine.request());
  try {
    const { data } = await axios.get(API_CONSTANTS.GET_ALL_DENTISTS);
    dispatch(FetchDataRoutine.success(data));
    return data;
  } catch (e) {
    toastr.error("Error", e);
    dispatch(FetchDataRoutine.failure(e));
  }
};

export const setAllDentistFilters = (filters) => ({
  type: SET_ALL_DENTIST_FILTERS,
  filters,
});