import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { FetchDataRoutine } from "./action-types";
import { API_CONSTANTS } from "./constants";

export const fetchRadiologists = () => async dispatch => {
  dispatch(FetchDataRoutine.request());
  try {
    const { data } = await axios.get(`${API_CONSTANTS.GET_RADIOLOGISTS}`);
    dispatch(FetchDataRoutine.success(data));
  } catch (e) {
    toastr.error("Error", e); 
    dispatch(FetchDataRoutine.failure(e));
  }
};
