import React from 'react';
import Select from 'react-select';
  
const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const ReactSelectGroupedAdapter = ({ input, options, ...rest }) => {
    return (
      <Select
        {...input}
        {...rest}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPlacement="auto"
        onChange={(...args) => {
          args[0] = args[0] ? args[0] : [];
          input.onChange(...args);
        }}
        options={options}
        formatGroupLabel={formatGroupLabel}
      />
    );
  };

  export default ReactSelectGroupedAdapter