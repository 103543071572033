import { GET_ALL_PATIENTS, CREATE_PATIENT } from '../action-types';
export const patientsReducer = (state = {}, action) => {
    switch (action.type) {
     case GET_ALL_PATIENTS:
      return {
       patientsArray: action.payload
      }
     case CREATE_PATIENT: {
        let newPatientArray = state.patientsArray;
        newPatientArray.push(action.payload);
        
        return {
            ...state,
            patientsArray: newPatientArray
        }
     }
     default:
      return state
    }
   }
