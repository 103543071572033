import { FetchDataRoutine, SET_DENTIST_FILTERS, SET_ALL_DENTIST_FILTERS } from "../action-types"; 

const initialDentistState = {
  isFetching: false,
  data: [],
  error: null,
  filters: {
    searchString: "",
    dentistId: -1,
  }
};

export const dentistsListReducer = (
  state = initialDentistState,
  action
) => {
  switch (action.type) {
    case FetchDataRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case FetchDataRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchDataRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case SET_DENTIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    case SET_ALL_DENTIST_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    default:
      return state;
  }
};
