import {
  FetchRadiologistReportRoutine,
  SET_RADIOLOGIST_REPORT_FILTERS
} from "../action-types";
import { backendDateFormat } from "../../../constants/global.constants";

import moment from "moment";

const initialRadiologistReportState = {
  isFetching: false,
  data: [],
  filters: {
    startDate: moment()
      .startOf("month")
      .format(backendDateFormat),
    endDate: moment().format(backendDateFormat),
    radiologistIds: []
  },
  error: null
};

export const radiologistReportListReducer = (
  state = initialRadiologistReportState,
  action
) => {
  switch (action.type) {
    case FetchRadiologistReportRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case SET_RADIOLOGIST_REPORT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        }
      };
    case FetchRadiologistReportRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchRadiologistReportRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
