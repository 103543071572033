import React from "react";
import { Alert } from "reactstrap";

export default function NoData({ children = "There is no data" }) {
  return (
    <Alert color="info" className="text-center">
      {children}
    </Alert>
  );
}
