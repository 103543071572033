import React, { useState, Fragment, useRef } from "react";
import { Link } from "react-router-dom";

import {
  INITIAL_EDIT_FORM_STATE,
  INITIAL_REGISTER_FORM_STATE,
  SPECIALITIES,
  COUNTRIES,
  TITLES,
} from "../../../../constants/auth.constants";
import { Form, Field } from "react-final-form";
import classNames from "classnames";
import {
  composeValidators,
  required,
  isEmail,
  isSecondaryEmail,
  isValidPassword,
  formatSpeciality,
} from "../../helpers";
import { NAVIGATION_CONSTANTS } from "../../../../constants/navigation.constants";
import "./index.css";

const ProfileForm = ({
  onSubmit,
  onDiscard,
  mode,
  userProfile,
  isUserDentist,
}) => {
  const mrxl = {
    marginRight: "15px", // Add extra css class during refactoring
  };
  const inputRef = useRef();

  const [termsRead, changeTermsRead] = useState(false);
  const [hidePassword, toggleHidePassword] = useState(true);

  const capitalize = (val) =>
    val.charAt(0).toUpperCase() + val.slice(1).toLowerCase(); // Just add lodash after demo

  const onChangeRead = () => changeTermsRead(!termsRead);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={
        userProfile ||
        (mode === "register"
          ? INITIAL_REGISTER_FORM_STATE
          : INITIAL_EDIT_FORM_STATE)
      }
      render={({ handleSubmit, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <fieldset className="ap-form-fieldset">
            {mode === "register" && (
              <legend className="ap-form-legend">About you</legend>
            )}
            <Field
              name="title"
              render={({ input }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-title"
                    className="col-sm-4 col-form-label"
                  >
                    Title
                  </label>
                  <div className="col-sm-8">
                    <select {...input} className="form-control" id="reg-title">
                      <option disabled hidden value="">
                        Please Choose...
                      </option>
                      {TITLES.map((title) => (
                        <option key={title} value={title}>
                          {capitalize(title)}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>
                </div>
              )}
            />

            <Field
              name="firstName"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-forename"
                    className="col-sm-4 col-form-label"
                  >
                    Forename
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      name="firstName"
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-forename"
                      placeholder="Forename"
                    />
                  </div>
                </div>
              )}
            />

            <Field
              name="lastName"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-surname"
                    className="col-sm-4 col-form-label"
                  >
                    Surname
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-forename"
                      placeholder="Surname"
                    />
                  </div>
                </div>
              )}
            />

            <Field
              name="speciality"
              render={({ input }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-speciality"
                    className="col-sm-4 col-form-label"
                  >
                    Speciality
                  </label>
                  <div className="col-sm-8">
                    <select
                      {...input}
                      className="form-control"
                      id="reg-speciality"
                    >
                      <option disabled hidden value="">
                        Please Choose...
                      </option>
                      {SPECIALITIES.map((speciality) => (
                        <option key={speciality} value={speciality}>
                          {formatSpeciality(speciality)}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>
                </div>
              )}
            />

            <Field
              name="nameOfPractice"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-forename"
                    className="col-sm-4 col-form-label"
                  >
                    Practice
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-practice"
                      placeholder="Name of Practice"
                    />
                  </div>
                </div>
              )}
            />

            <Field
              name="gdc"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label htmlFor="reg-gdc" className="col-sm-4 col-form-label">
                    GDC / GMC No.
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-practice"
                      placeholder="GDC / GMC No."
                    />
                  </div>
                </div>
              )}
            />

            {mode === "edit" && (
              <Field
                name="visitsEnabled"
                type="checkbox"
                render={({ input }) => (
                  <div className="form-group row">
                    <label
                      htmlFor="reg-visits-enabled"
                      className="col-sm-11 col-form-label"
                    >
                      Please, send me patient appointment confirmations by email
                    </label>
                    <div className="col-sm-1" style={{ margin: "auto" }}>
                      <input
                        {...input}
                        checked={input.value}
                        id="reg-visits-enabled"
                      />
                    </div>
                  </div>
                )}
              />
            )}
          </fieldset>
          <fieldset className="ap-form-fieldset">
            <legend className="ap-form-legend">Address of the practice</legend>

            <Field
              name="country"
              render={({ input }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-country"
                    className="col-sm-4 col-form-label"
                  >
                    Country
                  </label>
                  <div className="col-sm-8">
                    <select
                      disabled
                      {...input}
                      className="form-control"
                      id="reg-country"
                    >
                      {COUNTRIES.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                      ;
                    </select>
                  </div>
                </div>
              )}
            />

            <Field
              name="city"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label htmlFor="reg-city" className="col-sm-4 col-form-label">
                    City
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      disabled
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-city"
                      placeholder="City"
                    />
                  </div>
                </div>
              )}
            />

            <Field
              name="postCode"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-postcode"
                    className="col-sm-4 col-form-label"
                  >
                    Postcode
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-postcode"
                      placeholder="Postcode"
                    />
                  </div>
                </div>
              )}
            />

            <Field
              name="address"
              validate={required}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-address"
                    className="col-sm-4 col-form-label"
                  >
                    Address line
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      id="reg-address"
                      placeholder="Address line"
                    />
                  </div>
                </div>
              )}
            />
          </fieldset>
          <fieldset className="ap-form-fieldset">
            <legend className="ap-form-legend">Contact information</legend>

            {mode === "edit" && (
              <Field
                name="telephoneNumber"
                render={({ input, meta }) => (
                  <div className="form-group row">
                    <label
                      htmlFor="reg-tel"
                      className="col-sm-4 col-form-label"
                    >
                      Telephone No.
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        {...input}
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error,
                        })}
                        id="reg-tel"
                        placeholder="Telephone No."
                      />
                    </div>
                  </div>
                )}
              />
            )}

            <Field
              name="email"
              validate={composeValidators(required, isEmail)}
              render={({ input, meta }) => (
                <div className="form-group row">
                  <label
                    htmlFor="reg-email"
                    className="col-sm-4 col-form-label"
                  >
                    Email address
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      {...input}
                      className={classNames("form-control", {
                        "is-invalid": meta.touched && meta.error,
                      })}
                      disabled={mode === "edit" && !isUserDentist}
                      id="reg-email"
                      placeholder="Email address"
                    />
                    {mode === "register" && (
                      <small className="form-text text-muted">
                        This will be your username.
                      </small>
                    )}
                  </div>
                </div>
              )}
            />

            {mode === "edit" && (
              <Fragment>
                <div className="notification-title">Email notifications</div>

                <Field
                  name="enabledEmailNotification"
                  type="checkbox"
                  render={({ input }) => (
                    <label className="checkbox-container">
                      Send email notification to registration email{" "}
                      {values.email ? `(${values.email})` : ""}
                      <input
                        {...input}
                        checked={input.value}
                        disabled={
                          values.enabledEmailNotification &&
                          (values.customEmails === null ||
                          values.customEmails.length === 0 ||
                          values.customEmails.filter(customEmail => customEmail.email && isSecondaryEmail(customEmail.email)).length
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  )}
                />

                <span>
                    Please, send all my email notifications to alternative
                    email
                </span>

                {userProfile.customEmails.map((customEmail, i) => {
                  const name = `customEmails[${i}].email`
                  const id = `secondary-email-${i}`
                    
                  return <Field
                   name={name}
                   key={i}
                   validate={isSecondaryEmail}
                   render={({ input, meta }) => (
                     <div className="row mt-2">
                       <div className="col-sm-12">
                         <input
                           type="email"
                           {...input}
                           className={classNames("form-control", {
                             "is-invalid": meta.touched && meta.error,
                           })}
                           disabled={mode === "edit" && !isUserDentist}
                           id={id}
                           placeholder="example_e@gmail.com"
                         />
                       </div>
                     </div>
                   )}
                 />
                })}

                <Link className="d-block" to="#" onClick={() => {
                  userProfile.customEmails.push({
                    id: 0,
                    email: ''
                  })
               }}>Add alternative email</Link>
              </Fragment>
            )}
          </fieldset>
          {mode === "register" && (
            <fieldset className="ap-form-fieldset">
              <legend className="ap-form-legend">Password</legend>
              <Field
                name="password"
                validate={composeValidators(required, isValidPassword)}
                render={({ input, meta }) => (
                  <div className="form-group mod-switch row">
                    <label
                      htmlFor="reg-password"
                      className="col-sm-4 col-form-label"
                    >
                      Password
                    </label>
                    <div className="col-sm-8">
                      <input
                        {...input}
                        type={hidePassword ? "password" : "text"}
                        data-type-switch="text"
                        className={classNames("form-control", {
                          "is-invalid": meta.touched && meta.error,
                        })}
                        placeholder="Create your password"
                      />
                      <span
                        className="ap-form-control-change show-hide-password"
                        style={mrxl}
                        onClick={(e) => {
                          e.preventDefault();
                          toggleHidePassword(!hidePassword);
                        }}
                      >
                        {hidePassword ? "Show" : "Hide"}
                      </span>
                      <small className="form-text text-muted">
                        Password should contain at least 6 characters, at least
                        one capital letter and a number.
                      </small>
                    </div>
                  </div>
                )}
              />
            </fieldset>
          )}

          {mode === "register" && (
            <Fragment>
              {" "}
              <div className="form-group form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={termsRead}
                  onChange={onChangeRead}
                  id="ap-form-checkbox-0"
                />
                <label
                  className="form-check-label"
                  htmlFor="ap-form-checkbox-0"
                >
                  I have read and agree on{" "}
                  <Link to={NAVIGATION_CONSTANTS.TERMS} target="_blank">
                    Terms and Conditions
                  </Link>
                  .
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !termsRead || invalid || !values.speciality || !values.title
                }
              >
                Sign Up
              </button>
            </Fragment>
          )}
          {mode === "edit" && (
            <Fragment>
              <div className="row mt-4">
                <div className="col-sm-6">
                  <button
                    className="btn btn-light ap-discard-personal-information"
                    onClick={onDiscard}
                  >
                    Discard Changes
                  </button>
                </div>
                <div className="col-sm-6 text-right">
                  <button
                    ref={inputRef}
                    className="btn btn-primary ap-save-personal-information"
                    disabled={invalid}
                    onClick={(e) => {
                      values.customEmails = values.customEmails.filter(customEmail => customEmail.email);
                      e.preventDefault();
                      onSubmit(values);
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Fragment>
          )}
        </form>
      )}
    />
  );
};

export default ProfileForm;
