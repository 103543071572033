import { FetchPastVisitsRoutine } from "../action-types";

const initialVisitsState = {
  isFetching: false,
  data: [],
  error: null
};

export const visitsPastReducer = (state = initialVisitsState, action) => {
  switch (action.type) {
    case FetchPastVisitsRoutine.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null
      };
    case FetchPastVisitsRoutine.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case FetchPastVisitsRoutine.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};
