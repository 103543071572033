import { combineReducers } from "redux";
import { visitsReducer } from "./reducers/visits-list-reducers";
import { visitsPastReducer } from "./reducers/visits-past-reducers";
import { visitsUpdateReducer } from "./reducers/visit-update.reducers";
import { visitsReceiptReducer } from "./reducers/visits-receipt.reducers";

export default combineReducers({
  visitsReducer,
  visitsPastReducer,
  visitsUpdateReducer,
  visitsReceiptReducer
});
