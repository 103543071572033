import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { selectedSkullPartsOptionsV2 } from "../../../../constants/priceItems.constants";

import { preparePricingData } from "../../../../services/pricing.service";
import SkullSVG from "../../../dashboard/components/SkullSVG";
import TeethSVG from "../../../dashboard/components/TeethSVG";
import visitDetails from "../../../visit-details";

export default class BriefScanInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verballyConsented: true
    };
  }

  formatScanType() {
    const { visitDetails } = this.props;
    const config = {
      TWO_D: "2D Imaging",
      CBCT: "CBCT",
      SKULL: "Maxillofacial/ENT",
    };
    return config[visitDetails.bookingDetailsDto.scanType];
  }

  get isCBCT() {
    const { visitDetails } = this.props;
    return visitDetails.bookingDetailsDto.scanType === "CBCT";
  }

  get isSkull() {
    const { visitDetails } = this.props;
    return visitDetails.bookingDetailsDto.scanType === "SKULL";
  }

  renderPainterSkull() {
    const { bookingItemDtos } = this.props.visitDetails.bookingDetailsDto;
    const getSkullPriceItems = bookingItemDtos
      .filter((i) => selectedSkullPartsOptionsV2.includes(i.itemPriceDto.name))
      .map((i) => i.itemPriceDto.name);

    return (
      <SkullSVG
        isDetails
        selectedItems={getSkullPriceItems}
        options={getSkullPriceItems}
      />
    );
  }

  render() {
    const { visitDetails } = this.props;

    const {
      selectedCBCTFormat,
      selectedXRayJustification,
      selectedExtra,
      selected2DImaging,
      selectedTeeth,
      selectedSkullParts,
    } = preparePricingData(visitDetails, null, 90);

    return (
      <div className="row">
        <div className="col-8">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Row>
                <Col>Required service</Col>
                <Col>{this.formatScanType()} Scan</Col>
              </Row>
            </li>
          </ul>
          <ul className="list-group">
            {this.isCBCT && (
              <li className="list-group-item">
                <span className="row">
                  <span className="col-5">
                    {this.formatScanType()} Area of Interest
                  </span>

                  <span className="col-7 ap-tooth-labels">
                    {selectedTeeth.map((option, i) => {
                      return (
                        <label htmlFor={option.htmlFor} key={i}>
                          {option.label}
                        </label>
                      );
                    })}
                  </span>
                </span>
              </li>
            )}
            <li className="list-group-item">
              <Row>
                <Col>Clinical Indications/Relevant History/Clinical Question</Col>
                <Col className="clinical-indications-wrapper">
                  {visitDetails.bookingDetailsDto.clinicalIndications
                    ? visitDetails.bookingDetailsDto.clinicalIndications
                    : " — "}
                </Col>
              </Row>
            </li>
            {!!selectedCBCTFormat.length && (
              <li className="list-group-item">
                <Row>
                  <Col>{this.formatScanType()} Format</Col>
                  <Col>
                    {selectedCBCTFormat.map((value, i) => {
                      return <Row key={i}>{value.label}</Row>;
                    })}
                  </Col>
                </Row>
              </li>
            )}
            {!!selected2DImaging.length && (
              <li className="list-group-item">
                <Row>
                  <Col>{this.formatScanType()} Format</Col>
                  <Col>
                    {selected2DImaging.map((value, i) => {
                      return <Row key={i}>{value.label}</Row>;
                    })}
                  </Col>
                </Row>
              </li>
            )}
            {!!selectedSkullParts.length && (
              <li className="list-group-item">
                <Row>
                  <Col>Maxillofacial/ENT</Col>
                  <Col>
                    {selectedSkullParts
                      .filter((i) => i.label !== "Maxillofacial")
                      .map((value, i) => {
                        return <Row key={i}>{value.label}</Row>;
                      })}
                  </Col>
                </Row>
              </li>
            )}
            {!!selectedXRayJustification.length && (
              <li className="list-group-item">
                <Row>
                  <Col>Justification For X-ray</Col>
                  <Col>
                    {selectedXRayJustification.map((value, i) => {
                      return <Row key={i}>{value.label}</Row>;
                    })}
                  </Col>
                </Row>
              </li>
            )}
            <li className="list-group-item">
              <Row>
                <Col>Include TMJs</Col>
                <Col>
                  <Row>
                    <input
                      type="checkbox"
                      checked={visitDetails.bookingDetailsDto.includeTMJs}
                      disabled
                    />
                  </Row>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col>Verbally consented</Col>
                <Col>
                  <Row>
                    <input
                      type="checkbox"
                      defaultChecked={this.state.verballyConsented}
                      disabled={true}
                    />
                  </Row>
                </Col>
              </Row>
            </li>
            <li className="list-group-item">
              <Row>
                <Col>Authorised</Col>
                <Col>
                  <Row>
                    <input
                      type="checkbox"
                      checked={visitDetails.bookingDetailsDto.authorised}
                      disabled={true}
                    />
                  </Row>
                </Col>
              </Row>
            </li>
            {!!selectedExtra.length && (
              <li className="list-group-item">
                <Row>
                  <Col>Full radiology report</Col>
                  <Col>
                    {selectedExtra.map((value, i) => {
                      if (value.value.startsWith("e_frr_")) {
                        return <Row key={i}>{value.label}</Row>;
                      } else {
                        return <Row key={i}>{value.label}</Row>;
                      }
                    })}
                  </Col>
                </Row>
              </li>
            )}
          </ul>
        </div>
        <div className="col-4">
          <form className="teeth-map">
            {selectedTeeth.map((option, index) => {
              return (
                <input
                  type="checkbox"
                  id={option.htmlFor}
                  className="ap-tooth-checkbox"
                  checked="checked"
                  disabled="disabled"
                  key={index}
                />
              );
            })}
            {this.isCBCT && <TeethSVG />}
            {this.isSkull && this.renderPainterSkull()}
          </form>
        </div>
      </div>
    );
  }
}
