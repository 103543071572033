import axios from "../../axios-client";
import { toastr } from "react-redux-toastr";
import { GET_ALL_PATIENTS, CREATE_PATIENT } from "./action-types";
import { fetchVisits } from "../../modules/visits/actions";
import {
  getBookingInfo,
  getAllBookingsWithoutDate,
  getAllBookingsForCurrentWeek
} from "../../modules/dashboard/actions";

export const getAllPatients = () => dispatch => {
  axios.get("/patients").then(result => {
    return dispatch({
      type: GET_ALL_PATIENTS,
      payload: result.data
    });
  });
};

export const createPatient = patient => dispatch => {
    return axios
        .post("/patients", patient)
        .then(result => {
            const newPatient = {
                ...patient,
                id: result.data
            };

            dispatch({
                type: CREATE_PATIENT,
                payload: newPatient
            });

            toastr.success("Success", "Patient was created");

            return newPatient.id;
        })
        .catch(e => {
            toastr.error("Error", e);
            throw e;
        });
};

export const editPatient = (patient, isVisitPage, visitId) => dispatch => {
  axios
    .put(`/patients/${patient.id}`, patient)
    .then(result => {
      toastr.success("Success", "Patient was edited");
      if (isVisitPage) {
        dispatch(fetchVisits());
      } else {
        dispatch(getAllBookingsWithoutDate());
        dispatch(getAllBookingsForCurrentWeek());
      }
      dispatch(getBookingInfo(visitId));
    })
    .catch(e => toastr.error("Error", e));
};
