import {
  isUserRadiologist,
  isUserDentist
} from "../services/permission.service";
import { NAVIGATION_CONSTANTS } from "../constants/navigation.constants";
import { toastr } from "react-redux-toastr";
import { isMobile } from "../utils";

export const resolveAfterLoginRedirect = (history, role) => {
  if (
    isMobile(navigator.userAgent || navigator.vendor || window.opera) &&
    isUserDentist(role)
  ) {
    return history.push(NAVIGATION_CONSTANTS.VISITS_MOBILE);
  } else if (
    isMobile(navigator.userAgent || navigator.vendor || window.opera) &&
    !isUserDentist(role)
  ) {
    toastr.light(
      "Your device is not supported",
      "3beam application currently supports mobile view only for dentists, please log in as dentist or use desktop based application",
      { timeOut: 0, position: "top-center", icon: "warning" }
    );
  } else if (isUserRadiologist(role)) {
    return history.push(NAVIGATION_CONSTANTS.VISITS);
  } else {
    history.push(NAVIGATION_CONSTANTS.DASHBOARD);
  }
};
