import { isUserReceptionist } from "./permission.service";

export const populateLegalInfo = userProfile => {
  const placeholder = "______________________";
  return isUserReceptionist(userProfile.role)
    ? {
        address: placeholder,
        lastName: placeholder
      }
    : userProfile;
};

export const printForm = (id = "btnprint") => {
  const buttonControl = document.getElementById(id);
  buttonControl.style.visibility = "hidden";
  window.print();
  buttonControl.style.visibility = "visible";
};

export const style = {
  paddingTop: "50px",
  margin: "0 auto"
};

export const styleForTitle = {
  paddingTop: "50px",
  margin: "0 auto",
  textAlign: "center"
};

export const styleForDentistsList = {
  paddingTop: "150px",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column"
};

export const companyInfo = {
  address: "86 Harley Street, London, W1G 7HP",
  phone: "0207 637 8227",
  name: "3Beam Limited",
  defaultRadiologistId: 3,
  email: 'info@3beam.co.uk'
};
