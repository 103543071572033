export const TITLES = Object.freeze([
  "MR",
  "MRS",
  "MISS",
  "MS",
  "DR",
  "PROF",
  "LADY",
  "LORD"
]);

export const COUNTRIES = Object.freeze([
  "United Kingdom"
  //   "Country",
  //   "USA",
  //   "France",
  //   "Spain"
]);

export const SPECIALITIES = Object.freeze([
  "OTHER",
  "ORAL_SURGERY",
  "ENDODONTICS",
  "IMPLANTOLOGY",
  "ORTHODONTICS",
  "GENERAL",
  "PERIODONTICS",
  "MAXILLOFACIAL", 
  "ENT"
]);

export const INITIAL_EDIT_FORM_STATE = Object.freeze({
  address: "",
  city: "London",
  email: "",
  firstName: "",
  lastName: "",
  nameOfPractice: "",
  password: "",
  postCode: "",
  speciality: "",
  telephoneNumber: "",
  title: "",
  visitsEnabled: false,
  secondaryEmail: "",
  isEnabledEmailNotification: true,
});

export const INITIAL_REGISTER_FORM_STATE = Object.freeze({
  address: "",
  city: "London",
  email: "",
  firstName: "",
  lastName: "",
  nameOfPractice: "",
  password: "",
  postCode: "",
  speciality: "",
  title: "",
  visitsEnabled: false,
});

